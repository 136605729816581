import React, {Component} from "react";
import "./BookDetail.css";
import downloadPDf from "../../../assets/images/downloadpdf1.svg";

class DownloadPDFOr extends Component {
    render() {
        return (
            <div className="DownloadPDF">
                <img src = {downloadPDf} />
                <h1>Choose your package</h1>
                <p>You can choose to download the single PDF of <b>{this.props.match.params.title}</b> or upgrade to a
                    Premium Subscription for unlimited PDF downloads of
                    all of the books in our library.</p>

                <p>
                    <a href={`/payment/${this.props.match.params.id}`}>
                        <button className="DownloadPDF_SingleBtn StandardButton-1">Download PDF File</button>
                    </a>

                </p>
                <p>
                    <a href="/try">
                        <button className="DownloadPDF_SingleBtn StandardButton-4">Upgrade</button>
                    </a>
                </p>

            </div>
        );
    }
}

export default DownloadPDFOr;