import React, {Component} from 'react';

import './FAQ.css';

class FAQ extends Component {
    toggle() {
        var acc = document.getElementsByClassName("accordion");
        var i;

        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                /* Toggle between adding and removing the "active" class,
                to highlight the button that controls the panel */
                this.classList.toggle("active");

                /* Toggle between hiding and showing the active panel */
                var panel = this.nextElementSibling;
                if (panel.style.display === "block") {
                    panel.style.display = "none";
                } else {
                    panel.style.display = "block";
                }
            });
        }
    }

    render() {
        this.toggle();
        return (
            <div className="header Main">
                <button className="accordion">What is sumizeit?</button>
                <div className="panel">
                    <p>Access non-fiction book summaries you can read in a few minutes. Our experts extracted key
                        insights and crafted the sumizeit originals in a clear and easy to understand language.
                        <br /><br />
                        We are a young team of professionals who are unhappy with the cost and the 15 minutes lengths of
                        other apps. Our summaries give you critical insights into the books and let you talk with anyone
                        about the concepts. When we say summary, we mean summary. In just 5 minutes you get it all.
                        <br /><br />
                        We want you to fulfill your human and business potential. Fill waiting times before a meeting, a
                        metro ride, or even a personal break with meaningful content that lets you grow as a person. Our
                        editorial team carefully selected every one of our titles. We promise you a new book each week
                        or get your money back.</p>
                </div>

                <button className="accordion">How do I get started?</button>
                <div className="panel">
                    <p>Getting started is simple. Click on the Upgrade Now button above and subscribe. On the pricing page that you will then see, choose a monthly plan for 4.99/month or a yearly plan for 19.99/month. Your money will help us pay for the new titles, and the experts who are extracting the key insights. Join in and support the knowledge revolution.</p>
                    <ul>
                        <li>Unlimited access to all titles</li>
                        <li>We add new titles every week.</li>
                        <li>Unlock the insights from the world’s greatest books</li>
                    </ul>

                    <p>
                        Cancel subscription anytime. Subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the current period. Your account will be charged for renewal within 24-hours before the end of the current period at the price of the annual or monthly subscription depending on what is chosen. Payment will be charged to your credit card or paypal account that you provide.
                    </p>
                    <p>
                        Questions? Suggestions? You can speak to a real person at sumizeit. Our trusty customer support team is just an email away --- write to info@sumizeit.com​.
                    </p>
                </div>

                <button className="accordion">Do you have a mobile app?</button>
                <div className="panel">
                    <p>Of course! A link to our mobile app can be found in the footer of this page.</p>
                </div>

                <button className="accordion">What if I am unable to find a book?</button>
                <div className="panel">
                    <p>We add new book titles every week. Email us at info@sumizeit.com about books that you want to be added to the site, and we will get to them ASAP. At sumizeit, the customer comes first.</p>
                </div>

                <button className="accordion">Do you have a free trial?</button>
                <div className="panel">
                    <p>Yes! We have a 7-day money back guarantee, which means if you cancel in your first 7 days you will receive a full refund, no questions asked. Please email us at info@sumizeit.com if you want to cancel.</p>
                </div>
            </div>
        )
    }
}

export default FAQ;