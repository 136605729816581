import React, {Component} from 'react';
import axios from '../../../axios';
import "../Admin.css"
import Spinner from "../../Spinner/Spinner"
import {NavLink, Redirect} from 'react-router-dom';
import AdminNavigation from "../Navigation/AdminNavigation";

class AdminCollectionAdd extends Component {
    state = {
        collection: {
            id: '',
            categories: '',
            image: '',
            title: '',
            subtitle: '',
            uploadDate: ''
        },
        complete: false,
        loading: false
    };

    _onSubmit(ev) {
        ev.preventDefault();
        this.setState({loading: true});

        axios.post('/admin/collections/add', {values: this.state.collection}).then(response => {
            this.setState({collection: response.data.collection});
            this.setState({loading: false, complete: true})
        }).catch(error => {
            this.setState({error: true});
        });
    }

    render() {
        if (this.state.loading) {
            return <Spinner/>
        }
        if (this.state.complete) {
            return <Redirect to="/admin/collections"/>
        }
        const mapped = Object.keys(this.state.collection).map(val => {
            var input = <input type="text" name={val} value={this.state.collection[val]} id={val} onChange={(event) => {
                var newCollection = {...this.state.collection};
                newCollection[val] = event.target.value;
                this.setState({collection: newCollection})
            }} key={val}/>;

            return <div>
                <label>{val}</label><br/><br/>
                {input}
            </div>
        });
        return (
            <div className="Main">
                <form onSubmit={this._onSubmit.bind(this)} className="Admin">
                    <AdminNavigation/>
                    <div className="BookEdit AdminEdit Admin">
                        {mapped}
                        <input type="submit" name="submit" value="Submit"/>
                    </div>
                </form>
            </div>
        )
    }
}

export default AdminCollectionAdd;
