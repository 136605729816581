import React from "react";
import axios from "../../axios";
import Spinner from "../Spinner/Spinner";
import "./ChooseCategories.css";
import Alert from "react-bootstrap/Alert";
import Breadcrumb from "react-bootstrap/Breadcrumb";

class ChooseCategories extends React.Component {
  state = {
    success: null,
    loading: true,
    error: null,
    result: null,
    selected: [],
  };

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser() {
    axios
      .get("/api/user/account")
      .then((response) => {
        if (response.data.status === "ok") {
          if (
            response.data.result.user.recommends &&
            response.data.result.user.recommends.length > 0
          ) {
            if (this.props.match.params.type === "new") {
              window.location.href = "/home";
            } else {
              this.setState(
                {
                  selected: response.data.result.user.recommends,
                  loading: false,
                },
                () => {
                  this.fetchCategories();
                }
              );
            }
          } else {
            this.fetchCategories();
          }
        } else {
          this.setState({
            error: "Couldn't fetch the user",
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: "Couldn't fetch the user",
          loading: false,
        });
      });
  }

  fetchCategories() {
    axios
      .get("/api/categories")
      .then((response) => {
        if (response.data.status === "ok") {
          this.setState({
            result: response.data.result.categories,
            loading: false,
          });
        } else {
          this.setState({
            error: "Couldn't fetch the categories",
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: "Couldn't fetch the categories",
          loading: false,
        });
      });
  }

  submit() {
    if (this.state.selected.length < 1) {
      alert("Please pick a category.");
    } else {
      axios
        .put("/api/user/recommends", { recommends: this.state.selected })
        .then((response) => {
          if (response.data.status === "ok") {
            if (this.props.match.params.type === "new") {
              window.location.href = "/home";
            } else {
              this.setState({
                success: "Your preferences were successfully saved.",
              });
            }
          } else {
            this.setState({
              error:
                "There was an error processing your request. Please try again later.",
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            error:
              "There was an error processing your request. Please try again later.",
            loading: false,
          });
        });
    }
  }

  handleSelected(id, checked) {
    let selected = [...this.state.selected];

    if (checked && !selected.includes(id)) {
      selected.push(id);
    } else {
      const index = selected.indexOf(id);
      selected.splice(index, 1);
    }

    this.setState({
      selected,
    });
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }
    var error = "";
    if (this.state.error && this.state.error.length) {
      error = (
        <Alert key="warning-1" variant="warning">
          {this.state.error}
        </Alert>
      );
    }

    var success = "";
    if (this.state.success && this.state.success.length) {
      success = (
        <Alert key="success-1" variant="success">
          {this.state.success}
        </Alert>
      );
    }

    if (this.state.result && this.state.result.length) {
      let categoriesHtml = this.state.result.map((c) => {
        let checked = false;
        console.dir(this.state.selected);
        if (this.state.selected && this.state.selected.length > 0) {
          checked = this.state.selected.includes(c.id);
        }
        return (
          <div className="ChooseCategories_Category">
            <input
              type="checkbox"
              name="category"
              onChange={(e) => {
                this.handleSelected(c.id, e.target.checked);
              }}
              checked={checked}
            />
            &nbsp;&nbsp;
            {c.name}
          </div>
        );
      });
      return (
        <div className="ChooseCategories">
          <Breadcrumb>
            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Set Preferences</Breadcrumb.Item>
          </Breadcrumb>
          {success}
          {error}
          <h1>Set your Preferences</h1>
          <p className="subtitle">
            Please pick the categories that you are interested in, and we will
            recommend books for you to read.
          </p>
          {categoriesHtml}
          <button
            type="submit"
            className="ChooseCategories_SubmitBtn StandardButton-1"
            onClick={(ev) => {
              ev.preventDefault();
              this.submit();
            }}
          >
            Submit
          </button>
        </div>
      );
    } else {
      return (
        <div className="ChooseCategories">
          {success}
          {error}
        </div>
      );
    }
  }
}

export default ChooseCategories;
