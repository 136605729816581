import React from 'react';

import './Spinner.css';

const spinner = (props) => (
    <div className="loading-screen">
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
    </div>
);

export default spinner;