import React, {Component} from 'react';

class Collapsible extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
        this.togglePanel = this.togglePanel.bind(this);
    }

    togglePanel(e) {
        this.setState({open: !this.state.open})
    }

    render() {
        return (<div className="collapsible-content-wrapper">
            <div onClick={(e) => this.togglePanel(e)} className='collapsible-header'>
                {this.props.title}</div>
            {this.state.open ? (
                <div className='collapsible-content'>
                    {this.props.children}
                </div>
            ) : null}
        </div>);
    }
}


export default Collapsible;