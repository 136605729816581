import React, { Component } from "react";
import axios from "../../axios";
import "./Home.css";
import Spinner from "../Spinner/Spinner";
import Color from "color-thief-react";

class Visuals extends Component {
    state = {
        loading: true,
        visuals: [],
    };

    componentDidMount() {
        this.fetchVisuals();
    }

    fetchVisuals() {
        axios
            .get("/api/visuals")
            .then((response) => {
                var r = response.data;

                if (r.error) {
                    this.setState({
                        error: r.error,
                        loading: false,
                    });
                } else if (r.status === "ok") {
                    var booksNonUnique = [];
                    var visualsUnique = [];
                    var visuals= r.result.visuals;

                    visuals.forEach(v => {
                        if (booksNonUnique.indexOf(v.book.id) === -1) {
                            booksNonUnique.push(v.book.id);
                            visualsUnique.push(v);
                        }
                    });

                    this.setState(
                        {
                            loading: false,
                            visuals: visualsUnique
                        }
                    );
                }
            })
            .catch((error) => {
                this.setState({
                    error: error.message,
                    loading: false
                });
            });
    }

    renderItem(section, item) {
        return (
            <Color src={item.book.image} crossOrigin="anonymous" format="hex">
                {({ data, loading }) => {
                    if (loading) return <Spinner />;
                    return (
                        <div key={`${section}-${item.id}`} className="Home-item">
                            <div
                                style={{ backgroundColor: data }}
                                className="Home-bookitem-wrapper"
                            >
                                <a href={`/visuals/${item.book.id}`}>
                                    <button
                                        style={{
                                            backgroundImage: "url('" + item.book.image + "')",
                                        }}
                                        className="Home-bookitem"
                                    ></button>
                                </a>
                            </div>
                            <a href={`/visuals/${item.book.id}`} className="Home-itemtitle">
                                {item.book.title}
                            </a>
                            <div className="Home-itemauthor">{item.book.author}</div>
                        </div>
                    );
                }}
            </Color>
        );
    }


    render() {
        if (this.state.loading) {
            return <Spinner />;
        }

        var visuals = this.state.visuals;
        var mappedVisuals = [];

        if (visuals && visuals.length) {
            mappedVisuals = visuals.map((book) => {
                return this.renderItem("visual", book);
            });
        }

        return (
            <div className="Home Main">
                {mappedVisuals && (
                    <div className="Home-box Home-boxnew">
                        <h2 className="Visual_H2">Visual Book Summaries</h2>
                        <p className="Visual_P">Book summaries visualized for you to reach your goals faster!</p>
                        {mappedVisuals}
                    </div>
                )}
            </div>
        );
    }
}

export default Visuals;
