import React, { Component } from "react";
import axios from "../../axios";
import "./Login.css";
import Spinner from "../Spinner/Spinner";
import { NavLink } from "react-router-dom";

class Account extends Component {
  state = {
    user: null,
    loading: true,
  };

  fetch() {
    axios
      .get("/api/user/account")
      .then((response) => {
        this.setState({
          user: response.data.result.user,
          loading: false,
        });
      })
      .catch((error) => {
        console.dir(error);
        this.setState({ error: error });
      });
  }

  // exportUserInfo() {
  //     axios.get('/auth/json').then(response => {
  //         this.setState({user: response.data.result});
  //
  //         const data = response.data.result;
  //         const fileName = 'download';
  //         const exportType = 'json';
  //
  //         exportFromJSON({data, fileName, exportType});
  //     }).catch(error => {
  //         this.setState({error: error});
  //     });
  // }

  delete(event) {
    // event.preventDefault();
    // axios.post('/auth/delete').then(response => {
    //     if (response.error) {
    //         this.setState({error: response.error});
    //     } else {
    //         this.setState({completeDeleteAccount: true});
    //     }
    // }).catch(error => {
    //     this.setState({error: error});
    // });
  }

  componentDidMount() {
    this.fetch();
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }
    if (this.state.user) {
      const user = this.state.user;
      const tryNow = <a href="/try">Access 2 FREE summaries</a>;
      let planId = user.planId ? user.planId : "Free";
      let isUserPaid = false;

      if (
        user.paidDate > 0 &&
        (!user.endPaidDate || user.endPaidDate > Date.now())
      ) {
        isUserPaid = true;
      }

      let includesFreeTrial = false;
      if (planId && planId.length) {
        if (planId.toLowerCase().indexOf("monthly") !== -1) {
          if (planId.toLowerCase().indexOf("free") !== -1) {
            includesFreeTrial = true;
          }
          planId = "Sumizeit Monthly Subscription";
        } else if (planId.indexOf("yearly") !== -1) {
          if (planId.toLowerCase().indexOf("free") !== -1) {
            includesFreeTrial = true;
          }
          planId = "Sumizeit Yearly Subscription";
        } else {
          planId = "Sumizeit Lifetime";
        }
      }

      const timeOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZoneName: "short",
      };
      const freeTrialEnds =
        user.paidDate && includesFreeTrial
          ? new Date(user.paidDate + 60 * 60 * 1000 * 24 * 7).toLocaleString(
              "en-US",
              timeOptions
            )
          : null;

      return (
        <div className="container Account">
          <h1>Account Details</h1>
          <p>Thanks for joining sumizeit!</p>

          <h3>Email</h3>
          <p>{user.email}</p>

          <h3>Subscription</h3>
          <div>
            <p>
              {isUserPaid
                ? "Subscription Status: Active - " + planId + "   "
                : tryNow}
            </p>
            {/*{ user.paidDateInSeconds > 0 && user.paymentMethod === "stripe" && user.planid !== "lifetime" && user.paidStatus !== "cancelled"*/}
            {/*&& <NavLink to="/cancel">- Cancel Subscription</NavLink>*/}
            {/*}*/}
            {/*{ user.paidStatus === "cancelled" && " - Cancelled" }*/}
            {freeTrialEnds && (
              <p>You are on a free trial until {freeTrialEnds}</p>
            )}

            {user.endPaidDate > 0 && (
              <p>
                End Date: {new Date(user.endPaidDate).toLocaleString("en-US")}
              </p>
            )}
          </div>

          <div>
            <h3>Customize your interests</h3>
            <p>
              <NavLink to="/choose-categories/edit">
                Set your preferences
              </NavLink>
            </p>
          </div>

          <h3>Privacy</h3>
          <p>
            By using our service, you agree to our{" "}
            <a href="/privacy">privacy policy</a>. For more information, contact
            info@sumizeit.com.
          </p>

          {/*<button onClick={this.exportUserInfo.bind(this)} className="StandardButton-1">Export Personal Data</button>*/}

          {/*&nbsp;&nbsp;&nbsp;<a href="#" onClick={this.delete.bind(this)}>*/}
          {/*    Delete Account**/}
          {/*</a>*/}
          {/*<br />*/}
          {/*<span className="fine-print">If you delete your account, you'll still need to cancel your iTunes subscriptions by going to the iTunes app store.</span>*/}
          {/*/!*&nbsp;&nbsp;&nbsp;*!/*/}
          {/*<NavLink to={{*/}
          {/*    pathname: '/delete'*/}
          {/*}}>*/}
          {/*    Delete Account*/}
          {/*</NavLink>*/}
          <p>
            <a href="/account/delete">Delete Account</a>
          </p>

          <p>
            <a href="/logout">Logout</a>
          </p>
          <br />
          <br />
          <br />
        </div>
      );
    }
    return "";
  }
}

export default Account;
