import React from "react";
import axios from "../../axios";

class Logout extends React.Component {
  state = {
    complete: false,
    error: null,
  };

  componentDidMount() {
    if (window.localStorage) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("tokenDate");
    }
    axios
      .get("/api/user/logout")
      .then((response) => {
        window.location.href = "/";
      })
      .catch((error) => {
        window.location.href = "/";
      });
  }

  render() {
    return (
      <p align="center" className="Main AlertSuccess">
        Logging you out.
      </p>
    );
  }
}

export default Logout;
