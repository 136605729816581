import React, {Component} from "react";
import Spinner from '../Spinner/Spinner';
import Form from '../../Form';
import Axios from "../../axios";
import './Login.css';
import "../../Form.css";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            loading: false,
            success: ''
        };
    }

    handleSubmit(values) {
        Axios.put("/api/user/forgot-password", {
            email: values.email
        }).then((response) => {
            if (response.data.status === "ok") {
                this.setState({
                    error: '',
                    success: 'Please check your email for instructions on how to reset your password. If you have any questions, please email us at info@sumizeit.com.'
                }, () => {
                    window.scrollTo(0, 0)
                })
            } else {
                this.setState({
                    error: response.data.error,
                    loading: false,
                    success: ''
                }, () => {
                    window.scrollTo(0, 0)
                });
            }
        }).catch (err => {
            console.dir(err);
            this.setState({
                error: err,
                loading: false,
                success: ''
            }, () => {
                window.scrollTo(0, 0)
            })
        });
    }

    componentDidMount() {
    }

    render() {
        if (this.state.loading) {
            return (<Spinner />);
        }

        var error = '';
        if (this.state.error && this.state.error.length) {
            error = (<div className="alert alert-warning" role="alert">{this.state.error}</div>);
        }

        if (this.state.success && this.state.success.length) {
            return (<div className="container"><div className="row"><div className="alert alert-success" role="alert">{this.state.success}</div></div></div>);
        }

        return <div className="container ForgotPassword Form profile-form">
            <div className="row">
                <h1>Forgot Password</h1>
                {error}
                <p>Please fill in your email below. Please Contact Us if you require any assistance.</p>
                <Form fields={[
                    {
                        id: "email",
                        label: "Email Address",
                        type: "text",
                        required: true,
                        inputType: "email"
                    }
                ]} submitHandler={this.handleSubmit.bind(this)} submitButtonLabel="Reset Password"
                      errorHandler={(error) => {
                          this.setState({
                              error
                          })
                      }}/>
            </div>
        </div>
    }
}

export default ForgotPassword;