import React, {Component} from "react";
import app from "./base";
import axios from "../../axios";
import Utils from "../../utils"
import {NavLink} from "react-router-dom";
import "./Login.css";

class CancelSubscription extends Component {

    constructor (props) {
        super(props);

        this.handleRadioChange = this.handleRadioChange.bind(this);
    }

    state = {
        reason: null,
        error: null,
        complete: false
    };

    // this function is called whenever you change the radion button
    handleRadioChange(event) {
        // set the new value of checked radion button to state using setState function which is async funtion
        this.setState({
            reason: event.target.value
        });
    }

    submit(e) {
        e.preventDefault();
        axios.post('/charge/cancel', {
            reason: this.state.reason
        }).then(response => {
            if (response.error) {
                this.setState({error: response.error})
            } else {
                this.setState({complete: true});
            }
        }).catch(error => {
            this.setState({error: error});
        });
    }

    render() {
        if (this.state.complete) { return (<div><p className='Main' align="center">Your subscription was cancelled. <NavLink to="/account">Back to Account</NavLink></p></div>) }
        if (this.state.error) { return (<div><p className='Main' align="center">There was an error processing your cancellation. Please contact us at info@sumizeit.com. <NavLink to="/account">Back to Account</NavLink></p></div>) }

        return (
            <div className="container Auth Main">
                <h1>Cancel your subscription</h1>
                <p>We're sorry you want to cancel your subscription!</p>
                <p>To help us learn and improve, please tell us the reason why you wish to not renew your subscription.</p>
                <p>Please only use this form for non Itunes subscriptions. For Itunes subscriptions, please contact us at info@sumizeit.com to cancel.</p>
                <br />
                <form onSubmit={this.submit.bind(this)} className="CancelSubscriptionForm">
                    <input type="radio" checked={this.state.reason === "too expensive"} onChange={this.handleRadioChange} value="too expensive" />It's too expensive<br />
                    <input type="radio" checked={this.state.reason === "not using"}  onChange={this.handleRadioChange} value="not using" />I'm not using it enough to pay for it<br />
                    <input type="radio" checked={this.state.reason === "using other app"}  onChange={this.handleRadioChange} value="using other app" />I found another app to use<br />
                    <input type="radio" checked={this.state.reason === "not using it right now"} onChange={this.handleRadioChange} value="not using it right now" />I don't have time to use it right now<br />
                    <input type="radio" checked={this.state.reason === "not working"}  onChange={this.handleRadioChange} value="not working" />The app is not working<br />
                    <input type="radio" checked={this.state.reason === "no autorenewals"}  onChange={this.handleRadioChange} value="no autorenewals" />I don't like auto-renewals<br />

                    <input type="radio" checked={this.state.reason === "no android"}  onChange={this.handleRadioChange} value="no android app" />No android app support<br />
                    <input type="radio" checked={this.state.reason === "other"}  onChange={this.handleRadioChange} value="other" />Other<br />
                    <br />
                    <button type="submit" className='StandardButton-1'>Turn off auto-renewal</button> <NavLink to="/books"><button className="StandardButton-2">Keep as is</button></NavLink>
                </form>
            </div>
        );
    }
};

export default CancelSubscription;
