import React, {Component} from "react";
import axios from "../../../axios";
import _ from "lodash";
import "../Admin.css";
import {NavLink} from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import AdminNavigation from "../Navigation/AdminNavigation";

class AdminPodcastList extends Component {
    state = {
        podcasts: [],
        sort: "uploadDate",
        loading: true
    };

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        axios
            .get("/api/admin/podcasts/")
            .then((response) => {
                if (response.data.status === "ok") {
                    this.setState({
                        podcasts: response.data.result.podcasts,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false,
                        error: response.data.error
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: "There was an error processing your request"
                });
            });
    }

    sortBy = (key) => {
        this.setState({sort: key});
    };

    render() {
        if (this.state.loading) {
            return <Spinner/>;
        }
        var result = this.state.podcasts;

        const header = (
            <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Edit</th>
            </tr>
        );
        const podcastsHtml = result.map((b) => {
            return (
                <tr>
                    <td>{b.id}</td>
                    <td>
                        {b.title} by {b.author}
                    </td>
                    <td>
                        <NavLink to={`/admin/podcasts/${b.id}`} exact>
                            Edit
                        </NavLink>
                    </td>
                    <td>
                        <NavLink to={`/admin/podcast-photo/${b.id}`} exact>
                            Photo
                        </NavLink>
                    </td>
                    <td>
                        <NavLink to={`/admin/podcast-media/${b.id}`} exact>
                            Media
                        </NavLink>
                    </td>
                </tr>
            );
        });

        var count = result.length;

        return (
            <div className="container AdminBookList Admin">
                <AdminNavigation/>
                <br/>
                <br/>
                <p>
                    <NavLink to={`/admin/podcast-add`} exact>
                        Add podcast
                    </NavLink>
                </p>
                <br/>
                <br/>
                <p>#{count} podcasts</p>
                <table>
                    {header}
                    {podcastsHtml}
                </table>
            </div>
        );
    }
}

export default AdminPodcastList;
