import React, {Component} from 'react';
import axios from '../../../axios';
import _ from 'lodash'
import "../Admin.css"
import Spinner from "../../Spinner/Spinner"
import {NavLink} from "react-router-dom";
import AdminNavigation from "../Navigation/AdminNavigation";

function formatDate(date) {
    var d = new Date(parseInt(date, 10)),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

function sortObj(obj) {
    return Object.keys(obj).sort().reduce(function (result, key) {
        result[key] = obj[key];
        return result;
    }, {});
}

function Last7Days() {
    var result = [];
    for (var i = 0; i < 7; i++) {
        var d = new Date();
        d.setDate(d.getDate() - i);
        result.push(formatDate(d))
    }

    return (result.join(','));
}

const LIMIT = 100;

class UserList extends Component {

    state = {
        users: [],
        loading: true,
        sort: 'createDate',
        showAll: true,
        start: 0
    };

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        axios.get('/api/admin/user/' + this.state.start).then(response => {

            if (response.data.status === "ok") {
                let users = _.concat(response.data.result.users, this.state.users);
                users = this._sortByKey(users, this.state.sort);

                this.setState({
                    users,
                    loading: false,
                    start: this.state.start + LIMIT
                });
            } else {
                this.setState({error: response.data.error});
            }
        }).catch(error => {
            this.setState({error: true});
        });
    }

    _sortByKey(value, key) {
        return _.sortBy(value, [(o) => {
            if (o.hasOwnProperty(key)) {
                if (key.indexOf("Date") !== -1) {
                    return -o[key]
                }
                return o[key]
            }
        }]);
    }

    sortBy = (key) => {
        var users = this._sortByKey(this.state.users, key);
        this.setState({sort: key, users: users})
    };

    showAll(showAll) {
        this.setState({
            showAll: showAll
        })
    }

    render() {
        if (this.state.loading) {
            return <Spinner/>;
        }

        const userMapped = this.state.users.map(u => {
            return <div className="row" key={u.id}>
                <div className="col-md AdminUser_Col">{u.id}</div>
                <div className="col-md AdminUser_Col">{u.email}</div>
                <div className="col-md AdminUser_Col">{formatDate(u.createDate)}</div>
                <div className="col-md AdminUser_Col"><NavLink to={`/admin/users/${u.id}`} exact>Edit</NavLink></div>
            </div>
        })

        return <div className="container">
            <AdminNavigation/>
            <br />
            <br />
            <p>{this.state.users.length} users</p>
            <div className="row">
                <div className="col-md AdminUser_ColHeader">Id</div>
                <div className="col-md AdminUser_ColHeader">Email</div>
                <div className="col-md AdminUser_ColHeader">Date</div>
                <div className="col-md AdminUser_ColHeader">Edit</div>
            </div>
            {userMapped}
            <button onClick={this.fetch.bind(this)} className="LoadMore">Load More</button>
        </div>
    }
}

export default UserList;
