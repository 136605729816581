import React, { Component } from "react";
import axios from "../../../axios";
import "../Admin.css";
import Spinner from "../../Spinner/Spinner";
import AdminNavigation from "../Navigation/AdminNavigation";
import Form from "../../Form/Form";
import _ from "lodash";
import { NavLink } from "react-router-dom";

class AdminBookAdd extends Component {
  state = {
    loading: true,
    categories: null,
    error: "",
    success: "",
    goals: null,
    goalsMap: null,
  };

  componentWillMount() {
    this.fetchCategories();
  }

  _convertToMultiSelect(values) {
    const output = [];
    values.forEach((v) => {
      output.push({
        label: v.name,
        value: v.id,
      });
    });
    return output;
  }

  fetchCategories() {
    axios
      .get("/api/categories")
      .then((response) => {
        if (response.data.status === "ok") {
          const cmap = {};
          response.data.result.categories.forEach((c) => {
            cmap[c.id] = c.name;
          });

          this.setState(
            {
              categories: this._convertToMultiSelect(
                response.data.result.categories
              ),
              loading: true,
              categoriesMap: cmap,
            },
            () => {
              this.fetchGoals();
            }
          );
        } else {
          this.setState({
            error: response.data.error,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: "There was an error processing your request.",
          loading: false,
        });
      });
  }

  fetchGoals() {
    axios
      .get("/api/goals")
      .then((response) => {
        if (response.data.status === "ok") {
          const gmap = {};
          response.data.result.goals.forEach((c) => {
            gmap[c.id] = c.title;
          });

          this.setState({
            goals: this._convertToMultiSelect(response.data.result.goals),
            loading: false,
            goalsMap: gmap,
          });
        } else {
          this.setState({
            error: response.data.error,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: "There was an error processing your request.",
          loading: false,
        });
      });
  }

  handleSubmit(values) {
    const output = _.cloneDeep(values);

    this.setState(
      {
        loading: true,
      },
      () => {
        var selectedCategories = [];
        if (output.categories && output.categories.length) {
          output.categories.forEach((item) => {
            selectedCategories.push(item.value);
          });
        }

        var selectedGoals = [];
        if (output.goals && output.goals.length) {
          output.goals.forEach((item) => {
            selectedGoals.push(item.value);
          });
        }

        output.categories = selectedCategories;
        output.goals = selectedGoals;

        axios
          .post("/api/admin/books/", output)
          .then((response) => {
            if (response.data.status === "ok") {
              this.setState(
                {
                  error: "",
                  loading: false,
                  success: "Book was added.",
                },
                () => {
                  window.scrollTo(0, 0);
                }
              );
            } else {
              this.setState(
                {
                  error: response.data.error,
                  loading: false,
                  success: "",
                },
                () => {
                  window.scrollTo(0, 0);
                }
              );
            }
          })
          .catch((err) => {
            console.dir(err);
            this.setState(
              {
                error: err,
                loading: false,
                success: "",
              },
              () => {
                window.scrollTo(0, 0);
              }
            );
          });
      }
    );
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }

    var error = "";
    if (this.state.error && this.state.error.length) {
      error = (
        <>
          <div className="alert alert-warning" role="alert">
            {this.state.error}
          </div>
          <br />
          <br />
        </>
      );
    }

    var success = "";
    if (this.state.success && this.state.success.length) {
      success = (
        <>
          <div className="alert alert-success" role="alert">
            {this.state.success}
          </div>
          <br />
          <br />
        </>
      );
    }

    return (
      <div className="Main Form">
        <p>
          <NavLink to={`/admin/books-list`} exact>
            Books
          </NavLink>
        </p>
        <h1>Add Book</h1>
        {success}
        {error}
        <Form
          submitHandler={this.handleSubmit.bind(this)}
          submitButtonLabel="Submit"
          fields={[
            {
              id: "title",
              label: "Title",
              type: "text",
              inputType: "text",
              required: true,
            },
            {
              id: "image",
              label: "Image",
              type: "text",
              inputType: "text",
              required: false,
            },
            {
              id: "author",
              label: "Author",
              type: "text",
              inputType: "text",
              required: true,
            },
            {
              id: "authorDesc",
              label: "Author Description",
              type: "textarea",
              inputType: "textarea",
              required: false,
            },
            {
              id: "description",
              label: "Description",
              type: "textarea",
              inputType: "textarea",
              required: false,
            },
            {
              id: "body",
              label: "Body",
              type: "textarea",
              inputType: "textarea",
              required: false,
            },
            {
              id: "video",
              label: "Video",
              type: "text",
              inputType: "text",
              required: false,
            },
            {
              id: "mediaUrl",
              label: "Media Url",
              type: "text",
              inputType: "text",
              required: false,
            },
            {
              id: "categories",
              label: "Categories*",
              type: "multi-select",
              required: true,
              inputType: "multi-select",
              options: this.state.categories,
              helpText: "Please select all that apply.",
            },
            {
              id: "goals",
              label: "Goals",
              type: "multi-select",
              required: false,
              inputType: "multi-select",
              options: this.state.goals,
              helpText: "Please select all that apply.",
            },
            {
              id: "tags",
              label: "Tags",
              type: "text",
              inputType: "text",
              required: false,
            },
            {
              id: "free",
              label: "Is Free?",
              type: "text",
              inputType: "text",
              required: false,
            },
            {
              id: "comingSoon",
              label: "Coming soon?",
              type: "text",
              inputType: "text",
              required: false,
            },
          ]}
          showLabels={true}
          errorHandler={(error) => {
            window.scrollTo(0, 0);
            this.setState({
              error,
            });
          }}
        />
      </div>
    );
  }
}

export default AdminBookAdd;
