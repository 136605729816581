import React, {Component} from 'react';
import axios from "../../../axios";
import Spinner from "../../Spinner/Spinner";
import {NavLink} from 'react-router-dom';

import "./Video.css"

class Video extends Component {

    state = {
        book: null,
        complete: false
    };

    handleVideoPlay() {
        if (window.gtag) {
            window.gtag('event', 'video_play_start', {
                'event_label': this.props.book.id,
                'event_category': 'video_play',
                'non_interaction': true
            });
        }
    }

    handleHasStarted () {
        axios.post('/api/user-book-history/' + this.props.match.params.id, {});
    }

    componentWillMount() {
        if (this.props.match.params.id) {
            axios.get('/api/books/' + this.props.match.params.id).then(response => {
                let book = response.data.result.book;
                this.setState({book: book, complete: true});
            }).catch(error => {
                this.setState({error: true});
            });
            this.handleHasStarted();
        }
    }

    render() {
        if (!this.state.complete) {
            return <Spinner/>;
        }
        if (this.state.book && this.state.book.video) {
            return (
                <div style={{maxWidth: "600px", textAlign: "center"}} className="Video Main">
                    <br />
                    {this.state.book.lastFree
                    && <div className="lastFree">
                        <h2>You read a lot. We like that</h2>
                        <br />
                        <p>You’ve reached the end of your free member preview for this month. Become a member now to
                            view this summary and get unlimited access to all of the summaries on Sumizeit.</p>
                        <br/>
                        <a href="/try">
                            <button className="StandardButton-4">Upgrade Now</button>
                        </a>
                    </div>
                    }
                    <br/>
                    <br/>
                    <h1>Watching {this.state.book.title}</h1>
                    <br/>
                    <br/>
                    <video width="100%" controls>
                        <source src={this.state.book.video} type="video/mp4"></source>
                        <p>Your browser does not support the video tag.</p>
                    </video>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <p>
                        <a href={`/books/${this.state.book.id}`}>Back to {this.state.book.title}</a>
                    </p>
                    <br/>
                    <br/>
                </div>
            );
        } else if (!this.state.book.showPremiumContent) {
            return (
                <div  className="Video Main">
                    <br />
                <div className="paywall">
                    <h1>Get the best of Sumizeit</h1>
                    <br/>
                    Please upgrade to see the full video content for this summary. <br/><br/>You look like someone who
                    appreciates a good story. Try a Sumizeit Premium Subscription and get unlimited access to our
                    large library of text and audio summaries.<br/><br/><a href="/try">
                    <button className="StandardButton-4">Upgrade Now</button>
                </a></div>
                </div>
            );
        } else {
            return (
                <div style={{maxWidth: "600px", textAlign: "center"}} className="Main">
                    <p>No video available yet for this book.</p>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <p>
                        <a href={`/books/${this.state.book.id}`}>Back to {this.state.book.title}</a>
                    </p>
                    <br/>
                    <br/>
                </div>
            )
        }
    }

}

export default Video;
