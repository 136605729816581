import React, {Component} from 'react';

import {NavLink} from 'react-router-dom';
import axios from '../../../axios';
import Utils from '../../../utils';
import "../BookDetail/BookDetail.css"
import {Twitter, Facebook, Linkedin, Google, Reddit, Mail} from 'react-social-sharing';
import MetaTags from 'react-meta-tags';
import BookList from "../BookList/BookList";
import Spinner from "../../Spinner/Spinner"

class Author extends Component {

    state = {
        books: null,
        error: null,
        loading: true
    };

    componentWillMount() {
        if (this.props.match && this.props.match.params && !this.state.books) {
            var author = decodeURIComponent(this.props.match.params.author);

            if (author) {
                axios.get('/api/books/list').then(response => {
                    if (response.data.books) {
                        var filter = {};
                        var books = response.data.books;
                        var keys = Object.keys(books);
                        var mapped = keys.map(key => {
                            var book = books[key];
                            
                            if (book.author.indexOf(author) !== -1) {
                                filter[key] = book;
                                book.authorDesc = book.authorDesc.split("\n").join("<br />");
                                return book;
                            }
                        });
                        this.setState({books: filter, loading: false});
                    } else {
                        this.setState({error: "There was an error."})
                    }
                }).catch(error => {
                    this.setState({error: true});
                });
            }
        }
    }

    render() {
        if (this.state.loading) { return <Spinner />; }
        if (this.state.error) return "";
        var post = "";
        if (this.state.books) {
            var book = this.state.books[Object.keys(this.state.books)[0]];
            var authorDesc = book.authorDesc.split("\n").join("<br />");
            post = (
                <div className="Main Author">
                    <MetaTags>
                        <title>{book.author} | Sumizeit</title>
                        <meta name="description" content={book.authorDesc}/>
                    </MetaTags>

                    <div className="BookDetail Main">
                        <p className="BookDetailSocialIcons">
                            <Facebook solidcircle small link={window.location.href}/>
                            <Linkedin solidcircle small link={window.location.href}/>
                            <Twitter solidcircle small link={window.location.href}/>
                            <Mail solidcircle small link={window.location.href}/>
                        </p>
                        <div>
                            <p>More from the author</p>
                            <h1 className="BookDetailTitle">{book.author}</h1>
                            <div className="BookDetail_body">
                                <h4>About the author</h4>
                                <br/>
                                <div dangerouslySetInnerHTML={{__html: authorDesc }}></div>

                                <BookList author={book.author} />
                            </div>
                        </div>
                        <div className="clear"></div>
                        <br/>
                    </div>
                </div>
            );
        }
        return post
    }

}

export default Author;
