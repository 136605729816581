import React from "react";

class Referred extends React.Component {
  state = {
    complete: false,
    error: null,
  };

  componentWillMount() {
    if (window.localStorage) {
      window.localStorage.setItem("rId", this.props.match.params.id);
    }

    setTimeout(() => {
      window.location.href = "/";
    }, 10);
  }

  render() {
    return (
      <p align="center" className="Main AlertSuccess">
        Redirecting you.
      </p>
    );
  }
}

export default Referred;
