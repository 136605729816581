import React, {Component} from "react";
import Axios from "../../axios";
import Spinner from "../Spinner/Spinner"
import queryString from "query-string";
import "../Login/Login.css";
import {NavLink} from "react-router-dom";
import MetaTags from "react-meta-tags";

class SubscriptionCodeActivate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            loading: false,
        };
    }

    componentDidMount() {
        const search = this.props.location.search;
        const error = new URLSearchParams(search).get("error");

        if (error && error.length) {
            this.setState({
                error: error,
            });
        }
    }

    handleSubmit(ev) {
        ev.preventDefault();
        const {email, password, firstName, lastName, code} = ev.target.elements;
        if (!email.value.length || !code.value.length || !firstName.value.length || !lastName.value.length || !password.value.length) {
            this.setState(
                {
                    error: "Please specify an email or code or password or first name or last name.",
                    loading: false,
                },
                () => {
                    window.scrollTo(0, 0);
                }
            );
        } else {
            Axios.post("/api/user/subscription-code-register", {
                username: email.value,
                password: password.value,
                firstName: firstName.value,
                lastName: lastName.value,
                code: code.value,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            })
                .then((response) => {
                    if (response.data.status === "ok") {
                        if (window.gtag) {
                            window.gtag("event", "register", {
                                event_category: "user",
                                event_label: "auth"
                            });
                        }

                        window.localStorage.setItem("token", response.data.result.token);
                        const values = queryString.parse(this.props.location.search);
                        if (values.redirect) {
                            window.location.href = values.redirect;
                        } else {
                            window.location.href = "/home";
                        }
                    } else {
                        this.setState(
                            {
                                error: response.data.error,
                                loading: false,
                            },
                            () => {
                                window.scrollTo(0, 0);
                            }
                        );
                    }
                })
                .catch((err) => {
                    console.dir(err);
                    this.setState(
                        {
                            error: err,
                            loading: false,
                        },
                        () => {
                            window.scrollTo(0, 0);
                        }
                    );
                });
        }
    }

    render() {
        if (this.state.loading) {
            return <Spinner/>;
        }

        var error = "";
        if (this.state.error && this.state.error.length) {
            error = (
                <>
                    <div className="alert alert-warning" role="alert">
                        {this.state.error}
                    </div>
                    <br/>
                    <br/>
                </>
            );
        }

        var redirect = null;
        const values = queryString.parse(this.props.location.search);

        if (values.redirect) {
            redirect = "?redirect=" + values.redirect;
        }


        var imgStyle = {
            backgroundImage: "url(/images/signUp.jpg)",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        };

        let code = "";
        if (this.props.match && this.props.match.params && this.props.match.params.code) {
            code = this.props.match.params.code;
        }

        return (
            <div className="Login">
                <MetaTags>
                    <title>Sign Up | Sumizeit</title>
                    <meta name="description" content="Login." />
                    <meta property="og:title" content="Login | MentorDial" />
                </MetaTags>
                <div className="d-lg-flex half">
                    <div className="bg order-1 order-md-2" style={imgStyle}></div>
                    <div className="contents order-2 order-md-1">
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-7">
                                    {error}
                                    <div className="mb-4">
                                        <h3>Hey there!</h3>
                                        <br />
                                        <p>Please share your name, email, and the subscription code to get unlimited access to Sumizeit.</p>
                                        <br />
                                        <p>If you are already a user, please use the password that you use to login to sumizeit.com. If you forgot your password, retrieve it <a href = "/forgot-password">here</a></p>

                                    </div>

                                    <form
                                        onSubmit={(ev) => this.handleSubmit(ev)}
                                        name="login"
                                        method="POST"
                                    >
                                        <div className="form-group first">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="email"
                                                name="email"
                                                placeholder="Email Address"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="firstName"
                                                name="firstName"
                                                placeholder="First Name"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="lastName"
                                                name="lastName"
                                                placeholder="Last Name"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <input
                                                type="password"
                                                className="form-control"
                                                name="password"
                                                id="password"
                                                placeholder="Password"
                                            />
                                        </div>

                                        <div className="form-group  last mb-3">
                                            <input
                                                value={code}
                                                type="text"
                                                className="form-control"
                                                id="code"
                                                name="code"
                                                placeholder="Subscription Code"
                                            />
                                        </div>

                                        <input
                                            type="submit"
                                            value="Sign Up"
                                            className="submitLoginBtn btn-block"
                                        />

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SubscriptionCodeActivate;
