import React, {Component} from 'react';
import axios from '../../axios';
import Spinner from '../Spinner/Spinner'
import {NavLink, Redirect} from "react-router-dom";

class SetKindle extends Component {

    state = {
        hasKindle: false,
        loading: true,
        complete: false,
        kindle_email: "",
        kindle_email_end: "@kindle.com"
    };

    componentDidMount() {
        axios.get('/kindle/has-kindle').then(response => {
            if (response.data.kindleEmail && response.data.kindleEmail.length > 0) {
                var kindleEmail = response.data.kindleEmail;
                var kSplit = kindleEmail.split("@");
                this.setState({kindle_email: kSplit[0], kindle_email_end: kSplit[1], loading: false})
            } else {
                this.setState({hasKindle: false, loading: false});
            }
        }).catch(error => {
            this.setState({error: true, loading: false});
        });
    }

    submit (event) {
        event.preventDefault();
        const {kindle_email, kindle_email_end} = event.target.elements;

        if (kindle_email.value && kindle_email.value.length) {
            axios.post('/kindle/set-kindle', {
                kindle_email: kindle_email.value,
                kindle_email_end: kindle_email_end.value
            }).then(response => {
                var result = response.data;

                if (result.error) {
                    this.setState({error: result.error, loading: false});
                } else {
                    this.setState({complete: true, loading: false});
                }
            }).catch(error => {
                this.setState({error: error.message, loading: false});
            });
        } else {
            this.setState({error: "Please complete the form."});
        }
    }

    handleChange = (event) =>
        this.setState({[event.target.name]: event.target.value});

    render() {
        if (this.state.loading) return (<Spinner />);
        if (this.state.complete) return (<div align="center" className="Main AlertSuccess">
            Successful set your kindle email.
            <br />
            <br />
            <NavLink to="/account">Back to account</NavLink>
        </div>);

        var error = "";
        if (this.state.error) {
            error = (
                <div className="alert alert-danger" role="alert">
                    {this.state.error}
                </div>
            )
        }
        var result = (
            <div className="container Main">
                <h1 align="center">Set up your Kindle</h1>
                <br />
                <form onSubmit={this.submit.bind(this)} style={{margin: "auto", maxWidth: "400px"}}>
                    {error}
                    <p>Send sumizeit originals right to your Kindle.</p>
                    <br />
                    <br />
                    <p>1. Add sumizeit to your approved document senders to receive documents from sumizeit.</p>
                    <br />
                     <p>Go <a href ="https://www.amazon.com/mn/dcw/myx.html/ref=kinw_myk_redirect#/home/settings/payment" target="_blank">here</a>. Click on "Personal Document Settings". Your kindle's email address will be listed. Please input it below. Go to the section <b>Approved Personal Document E-mail List</b> and add kindle@sumizeitmail.com as an approved email address.</p>
                    <br />
                    <p>2. Add your Kindle email address below.</p>
                    <br />
                    <input name="kindle_email" className="form-control" type="text" placeholder="email" onChange={this.handleChange.bind(this)} value={this.state.kindle_email}/>
                    <select name="kindle_email_end" value={this.state.kindle_email_end} onChange={this.handleChange.bind(this)}>
                        <option value="@kindle.com">kindle.com</option>
                        <option value="@kindle.cn">kindle.cn</option>
                        <option value="@kindle.ca">kindle.ca</option>
                        <option value="@kindle.it">kindle.it</option>
                        <option value="@free.kindle.com">free.kindle.com</option>
                    </select>
                    <br/>
                    <br />
                    <button type="submit" className="StandardButton-2">Submit</button>
                </form>
            </div>
        );
        return result
    }
}

export default SetKindle;
