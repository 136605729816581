import React, {Component} from 'react';
import "./Audio.css"
import $ from 'jquery';

function getTime(time) {
    if (!isNaN(time)) {
        return (
            Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
        );
    }
}
class Audio extends Component {

    state = {
        selectedTrack: null,
        player: "paused",
        currentTime: null,
        duration: null,
        playbackRate: 1,
        startedPlaying: false
    };

    componentDidMount() {
        this.player.addEventListener("timeupdate", e => {
            this.setState({
                currentTime: e.target.currentTime,
                duration: e.target.duration
            });
        });
    }

    componentWillUnmount() {
        this.player.removeEventListener("timeupdate", () => {});
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.player !== prevState.player) {
            if (this.state.player === "paused") {
                this.player.pause();
            } else if (this.state.player === "stopped") {
                this.player.pause();
                this.player.currentTime = 0;
                this.setState({ selectedTrack: null });
            } else if (
                this.state.player === "playing" &&
                prevState.player === "paused"
            ) {
                this.player.play();
            }
        }
    }

    updateTime(e) {
        let posX = e.pageX - $(e.currentTarget).offset().left;
        let progressWidth = $(e.currentTarget).outerWidth();

        // create percentage from position etc
        let percentage = parseFloat((posX / progressWidth) * 100);
        var currentTime = (percentage / 100 * this.player.duration);
        this.player.currentTime = currentTime;
        this.setState({
            currentTime: currentTime
        });
    }

    togglePlay() {
        if (this.state.player !== "playing") {
            this.setState({
                player: "playing"
            }, () => {
                if (this.state.player === "playing") {
                    this.props.startedPlaying();
                }
            });

            if (window.gtag) {
                window.gtag('event', 'audio_play_start', {
                    'event_label': this.props.book.id,
                    'event_category': 'audio_play',
                    'non_interaction': true
                });
            }
        } else {
            this.setState({
                player: "paused"
            })
        }
    }

    updateRate() {
        var rate = this.state.playbackRate + 0.25;

        if (rate > 2) {
            rate = 0.5;
        }
        this.player.playbackRate = rate;
        this.setState({
            playbackRate: rate
        })
    }

    render() {
        var post = "";
        if (this.props.book) {

            const currentTime = getTime(this.state.currentTime);
            const duration = getTime(this.state.duration);

            var progress = this.state.currentTime * 100/this.state.duration;
            var rate = this.state.playbackRate + "x";

            post = (
                <div className="Audio">
                    {this.state.player === "playing"
                    && <button className="play-pause icon-pause" onClick={this.togglePlay.bind(this)}></button>
                    }
                    {this.state.player !== "playing"
                    && <button className="play-pause icon-play" onClick={this.togglePlay.bind(this)}></button>
                    }
                    <div className="current-time">{currentTime}</div>
                    <div className="audio-progress-bar-wrapper" onClick={this.updateTime.bind(this)}>
                        <div className="audio-progress" style={{width: progress + "%"}}></div>
                        <div className="audio-bubble" style={{left: progress + "%"}}></div>
                    </div>
                    <audio ref={ref => (this.player = ref)}>
                        <source src={this.props.book.mediaUrl} type="audio/mpeg" />
                    </audio>
                    <div className="duration">{duration}</div>
                    <button className="rate" onClick={this.updateRate.bind(this)}>Audio Speed {rate}</button>
                </div>
            );
        }
        return post
    }

}

export default Audio;
