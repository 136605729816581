import React, { Component } from "react";
import axios from "../../../axios";
import _ from "lodash";
import "../Admin.css";
import { NavLink } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import AdminNavigation from "../Navigation/AdminNavigation";

class AdminBookList extends Component {
  state = {
    books: null,
    sort: "uploadDate",
    loading: true,
  };

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    axios
      .get("/api/admin/books/")
      .then((response) => {
        if (response.data.status === "ok") {
          this.setState({
            books: response.data.result.books,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
            error: response.data.error,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: "There was an error processing your request",
        });
      });
  }

  sortBy = (key) => {
    this.setState({ sort: key });
  };

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }
    if (this.state.books) {
      var result = this.state.books;

      const header = (
        <tr>
          <th>ID</th>
          <th>Title</th>
          <th>Edit</th>
        </tr>
      );
      const booksHtml = result.map((b) => {
        return (
          <tr>
            <td>{b.id}</td>
            <td>
              {b.title} by {b.author}
            </td>
            <td>
              <NavLink to={`/admin/book-edit/${b.id}`} exact>
                Edit
              </NavLink>
            </td>
            <td>
              <NavLink to={`/admin/book-photo/${b.id}`} exact>
                Photo
              </NavLink>
            </td>
            <td>
              <NavLink to={`/admin/book-media/${b.id}`} exact>
                Media
              </NavLink>
            </td>
            <td>
              <NavLink to={`/admin/book-infographic/${b.id}`} exact>
                Infographic
              </NavLink>
            </td>
            <td>
              <NavLink to={`/admin/book-vector/${b.id}`} exact>
                Vector
              </NavLink>
            </td>
          </tr>
        );
      });

      var count = result.length;

      return (
        <div className="container AdminBookList Admin">
          <AdminNavigation />
          <br />
          <br />
          <p>
            <NavLink to={`/admin/book-add`} exact>
              Add book
            </NavLink>
          </p>
          <br />
          <br />
          <p>#{count} books</p>
          <table>
            {header}
            {booksHtml}
          </table>
        </div>
      );
    }
    return "";
  }
}

export default AdminBookList;
