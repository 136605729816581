import React, { Component } from "react";
import axios from "../../../axios";
import "../Admin.css";
import Spinner from "../../Spinner/Spinner";
import AdminNavigation from "../Navigation/AdminNavigation";

class AdminCollectionEdit extends Component {
  state = {
    collection: null,
    loading: true,
    keys: {
      id: "",
      categories: "",
      image: "",
      title: "",
      subtitle: "",
      uploadDate: "",
    },
  };

  componentWillMount() {
    if (this.props.match && this.props.match.params.id) {
      const id = this.props.match.params.id;
      axios
        .get("/admin/collections/detail/" + id)
        .then((response) => {
          this.setState({ collection: response.data.collection });
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ error: true });
          this.setState({ loading: false });
        });
    }
  }

  _onSubmit(ev) {
    ev.preventDefault();
    this.setState({ loading: true });
    if (this.props.match && this.props.match.params.id) {
      const id = this.props.match.params.id;
      axios
        .post("/admin/collections/detail/" + id, {
          values: this.state.collection,
        })
        .then((response) => {
          this.setState({ collection: response.data.collection });
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ error: true });
        });
    }
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }
    if (this.state.collection) {
      const mapped = Object.keys(this.state.keys).map((val) => {
        var input;
        var value = this.state.collection[val];
        if (typeof value === "object") {
          value = value.join(",");
        }
        input = (
          <input
            type="text"
            name={val}
            value={value}
            id={val}
            onChange={(event) => {
              var newCourse = { ...this.state.collection };
              newCourse[val] = event.target.value;
              this.setState({ collection: newCourse });
            }}
          />
        );

        return (
          <div>
            <label>{val}</label>
            <br />
            <br />
            {input}
          </div>
        );
      });
      return (
        <div className="Main">
          <form onSubmit={this._onSubmit.bind(this)} className="Admin">
            <AdminNavigation />
            <div className="BookEdit AdminEdit Admin">
              {mapped}
              <input type="submit" name="submit" value="Submit" />
            </div>
          </form>
        </div>
      );
    }
    return "";
  }
}

export default AdminCollectionEdit;
