import React, { Component } from 'react';

import { NavLink } from 'react-router-dom';
import BookList from "../BookList/BookList";
import MetaTags from "react-meta-tags";

class BookListPage extends Component {

    state = {
        onlyShowVideo: false,
        onlyShowAudio: false
    };

    handleOnlyShowAudio (e) {
        this.setState({onlyShowAudio: !this.state.onlyShowAudio});
    }

    handleOnlyShowVideo (e) {
        this.setState({onlyShowVideo: !this.state.onlyShowVideo});
    }

    render () {

        var sort = "";
        var paramSort = "";
        if (this.props.match && this.props.match.params.sort) {
            sort = this.props.match.params.sort;
            paramSort = sort;
        }

        var audio = this.state.onlyShowAudio;
        if (sort === "audio") {
            sort = "new";
            audio = true;
        }


        var video = this.state.onlyShowVideo;
        if (sort === "video") {
            sort = "new";
            video = true;
        }

        var tag = "";
        if (this.props.match && this.props.match.params.tag) {
            tag = this.props.match.params.tag;
        }

        var collection = "";
        if (this.props.match && this.props.match.params.collection) {
            collection = this.props.match.params.collection;
        }

        var title = "";
        var category = null;
        var latestOrNew = "";
        var metaDesc = "Search for a non fiction book summary.";
        if (this.props.match && this.props.match.params.category) {
            category = this.props.match.params.category;
            metaDesc = `Short Non Fiction book summaries about the topic ${category}`;
            title = (<h1 className="BookList_title MainTitle" align="center">{this.props.match.params.category}</h1>);
        } else if (this.props.match && this.props.match.params.collection) {
            latestOrNew = (
                <div style={{margin: "10px auto", textAlign: "center"}}><a href="/collections">Back to collections</a></div>
            )
        } else {
            title = "";
            latestOrNew = (
                <div>
                    <div style={{"margin": "0 auto", "textAlign": "center"}}>

                        <NavLink  to={`/booklist/new/${tag}`} key="trending">
                            <button className="button ButtonStandard">Latest</button>
                        </NavLink>

                        <NavLink  to={`/booklist/trending/${tag}`} key="trending">
                            <button className="button ButtonStandard right">Popular</button>
                        </NavLink>
                    </div>
                    <br />
                    <br />
                </div>
            )
        }
        return (
            <div className="Main">
                <MetaTags>
                    <title>Discover the books that will help you grow | Sumizeit</title>
                    <meta name="description" content={metaDesc} />
                </MetaTags>
                {title}

                {latestOrNew}

                {paramSort !== "video" && paramSort !== "audio"

                && <div><p><input type="checkbox" name="only-audio" checked={audio}
                                  onChange={this.handleOnlyShowAudio.bind(this)}/> Only show titles with audio</p>
                    <p><input type="checkbox" name="only-video" checked={video}
                              onChange={this.handleOnlyShowVideo.bind(this)}/> Only show titles with video</p>
                </div>
                }
                <BookList sort = {sort} category = {category} tag={tag} audio={audio} video={video} collection={collection} />
            </div>
        )
    }
}

export default BookListPage;
