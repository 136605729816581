import React, {Component} from "react";
import Select from "react-select";
import PropTypes from 'prop-types';

function isEmptyObj(obj) {
    return Object.keys(obj).length === 0;
}

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            loading: true,
            values: {}
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        var values = {};
        this.props.fields.forEach(item => {
            values[item.id] = item.value
        })
        this.setState({
            values,
            loading: false
        })
    }

    handleSubmit (ev) {
        ev.preventDefault();

        var errorExists = false;
        this.props.fields.forEach(item => {
            var itemValue = this.state.values[item.id];
            if (item.required) {
                if (item.inputType === "number" && isNaN(itemValue)) {
                    errorExists = true;
                    this.props.errorHandler(`Please make sure ${item.label.replace("*", "")} is greater or equal to 0.`);
                    return false;
                } else if (((["text", "password", "textarea", "date"].indexOf(item.inputType) !== -1) && (!itemValue || !itemValue.length || !itemValue.trim().length))) {
                    errorExists = true;
                    this.props.errorHandler(`Please specify ${item.label.replace("*", "")}.`);
                    return false;
                } else if (item.inputType === "normal-select" && (!itemValue || itemValue === "")) {
                    errorExists = true;
                    this.props.errorHandler(`Please specify ${item.label.replace("*", "")}.`);
                    return false;
                } else if (item.inputType === "select" && (!itemValue || isEmptyObj(itemValue))) {
                    errorExists = true;
                    this.props.errorHandler(`Please specify ${item.label.replace("*", "")}.`);
                    return false;
                } else if (item.inputType === "multi-select" && (!itemValue || itemValue.length < 1)) {
                    errorExists = true;
                    this.props.errorHandler(`Please specify ${item.label.replace("*", "")}.`);
                    return false;
                }
            }
            return true;
        });

        if (!errorExists) {
            this.props.submitHandler(this.state.values);
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var values = Object.assign({}, this.state.values);
        values[name] = value;

        this.setState({
            values
        });
    }

    handleSelectChange (id, value) {
        if (typeof value === "object") {
            var values = Object.assign({}, this.state.values);
            values[id] = value;

            this.setState({
                values
            })
        }
    }

    getSelectValue(id) {
        if (typeof this.state.values[id] === "object") {
            return this.state.values[id];
        }
        var found = null;
        this.props.fields.forEach(item => {
            if (item.type === "select" && item.id === id) {
                item.options.forEach(o => {
                    var value = this.state.values[id];
                    if (this.state.values.hasOwnProperty(id) && o.value === value) {
                        found = o;
                    }
                })
            }
        })
        return found;
    }

    handleFileChange (ev, id) {
        ev.preventDefault();
        var values = Object.assign({}, this.state.values);
        values[id] = {
            fileName: ev.target.files[0].name,
            file: ev.target.files[0]
        };

        this.setState({
            values
        });
    }

    render () {
        if (!this.state.loading) {
            var fields = this.props.fields.map(item => {
                var html = '';
                var selectBody;
                var value;
                if (item.type === "text") {
                    html = <input type={item.inputType} placeholder={item.label} value={this.state.values[item.id]}
                                  name={item.id}
                                  className="form-control item" id={item.id} aria-describedby={`${item.id}Help`}
                                  onChange={this.handleInputChange}/>
                } else if (item.type === "date") {
                    html = <input type={item.inputType} placeholder={item.label} value={this.state.values[item.id]} name={item.id}
                                  className="form-control item" id={item.id} aria-describedby={`${item.id}Help`} onChange={this.handleInputChange} />
                } else if (item.type === "textarea") {
                    html = <textarea id={item.id} placeholder={item.label}  name={item.id} value={this.state.values[item.id]} onChange={this.handleInputChange} className="form-control item" />
                } else if (item.type === "select") {
                    value = this.getSelectValue(item.id);
                    html = <div className="select"><Select options={item.options} placeholder={item.label} value={value}
                                                           onChange={(value) => {
                                                               this.handleSelectChange(item.id, value);
                                                           }}/></div>;
                } else if (item.inputType === "normal-select") {
                    selectBody = item.options.map(item => {
                        var selected = item.value;
                        var value = this.getSelectValue(item.id);
                        if (value === item.value) {
                            selected = item.value + " selected";
                        }
                        return <option value={selected}>{item.label}</option>
                    });
                    html = <select name={item.id} onChange={(ev) => {
                        this.handleInputChange(ev);
                    }}>{selectBody}</select>;
                } else if (item.type === "file") {
                    html = <input id={item.id} type="file" placeholder={item.label}  className="form-control" onChange={(ev) => {
                        this.handleFileChange(ev, item.id);
                    }} name={item.id} />;
                } else if (item.type === 'multi-select') {
                    html = <div className="select"><Select options={item.options} isMulti={true} placeholder={item.label}  value={this.state.values[item.id]}  onChange={(value) => {
                        this.handleSelectChange(item.id, value);
                    }}/></div>;
                }  if (item.type === "hidden") {
                    html = <input type={item.inputType} value={this.state.values[item.id]}
                                  name={item.id} id={item.id} />
                } else if (item.type === "readonly") {
                    let value = this.state.values[item.id];
                    if (item.inputType === "date") {
                        value = new Date(value).toLocaleString();
                    }
                    html = (<div>
                            {item.showLabel && !this.props.showLabels && <p>{item.label}<br /><br /></p>}
                            {value}
                        </div>
                    );
                }
                return <div className="row" key={`row-${item.id}`}>
                    <div className="col-sm-12">
                        <div className="form-group">
                            {this.props.showLabels &&  <label htmlFor={item.id} className="form-label">{item.label}&nbsp;&nbsp;</label>}
                            {html}
                            {item.helpText && <div id={`${item.id}Help`} className="form-text">{item.helpText}</div>}
                        </div>
                    </div>
                </div>
            })
            return (
                <form onSubmit={(ev) => this.handleSubmit(ev)} className="Form" name="form" method="POST">
                    {fields}
                    <button type="submit" className="submitBtn">{this.props.submitButtonLabel}</button>
                </form>
            )
        } else {
            return <div></div>;
        }
    }
}

Form.propTypes = {
    errorHandler: PropTypes.func,
    submitHandler: PropTypes.func,
    fields: PropTypes.array,
    submitButtonLabel: PropTypes.string
};

export default Form;