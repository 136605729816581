import React, {Component} from "react";
import "../Admin.css";
import {NavLink} from "react-router-dom";

class AdminNavigation extends Component {
    render() {
        return (
            <div className="Admin_Navigation">
                <NavLink to="/admin/users">Manage Users</NavLink>&nbsp;&nbsp;
                <NavLink to="/admin/books-list">Manage Books</NavLink>&nbsp;&nbsp;
                <NavLink to="/admin/blog-list">Manage Blog</NavLink>&nbsp;&nbsp;
                <NavLink to="/admin/insights">Manage Insights</NavLink>&nbsp;&nbsp;
                <NavLink to="/admin/goals">Manage Goals</NavLink>&nbsp;&nbsp;
                <NavLink to="/admin/visuals">Manage Visuals</NavLink>&nbsp;&nbsp;
                <NavLink to="/admin/podcasts">Manage Podcasts</NavLink>&nbsp;&nbsp;
            </div>
        );
    }
}

export default AdminNavigation;
