import React, { Component } from "react";
import axios from "../../axios";
import "./Home.css";
import Spinner from "../Spinner/Spinner";
import { NavLink } from "react-router-dom";

class Visual extends Component {
    state = {
        loading: true,
        visuals: null,
        index: 0
    };

    componentDidMount() {
        this.fetchVisuals(this.props.match.params.id);
    }

    fetchVisuals(id) {
        axios
            .get("/api/visuals/book/" + id)
            .then((response) => {
                var r = response.data;

                if (r.error) {
                    this.setState({
                        error: r.error,
                        loading: false,
                    });
                } else if (r.status === "ok") {
                    if (!r.result.isPaid) {
                        window.location.href = "/try";
                    } else {
                        this.setState(
                            {
                                loading: false,
                                visuals: r.result.visuals
                            }
                        );
                    }
                }
            })
            .catch((error) => {
                this.setState({
                    error: error.message,
                    loading: false
                });
            });
    }

    handlePrev() {
        this.setState({
            index: this.state.index - 1
        });
    }
    handleNext() {
        this.setState({
            index: this.state.index + 1
        });
    }


    render() {
        if (this.state.loading) {
            return <Spinner />;
        }

        const visual = this.state.visuals[this.state.index];

        return (
            <div className="Home Main Visual">
                <h2>{visual.title}</h2>
                <p>{visual.body}</p>
                <img src={visual.image} className="Home_Visual_Img" alt={visual.title}/>

                <div className="Home_Visual_Buttons">
                    {this.state.index > 0 && <button className="Home_Visual_Prev" onClick={this.handlePrev.bind(this)}></button>}
                    {this.state.index < (this.state.visuals.length - 1) && <button className="Home_Visual_Next" onClick={this.handleNext.bind(this)}></button>}
                </div>
                <p className="Home_Visual_Back_To">
                    <NavLink
                        to="/visuals"
                    >
                        Back to Visuals
                    </NavLink>
                </p>
            </div>
        );
    }
}

export default Visual;
