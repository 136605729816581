import React from "react";
import { Route, Redirect } from "react-router-dom";
import Utils from "../utils"

const ProtectedRoute = ({
                                   path,
                                   component: Component,
                                   render,
                                   ...rest
                               }) => {
    return (
        <Route
            path={path}
            {...rest}
            render={props => {
                if (Utils.isAuthenticated()) {
                    return Component ? <Component {...props} /> : render(props);
                } else {
                    return <Redirect to={`/signup?redirect=${props.location.pathname}${props.location.search}`} />;
                }
            }}
        />
    );
};

export default ProtectedRoute;
