import React, {Component} from "react";
import axios from "../../../axios";
import "../Admin.css";
import Spinner from "../../Spinner/Spinner";
import Form from "../../Form/Form";
import _ from "lodash";
import {NavLink} from "react-router-dom";

class AdminGoalAdd extends Component {
    state = {
        loading: false,
        error: "",
        success: ""
    };

    componentWillMount() {
    }

    handleSubmit(values) {
        const output = _.cloneDeep(values);

        this.setState(
            {
                loading: true
            },
            () => {
                axios
                    .post("/api/admin/goals/", output)
                    .then((response) => {
                        if (response.data.status === "ok") {
                            this.setState(
                                {
                                    error: "",
                                    loading: false,
                                    success: "Goal was added."
                                },
                                () => {
                                    window.scrollTo(0, 0);
                                }
                            );
                        } else {
                            this.setState(
                                {
                                    error: response.data.error,
                                    loading: false,
                                    success: ""
                                },
                                () => {
                                    window.scrollTo(0, 0);
                                }
                            );
                        }
                    })
                    .catch((err) => {
                        console.dir(err);
                        this.setState(
                            {
                                error: err,
                                loading: false,
                                success: ""
                            },
                            () => {
                                window.scrollTo(0, 0);
                            }
                        );
                    });
            }
        );
    }

    render() {
        if (this.state.loading) {
            return <Spinner/>;
        }

        var error = "";
        if (this.state.error && this.state.error.length) {
            error = (
                <>
                    <div className="alert alert-warning" role="alert">
                        {this.state.error}
                    </div>
                    <br/>
                    <br/>
                </>
            );
        }

        var success = "";
        if (this.state.success && this.state.success.length) {
            success = (
                <>
                    <div className="alert alert-success" role="alert">
                        {this.state.success}
                    </div>
                    <br/>
                    <br/>
                </>
            );
        }

        return (
            <div className="Main Form">
                <p>
                    <NavLink to={`/admin/goals`} exact>
                        Goals
                    </NavLink>
                </p>
                <h1>Add Goal</h1>
                {success}
                {error}
                <Form
                    submitHandler={this.handleSubmit.bind(this)}
                    submitButtonLabel="Submit"
                    fields={[
                        {
                            id: "title",
                            label: "Title",
                            type: "text",
                            inputType: "text",
                            required: true
                        },
                        {
                            id: "shortTitle",
                            label: "Short Title",
                            type: "text",
                            inputType: "text",
                            required: true
                        },
                        {
                            id: "desc",
                            label: "Description",
                            type: "textarea",
                            inputType: "textarea",
                            required: false
                        }
                    ]}
                    showLabels={true}
                    errorHandler={(error) => {
                        window.scrollTo(0, 0);
                        this.setState({
                            error
                        });
                    }}
                />
            </div>
        );
    }
}

export default AdminGoalAdd;
