import React, {Component} from "react";
import Spinner from '../../Spinner/Spinner';
import Axios from "../../../axios";
import {NavLink} from "react-router-dom";
import AdminNav from "../Navigation/AdminNavigation";
import Alert from "react-bootstrap/Alert";
import _ from "lodash";
import Form from "../../../Form";

class AdminBlogEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            loading: false,
            success: '',
            result: []
        };


        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values) {
        this.setState({
            loading: true
        }, () => {
            Axios.post("/api/admin/blog", values).then((response) => {
                if (response.data.status === "ok") {
                    this.setState({
                        error: '',
                        loading: false,
                        success: "The post has been added."
                    }, () => {
                        window.scrollTo(0, 0)
                    });
                } else {
                    this.setState({
                        error: response.data.error,
                        loading: false,
                        success: ''
                    }, () => {
                        window.scrollTo(0, 0)
                    });
                }
            }).catch (err => {
                console.dir(err);
                this.setState({
                    error: err,
                    loading: false,
                    success: ''
                }, () => {
                    window.scrollTo(0, 0)
                })
            });
        })
    }

    render() {
        if (this.state.loading) {
            return (<Spinner />);
        }

        var error = '';
        if (this.state.error && this.state.error.length) {
            error = (<Alert key="warning-1" variant="warning">{this.state.error}</Alert>);
        }

        var success = '';
        if (this.state.success && this.state.success.length) {
            success = (<Alert key="success-1" variant="success">{this.state.success}</Alert>);
        }

        const result = this.state.result;

        return <div className="container Inner">
            <div className="row">
                {error}
                {success}
                <AdminNav />
                <h1>Add Post</h1>
                <Form fields={[
                    {
                        id: "title",
                        label: "Title",
                        type: "text",
                        inputType: "text",
                        value: result.title,
                        required: true
                    },
                    {
                        id: "description",
                        label: "Description",
                        type: "text",
                        inputType: "text",
                        value: result.description,
                        required: true
                    },
                    {
                        id: "categories",
                        label: "Categories",
                        type: "text",
                        inputType: "text",
                        value: result.categories,
                        required: true
                    },
                    {
                        id: "text",
                        label: "Text",
                        type: "textarea",
                        inputType: "textarea",
                        value: result.text,
                        required: true
                    },
                ]} submitHandler={this.handleSubmit} errorHandler={(error) => {
                    this.setState({
                        error
                    })
                }} submitButtonLabel="Submit" />
            </div>
        </div>
    }
}

export default AdminBlogEdit;