import React, { Component } from "react";
import axios from "../../../axios";
import "../Admin.css";
import { NavLink } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import AdminNavigation from "../Navigation/AdminNavigation";

class AdminVisualList extends Component {
    state = {
        visuals: [],
        loading: true,
    };

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        axios
            .get("/api/admin/visuals")
            .then((response) => {
                if (response.data.status === "ok") {
                    this.setState({
                        visuals: response.data.result.visuals,
                        loading: false,
                    });
                } else {
                    this.setState({
                        loading: false,
                        error: response.data.error,
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: "There was an error processing your request",
                });
            });
    }

    render() {
        if (this.state.loading) {
            return <Spinner />;
        }
        if (this.state.visuals) {
            var result = this.state.visuals;

            const header = (
                <tr>
                    <th>ID</th>
                    <th>Book ID</th>
                    <th>Title</th>
                    <th>Edit</th>
                    <th>Photo</th>
                    <th>Delete</th>
                </tr>
            );
            const visualsHtml = result.map((b) => {
                return (
                    <tr>
                        <td>{b.id}</td>
                        <td>
                            {b.bookId}
                        </td>

                        <td>
                            {b.title}
                        </td>
                        <td>
                            <NavLink to={`/admin/visuals-update/${b.id}`} exact>
                                Edit
                            </NavLink>
                        </td>
                        <td>
                            <NavLink to={`/admin/visuals-photo/${b.id}`} exact>
                                Photo
                            </NavLink>
                        </td>
                        <td>
                            <NavLink to={`/admin/visuals-delete/${b.id}`} exact>
                                Delete
                            </NavLink>
                        </td>
                    </tr>
                );
            });

            var count = result.length;

            return (
                <div className="container AdminBookList Admin">
                    <AdminNavigation/>
                    <br />
                    <br />
                    <p>
                        <NavLink to={`/admin/visuals-add`} exact>
                            Add Visual
                        </NavLink>
                    </p>
                    <br />
                    <br />
                    <p>#{count} Visual</p>
                    <table>
                        {header}
                        {visualsHtml}
                    </table>
                </div>
            );
        }
        return "";
    }
}

export default AdminVisualList;
