import React, {Component} from 'react';
import axios from '../../../axios';
import _ from 'lodash'
import "../Admin.css"
import {NavLink} from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import AdminNavigation from "../Navigation/AdminNavigation";

class AdminCollectionList extends Component {
    state = {
        sort: "uploadDate",
        loading: true,
        collections: []
    };

    componentDidMount() {
        axios.get('/admin/collections/list').then(response => {
            this.setState({collections: response.data.results, loading: false});
        }).catch(error => {
            this.setState({error: error, loading: false});
        });
    }

    sortBy = (key) => {
        this.setState({sort: key})
    };

    render() {
        if (this.state.loading) {
            return <Spinner/>;
        }
        if (this.state.collections) {
            this.state.collections = _.sortBy(this.state.collections, [(o) => {
                if (this.state.sort.indexOf("Count") !== -1 || this.state.sort.indexOf("Date") !== -1) {
                    return -o[this.state.sort]
                }
                return o[this.state.sort]
            }]);

            var fields = ["id", "title"];
            var header = fields.map(heading => {
                return <th><a href="#" onClick={(ev) => {
                    ev.preventDefault();
                    this.sortBy(heading)
                }}>{heading}</a></th>
            });
            var keys = Object.keys(this.state.collections);
            var field_mapped = keys.map(key => {
                var mapped = fields.map(field => {
                    return <td>{this.state.collections[key][field]}</td>;
                });
                return <tr>
                    {mapped}
                    <td>
                        <div className="Admin_dropdown">
                            <button className="Admin_dropbtn">...</button>
                            <div className="Admin_dropdown-content">
                                <NavLink
                                    to={`/admin/collection-edit/${this.state.collections[key]['id']}`}
                                    exact>Edit</NavLink>
                            </div>
                        </div>
                    </td>
                </tr>;
            });

            var count = keys.length;

            return (<div className="Main">
                    <div className="AdminBookList Admin">
                        <AdminNavigation />
                        <br />
                        <NavLink to={`/admin/collection-add`} exact>Add Collection</NavLink>
                        <br/>
                        <br/>
                        <p>#{count} collections</p>
                        <table width="100%">
                            <tr>
                                {header}
                                <th>Manage</th>
                            </tr>
                            {field_mapped}
                        </table>

                    </div>
                </div>
            );
        } else {

            return (
                <div className="Main">
                    <AdminNavigation />
                    <br />
                    <NavLink to={`/admin/collection-add`} exact>Add Collection</NavLink>
                </div>
            );
        }
    }
}

export default AdminCollectionList;
