import React, {Component} from 'react';
import axios from '../../axios';
import "./GiftCard.css"
import savetime from "../../assets/images/time.svg";
import learnongo from "../../assets/images/learnonthego.svg";
import getsmarter from "../../assets/images/getsmarter.png";
import {NavLink} from "react-router-dom";


class GiftCard extends Component {
    render() {
        return (
            <div className="Giftcard">
                <div className="Giftcard-hero">
                    <h1 className="Giftcard-herotitle">The gift of knowledge</h1>
                    <h2 className="Giftcard-herosubtitle">Give a year of Sumizeit and help someone reach their learning potential.</h2>
                    <div className="Giftcard-img"></div>
                </div>
                <div className="Giftcard-inner">
                    <h2 className="Giftcard-whatmakes">What makes Sumizeit such a great gift?</h2>
                    <div className="Giftcard-benefits">
                        <div className="Giftcard-benefit">
                            <img src={savetime} />
                            <h3>Save Time</h3>
                            <p>Save time and learn quickly with unlimited access to a large library of 10-15 minute business, self-help, and nonfiction book summaries.</p>
                        </div>
                        <div className="Giftcard-benefit">
                            <img src={getsmarter} />
                            <h3>Get Smarter</h3>
                            <p>Learn from industry leaders and best selling authors about various topics such as Business, Psychology, Politics, Health & Nutrition, and Self Development.</p>
                        </div>
                        <div className="Giftcard-benefit">
                            <img src={learnongo} />

                            <h3>Learn on the go</h3>
                            <p>Use the mobile app as a life coach to take wherever you go. Read or listen anytime, anywhere on mobile or desktop.</p>
                        </div>
                    </div>
                </div>
                <div className="Giftcard-plan">
                    <h2>1 year of Sumizeit Premium</h2>
                    <p className="Giftcard-planprice">$44.99</p>
                    <p className="Giftcard-planpricefineprint">One-time fee</p>
                    <p>Unlimited access to Sumizeit's nonfiction book summaries. This is our most popular offer.</p>
                    <NavLink to="/payment/gift?gift=true"><button className="StandardButton-6">Get Sumizeit now</button></NavLink>
                </div>

                <div className="Giftcard-moreinfo">
                    When you sign up for a gift card, the recipient will receive a link to a page on our website that allows them to sign up for a Sumizeit Premium account using a code. The recipient will not have to enter credit card details because he has a code that automatically gives him access to a Premium account. As soon as the recipient signs up, he has a year until his Premium account expires. Gift cards do not expire - you can gift the gift card to someone at any time. If you want to give the recipient lifetime access to Sumizeit, purchase a gift card and send us an email at info@sumizeit.com.
                </div>
            </div>
        );
    }
}

export default GiftCard;
