import React, { Component } from "react";

import "./Header.css";
import Utils from "../../../utils";
import { NavLink } from "react-router-dom";

class Header extends Component {
  state = {
    showOff40: true,
  };

  componentWillMount() {
    var off40 = window.sessionStorage
      ? window.sessionStorage.getItem("40off")
      : false;
    off40 = off40 !== "close";

    this.setState({
      showOff40: off40,
    });
  }

  toggleMobile(event) {
    event.preventDefault();
    var x = document.getElementById("header");
    if (x.className === "header") {
      x.className += " responsive";
    } else {
      x.className = "header";
    }
  }

  handleStartForFreeClick() {
    document.querySelector(".startForFreeBtn").onclick = function () {
      if (window.gtag) {
        window.gtag("event", "startForFreeBtnBtnClick", {
          event_category: "ctaClick",
          non_interaction: true,
        });
      }
    };
  }

  handleFloatingBannerClose() {
    if (window.sessionStorage) {
      window.sessionStorage.setItem("40off", "close");
    }
    this.setState({
      showOff40: false,
    });
  }

  render() {
    const isAuthenticated = Utils.isAuthenticated();
    return (
      <div id="header" className="container header">
        <div className="row">
          <div className="col-md-3">
            <a href="/" className="logo div"></a>
          </div>
          <div className="col-md-9">
            <div className="Header_DropdownMenu">
              <a href="/books">Book Summaries</a>
              <a href="/infographics">Infographics</a>
              <a href="/blog">Blog</a>
              <a href="/pricing">Pricing</a>
              {Utils.isAuthenticated() && (
                <div className="dropdown logindropdown">
                  <button className="dropbtn"></button>
                  <div className="dropdown-content">
                    <NavLink to={"/home?onsite=1"} activeClassName="try">
                      My Home
                    </NavLink>
                    <NavLink to="/account" activeClassName="try">
                      Account
                    </NavLink>
                    <NavLink to="/library" activeClassName="try">
                      My Library
                    </NavLink>
                    <NavLink to="/contact" activeClassName="try">
                      Suggest a Book
                    </NavLink>
                    <NavLink to="/refer-a-friend" activeClassName="try">
                      Refer a Friend
                    </NavLink>
                    <NavLink to="/ten-day-challenge" activeClassName="try">
                      Ten Day Challenge Results
                    </NavLink>
                    <NavLink to="/logout" activeClassName="try">
                      Logout
                    </NavLink>
                  </div>
                </div>
              )}
              {!isAuthenticated && (
                <a href="/login" className="login header_nav_login">
                  Login
                </a>
              )}
              {/*{!Utils.isAuthenticated() && <a href="/try" className="login">Start for free</a>}*/}
            </div>
            <a
              href="javascript:void(0);"
              className="icon"
              onClick={this.toggleMobile.bind(this)}
            >
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </a>
            <div className="clear"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
