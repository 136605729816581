import React, { Component } from "react";
import Spinner from "../../Spinner/Spinner";
import Axios from "../../../axios";
import { NavLink } from "react-router-dom";
import AdminNav from "../Navigation/AdminNavigation";
import Alert from "react-bootstrap/Alert";

class AdminInsightList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      loading: true,
      success: "",
      result: [],
    };
  }

  componentDidMount() {
    Axios.get("/api/admin/insight", {})
      .then((response) => {
        if (response.data.status === "ok") {
          this.setState({
            error: "",
            loading: false,
            result: response.data.result,
          });
        } else {
          this.setState(
            {
              error: response.data.error,
              loading: false,
              success: "",
            },
            () => {
              window.scrollTo(0, 0);
            }
          );
        }
      })
      .catch((err) => {
        console.dir(err);
        this.setState(
          {
            error: err,
            loading: false,
            success: "",
          },
          () => {
            window.scrollTo(0, 0);
          }
        );
      });
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }

    var error = "";
    if (this.state.error && this.state.error.length) {
      error = (
        <Alert key="warning-1" variant="warning">
          {this.state.error}
        </Alert>
      );
    }

    var success = "";
    if (this.state.success && this.state.success.length) {
      success = (
        <Alert key="success-1" variant="success">
          {this.state.success}
        </Alert>
      );
    }

    var insights = this.state.result.map((b) => {
      return (
        <tr>
          <td>{b.id}</td>
          <td>{b.title}</td>
          <td>{new Date(b.createDate).toLocaleString()}</td>
          <td>
            <NavLink to={`/admin/insight-edit/${b.id}`}>Edit</NavLink>
          </td>
        </tr>
      );
    });

    return (
      <div className="container">
        <div className="row">
          {error}
          {success}
          <AdminNav />
          <h1>Manage Insights</h1>
          <p>
            <NavLink to={`/admin/insight-add`}>Add Insight</NavLink>
          </p>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Title</th>
                <th scope="col">Date Added</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody>{insights}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default AdminInsightList;
