import React, {Component} from "react";
import Spinner from "../../Spinner/Spinner";
import AdminNav from "../Navigation/AdminNavigation";
import Alert from "react-bootstrap/Alert";
import axios from "../../../axios";
import Form from "../../../Form";

class AdminVisualPhoto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            loading: true,
            success: "",
            result: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {
        if (this.props.match && this.props.match.params.id) {
            this.fetch();
        }
    }

    fetch() {
        const id = this.props.match.params.id;
        axios
            .get("/api/admin/visuals/" + id)
            .then((response) => {
                if (response.data.status === "ok") {
                    this.setState({
                        result: response.data.result.visual,
                        loading: false
                    });
                } else {
                    this.setState({
                        error: response.data.error,
                        loading: false
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    error: "There was an error processing your request.",
                    loading: false
                });
            });
    }

    handleSubmit(values) {
        if (this.props.match && this.props.match.params && this.props.match.params.id > 0) {
            this.setState({
                loading: true
            }, () => {
                const formData = new FormData();
                formData.append("file", values.file.file);
                formData.append("fileName", values.file.fileName);
                const res = axios.post(
                    "/api/admin/visuals/photo/" + this.props.match.params.id,
                    formData
                ).then(res => {
                    if (res.data.status === "ok") {
                        this.setState({
                            success: "The photo was uploaded.",
                            error: "",
                            loading: false
                        });
                    } else {
                        this.setState({
                            success: "",
                            error: res.data.error,
                            loading: false
                        });
                    }
                }).catch(err => {
                    console.log(err);
                    this.setState({
                        success: "",
                        error: err,
                        loading: false
                    });
                });
            });
        }
    }

    render() {
        if (this.state.loading) {
            return (<Spinner/>);
        }

        var error = "";
        if (this.state.error && this.state.error.length) {
            error = (<Alert key="warning-1" variant="warning">{this.state.error}</Alert>);
        }

        var success = "";
        if (this.state.success && this.state.success.length) {
            success = (<Alert key="success-1" variant="success">{this.state.success}</Alert>);
        }
        const result = this.state.result;

        return <div className="container Inner">
            <div className="row">
                {error}
                {success}
                <AdminNav/>
                <h1>Post Photo</h1>
                <p>Book Id:{result.bookId}</p>
                <p>{result.title}</p>
                <p>{result.body}</p>
                <Form fields={[
                    {
                        id: "file",
                        label: "Photo",
                        type: "file",
                        inputType: "file"
                    }
                ]} submitHandler={this.handleSubmit} errorHandler={(error) => {
                    this.setState({
                        error
                    });
                }} submitButtonLabel="Submit"/>
            </div>
        </div>;
    }
}

export default AdminVisualPhoto;