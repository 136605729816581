import React, {Component} from 'react';
import "./Invitations.css"
import {NavLink} from "react-router-dom";
import money from "../../../assets/images/money.svg";
import value from "../../../assets/images/value.svg";
import heart from "../../../assets/images/heart.svg";

class AffiliateProgram extends Component {
    render() {
        return (
            <div className="Invitations">
                <div className="Invitations-hero">
                    <h1 className="Invitations-herotitle">Join the Sumizeit Affiliate Program</h1>
                    <h2 className="Invitations-herosubtitle">Share Sumizeit and earn money.</h2>
                    <div className="Invitations-img"></div>
                </div>
                <div className="Invitations-inner">
                    <p>Do you have a blog or app? Earn money by sharing Sumizeit with your users. Do you have friends who are interested in personal and professional development? Share Sumizeit with them. Whenever they purchase a Sumizeit account using a special link that we give you, you will earn commission. Your users or friends will love Sumizeit. We have a high rating on the App store. This is a good way to make your users happy and earn money.</p>
                    <h2 className="Invitations-whyjoinus">Why join the program?</h2>
                    <div className="Invitations-benefits">
                        <div className="Invitations-benefit">
                            <img src={money} />
                            <h3>Earn Money</h3>
                            <p>Earn 10% commission when your users buy a Sumizeit Premium account.</p>
                        </div>
                        <div className="Invitations-benefit">
                            <img src={value} />
                            <h3>Provide Value</h3>
                            <p>With a high user rating, Sumizeit is well liked in the industry. Make your users happy by giving them access to an excellent product.</p>
                        </div>
                        <div className="Invitations-benefit">
                            <img src={heart} />
                            <h3>Share the love!</h3>
                            <p>Love the product? Why not share the love and earn money while doing it!</p>
                        </div>
                    </div>
                </div>
                <div className="Invitations-ready">
                    <h2>Ready to share something great?</h2>
                    <p>Start earning money now.</p>
                    <NavLink to="/invite"><button className="StandardButton-6">Get Started</button></NavLink>
                </div>
            </div>
        );
    }
}

export default AffiliateProgram;
