import React, { Component } from 'react';
import axios from '../../../axios';
import BookList from "../BookList/BookList";
import "./BookSearch.css";
import MetaTags from "react-meta-tags";
import Spinner from "../../Spinner/Spinner"
import queryString from "query-string";

class BookSearch extends Component {

    state = {
        search: '',
        books: '',
        hasAudio: false,
        hasVideo: false
    };

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        if (values.search) {
            this.setState({
                search: values.search
            })
        }
        this.loadData()
    }

    loadData () {
        axios.get('/api/books/list')
            .then(response => {
                this.setState({books: response.data.books});
            })
            .catch(error => {
                this.setState({error: true});
            });
    }

    onSubmit (ev) {
        ev.preventDefault();
        const {search} = ev.target.elements;

        if (window.gtag) {
            window.gtag('event', 'book_search', {
                'event_label': search.value,
                'event_category': 'engagement',
                'non_interaction': true
            });
        }
        this.setState({search: search.value});
    }

    handleInputChange(event) {
        const target = event.target;
        var value = false;
        if (target.name === "hasAudio") {
            value = target.checked;
        }
        if (target.name === "hasVideo") {
            value = target.checked;
        }
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render () {
        var search = (
            <div className="BookSearch">
                <MetaTags>
                    <title>Search for nonfiction business and personal development book summaries | Sumizeit</title>
                    <meta name="description" content="Search for nonfiction book summaries" />
                </MetaTags>

                <form onSubmit={this.onSubmit.bind(this)}>
                <h1 className="MainTitle" align="center">Search</h1>
                <p align="center">Search our library to find compelling nonfiction book summaries.</p>
                <br />
                <br />
                <input type="text" placeholder="Search by book title, author" name="search" className="FullBookSearchInput StandardInput-1" />
                    <br />    <br />
                    <label>
                        <input
                            name="hasAudio"
                            type="checkbox"
                            onChange={this.handleInputChange.bind(this)} />&nbsp;Audio
                    </label>&nbsp;&nbsp;&nbsp;
                    <label>
                        <input
                            name="hasVideo"
                            type="checkbox"
                            onChange={this.handleInputChange.bind(this)} />&nbsp;Video
                    </label>
                <br />
                <br />
                <div align="center"><button name="submit" value="Search" className="StandardButton-2">Search</button></div>
                </form>
                <br />
                <br />
            </div>
        );
        var results = {};

        var numberOfResults = 0;
        if ( this.state.books && this.state.search) {
            var books = this.state.books;
            var keys = Object.keys(books);
            keys.forEach(key => {
                var ignore = false;
                var book = books[key];
                if (book["title"].toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ||
                    book["author"].toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ||
                    book["desc"].toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ) {
                    if (this.state.hasAudio && !book.hasMedia) {
                        ignore = true;
                    }
                    if (this.state.hasVideo && !book.hasVideo) {
                        ignore = true;
                    }
                    if (!ignore) {
                        results[book.id] = book;
                        numberOfResults += 1;
                    }
                }
            });
        } else if (!this.state.books) {
            return <Spinner />
        }


        return (
            <div className="FullBookSearch Main">
                {search}
                {numberOfResults > 0
                && <BookList books={results} />
                }
                {!numberOfResults && this.state.search
                && <p align="center">No results.</p>
                }
            </div>
        );
    }
}


export default BookSearch;
