import React, {Component} from "react";
import Spinner from "../../Spinner/Spinner";
import AdminNav from "../Navigation/AdminNavigation";
import Alert from "react-bootstrap/Alert";
import axios from "../../../axios";
import Form from "../../../Form";
import _ from "lodash";

class AdminVisualAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            loading: false,
            success: "",
            result: []
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values) {
        this.setState({
            loading: true
        }, () => {
            const output = _.cloneDeep(values);
            const res = axios.post(
                "/api/admin/visuals",
                output
            ).then(res => {
                if (res.data.status === "ok") {
                    this.setState({
                        success: "The visual was added.",
                        error: "",
                        loading: false
                    });
                } else {
                    this.setState({
                        success: "",
                        error: res.data.error,
                        loading: false
                    });
                }
            }).catch(err => {
                console.log(err);
                this.setState({
                    success: "",
                    error: err,
                    loading: false
                });
            });
        });
    }

    render() {
        if (this.state.loading) {
            return (<Spinner/>);
        }

        var error = "";
        if (this.state.error && this.state.error.length) {
            error = (<Alert key="warning-1" variant="warning">{this.state.error}</Alert>);
        }

        var success = "";
        if (this.state.success && this.state.success.length) {
            success = (<Alert key="success-1" variant="success">{this.state.success}</Alert>);
        }

        return <div className="container Inner">
            <div className="row">
                {error}
                {success}
                <AdminNav/>
                <h1>Add Visual</h1>
                <Form fields={[
                    {
                        id: "bookId",
                        label: "Book Id",
                        type: "text",
                        inputType: "text"
                    },
                    {
                        id: "title",
                        label: "Title",
                        type: "text",
                        inputType: "text"
                    },
                    {
                        id: "body",
                        label: "Body",
                        type: "textarea",
                        inputType: "textarea"
                    }
                ]} submitHandler={this.handleSubmit} errorHandler={(error) => {
                    this.setState({
                        error
                    });
                }} submitButtonLabel="Submit"/>
            </div>
        </div>;
    }
}

export default AdminVisualAdd;