import React, {Component} from "react";
import axios from "../../axios";

class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            complete: false,
            error: false
        };
    }

    submit(event) {
        event.preventDefault();

        const {feedback, email} = event.target.elements;

        if (feedback.value && feedback.value.length && email && email.value.length) {
            axios.post('/feedback/send', {
                feedback: feedback.value,
                email: email.value
            }).then(response => {
                if (response.data.error) {
                    this.setState({error: response.data.error});
                } else {
                    this.setState({complete: true});
                }
            }).catch(error => {
                this.setState({error: error});
            });
        } else {
            this.setState({error: "Please input the required fields."});
        }
    }

    render() {
        if (this.state.complete) { return <p className="NewComment alert alert-success">Your feedback was submitted.</p>; }
        if (this.state.error) { return <p className="NewComment alert error">{this.state.error}</p>; }

        return (
            <div className="Feedback NewComment">
                <form onSubmit={(ev) => this.submit(ev)}>
                    <h1>What do you think?</h1>
                    <p>Tell us about your experience with Sumizeit! You're helping us get better.</p>
                    <div className="row form-group">
                        <textarea
                            name="feedback"
                            ref="feedback"
                            placeholder="Your feedback"
                            className="form-control StandardTextarea-1 Comment-Input"
                            autoFocus={true}
                        />
                        <br/>
                        <input type="text" name="email" ref="email" placeholder="Your Email"
                               className="form-control Name-Input"/>
                        <br/>
                        <br/>
                        <button type="submit" className="StandardButton-2" id="submitBtn">Send Feedback</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default Feedback;