import React, {Component} from "react";
import "./Quiz.css";
import brain from "../../assets/images/brain.png";
import ProgressBar from 'react-bootstrap/ProgressBar';

class QuizResults extends Component {
    constructor(props) {
        super(props);

        this.state = {
            questions: [
                {
                    title: "I keep a personal journal",
                    types: ["Linguistic Intelligence"]
                },
                {
                    title: "I love solving puzzles and finding patterns.",
                    types: ["Logical-Mathematical Intelligence"]
                },
                {
                    title: "There’s always a logical explanation for everything.",
                    types: ["Logical-Mathematical Intelligence"]
                },
                {
                    title: "I can visualize the layout of a room before I walk into it, and I love creating art or design.",
                    types: ["Spatial Intelligence"]
                },
                {
                    title: "I can pick up on rhythms and melodies instantly, and I often think in terms of sounds.",
                    types: ["Musical Intelligence"]
                },
                {
                    title: "I understand things better when I can move or use my hands, whether it's dancing, building, or playing sports.",
                    types: ["Bodily-Kinesthetic Intelligence"]
                },
                {
                    title: "I spend a lot of time reflecting on my thoughts and emotions. Self-awareness helps me make better decisions.",
                    types: ["Intrapersonal Intelligence"]
                },
                {
                    title: "I often contemplate big questions about life, death, and the universe. I seek meaning in everything I do.",
                    types: ["Existential Intelligence"]
                },
                {
                    title: "I like to draw",
                    types: ["Spatial Intelligence"]
                },
                {
                    title: "I'm able to recognize and create musical pitches, tones, and rhythms",
                    types: ["Musical Intelligence"]
                },
                {
                    title: "I am a dancer",
                    types: ["Bodily-Kinesthetic Intelligence"]
                },
                {
                    title: "I am an effective communicator",
                    types: ["Interpersonal Intelligence"]
                },
                {
                    title: "I am an empathetic person",
                    types: ["Interpersonal Intelligence"]
                },
                {
                    title: "I understand my strengths and weaknesses very clearly.",
                    types: ["Intrapersonal Intelligence"]
                },
                {
                    title: "I care about the environment",
                    types: ["Naturalistic Intelligence"]
                },
                {
                    title: "The natural world is where I feel most at peace.",
                    types: ["Naturalistic Intelligence"]
                },
                {
                    title: "I am a critical thinker",
                    types: ["Existential Intelligence"]
                },
                {
                    title: "I like going to concerts",
                    types: ["Musical Intelligence"]
                }
            ],
            questionNo: 0,
            intelligenceTypes: {},
            userIntelligenceTypes: {}
        };
    }

    componentWillMount() {
        let intelligenceTypes = {};
        this.state.questions.forEach(q => {
            q.types.forEach(t => {
                if (!intelligenceTypes[t]) {
                    intelligenceTypes[t] = 1;
                } else {
                    intelligenceTypes[t] += 1;
                }
            });
        });

        let userIntelligenceTypes = {};
        try {
            userIntelligenceTypes = JSON.parse(window.sessionStorage.getItem("userIntelligenceTypes"));
        } catch {
            userIntelligenceTypes = {};
        }

        this.setState({
            intelligenceTypes,
            userIntelligenceTypes
        });
    }

    render() {
        const intelis = this.state.intelligenceTypes;
        return (
            <div className="QuizBody">
                <h1>Quiz Results - Your Intelligence Types</h1>
                <p>Different types of intelligence highlight the diverse ways people think, solve problems, and interact
                    with the world around them.</p>
                <div className="QuizImg"><img src={brain} alt = "sumizeit intelligence type quiz results" /></div>
                {Object.keys(this.state.userIntelligenceTypes).map((type) => {
                    const tnum = intelis[type];
                    const num = this.state.userIntelligenceTypes[type];
                    const percentage = ((num / tnum).toFixed(2)) * 100;
                    return (
                        <div>
                            <h6 className="QuizIntelligenceType">{type}</h6>
                            <p><ProgressBar now={percentage} label={`${percentage}%`}  /></p>
                        </div>);
                })}
            </div>
        );
    }
}

export default QuizResults;