import React, { Component } from "react";
import {
  injectStripe,
  CardNumberElement,
  CardCVCElement,
  CardExpiryElement,
} from "react-stripe-elements";
import "./Payment.css";
import axios from "../../axios";
import queryString from "query-string";
import LazyLoad from "react-lazyload";
import appstore from "../../assets/images/appstore.svg";
import creditcard from "../../assets/images/creditcard.png";
import paypal from "../../assets/images/paypal.svg";
import { PayPalButton } from "react-paypal-button-v2";
import Spinner from "../Spinner/Spinner";

const INFOGRAPHICS_PRICE_20 = 1099;
const INFOGRAPHICS_PRICE_50 = 2999;
const CORPORATE_PLAN_ID_1 = "gnhwupl";
const CORPORATE_PLAN_ID_2 = "dkosskq";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      invoiceId: "",
      loading: false,
      infographicsUrl: null,
      addInfographics: false,
      coupon: "",
      showCouponBox: false,
      price: 0,
      percentOff: 0,
      addedAddOn: false,
      quantity: 1,
      cost: 0,
      originalPrice: 0,
      appliedCoupon: false,
      paypalPlan: "",
      course: {},
      recipient_name: "",
      bookTitle: "",
      recipient_email: "",
      recipient_msg: "",
      fileDownloadUrl: null,
    };
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    let coupon = "";
    const values = queryString.parse(window.location.search);

    if (values.coupon) {
      coupon = values.coupon;
      this.getPriceFromCoupon(coupon);
    } else {
      this.getPriceFromCoupon(null);
    }
  }

  handlePaypalApprove(orderID, subscriptionID) {
    const values = queryString.parse(window.location.search);

    var gift = false;
    if (values.gift) {
      gift = true;
    }

    let rId;
    if (window.localStorage) {
      rId = window.localStorage.getItem("rId");
    }

    axios
      .post("/api/paypal", {
        orderId: orderID,
        senderName: this.state.name,
        recipientName: this.state.recipient_name,
        recipientEmail: this.state.recipient_email,
        recipientMsg: this.state.recipient_msg,
        subscriptionId: subscriptionID,
        gift,
        rId,
        quantity: this.state.quantity,
        planId: this.props.planId,
      })
      .then((response) => {
        if (response.data.status === "ok") {
          if (window.gtag) {
            window.gtag("event", "subscribed", {
              event_label: this.props.planId,
              event_category: "purchase",
              value: (this.state.cost / 100).toFixed(2),
            });
          }

          if (window.gtag && window.localStorage) {
            const hkossjdd = window.localStorage.getItem("hkossjdd");

            if (hkossjdd && hkossjdd.length) {
              window.gtag("event", "subscribed_exp", {
                event_label: hkossjdd,
                event_category: "purchase_exp",
                value: (this.state.cost / 100).toFixed(2),
              });
            }
          }

          if (window.fbq) {
            window.fbq("track", "Subscribe", {
              value: (this.state.cost / 100).toFixed(2),
              currency: "USD",
            });
          }

          this.setState(
            {
              complete: true,
              infographicsUrl: response.data.infographicsUrl,
              loading: !!response.data.buffer,
            },
            () => {
              if (response.data.buffer) {
                this.handleBufferSummary(response.data.buffer);
              }
            }
          );
        } else if (response.data.error) {
          window.scrollTo(0, 0);
          this.setState({ error: response.data.error, loading: false });
        }
      })
      .catch((error) => {
        window.scrollTo(0, 0);
        this.setState({ error: error.error, loading: false });
      });
  }

  submit(event) {
    try {
      event.preventDefault();

      let rId;
      if (window.localStorage) {
        rId = window.localStorage.getItem("rId");
      }

      var planId = this.props.planId;
      var submitBtn$ = document.getElementById("submitBtn");

      if (planId) {
        if (planId === CORPORATE_PLAN_ID_2 && this.state.quantity < 20) {
          alert("Please choose a quantity >= 20");
          return;
        }
        if (submitBtn$) {
          submitBtn$.innerText = "Loading...";
          submitBtn$.disabled = true;
        }

        var cost = (this.state.cost / 100).toFixed(2);

        this.props.stripe.createToken({ type: "card" }).then((token) => {
          if (!token || token.error) {
            if (submitBtn$) {
              submitBtn$.innerText = "Complete Purchase";
              submitBtn$.disabled = false;
            }
            this.setState({ error: token.error.message, loading: false });
          } else {
            const values = queryString.parse(window.location.search);

            var gift = false;
            if (values.gift) {
              gift = true;
            }

            var token_id = token.token.id;
            axios
              .post("/api/charge/process/" + planId, {
                token: token_id,
                planId: planId,
                addInfographics: this.state.addInfographics,
                couponCode: this.state.coupon,
                gift,
                rId,
                quantity: this.state.quantity,
                senderName: this.state.name,
                recipientName: this.state.recipient_name,
                recipientEmail: this.state.recipient_email,
                recipientMsg: this.state.recipient_msg,
              })
              .then((response) => {
                if (response.data.status === "ok") {
                  if (submitBtn$) {
                    submitBtn$.innerText = "Complete Purchase";
                    submitBtn$.disabled = false;
                  }
                  if (window.gtag) {
                    window.gtag("event", "subscribed", {
                      event_label: planId,
                      event_category: "purchase",
                      value: cost,
                    });
                  }
                  if (window.gtag && window.localStorage) {
                    const hkossjdd = window.localStorage.getItem("hkossjdd");

                    if (hkossjdd && hkossjdd.length) {
                      window.gtag("event", "subscribed_exp", {
                        event_label: hkossjdd,
                        event_category: "purchase_exp",
                        value: cost,
                      });
                    }
                  }

                  if (window.fbq) {
                    window.fbq("track", "Subscribe", {
                      value: cost,
                      currency: "USD",
                    });
                  }
                  this.setState(
                    {
                      complete: true,
                      loading: !!response.data.buffer,
                      infographicsUrl: response.data.infographicsUrl,
                    },
                    () => {
                      if (response.data.buffer) {
                        this.handleBufferSummary(response.data.buffer);
                      }
                    }
                  );
                } else if (response.data.error) {
                  window.scrollTo(0, 0);

                  if (submitBtn$) {
                    submitBtn$.innerText = "Complete Purchase";
                    submitBtn$.disabled = false;
                  }
                  this.setState({ error: response.data.error, loading: false });
                }
              })
              .catch((error) => {
                window.scrollTo(0, 0);
                if (submitBtn$) {
                  submitBtn$.innerText = "Complete Purchase";
                  submitBtn$.disabled = false;
                }
                this.setState({ error: error.error, loading: false });
              });
          }
        });
      } else {
        window.scrollTo(0, 0);
        if (submitBtn$) {
          submitBtn$.innerText = "Complete Purchase";
          submitBtn$.disabled = false;
        }
        this.setState({ error: "Invalid plan id", loading: false });
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleBufferSummary(buffero) {
    // Create a buffer (example only, you would replace this with your actual buffer)
    const buffer = new Buffer(buffero);

    // Convert the buffer to a file
    const file = new File([buffer], "summary.pdf", { type: "application/pdf" });

    // Download the file
    const url = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    this.setState({
      fileDownloadUrl: url,
      loading: false,
    });
  }

  getPriceFromCoupon(coupon) {
    this.setState({ loading: true }, () => {
      axios
        .post("/api/charge/price", {
          planId: this.props.planId,
        })
        .then((priceResponse) => {
          if (priceResponse.error || priceResponse.data.error) {
            if (priceResponse.data.notLoggedIn) {
              window.location.href =
                "/login?redirect=/payment/" + this.props.planId;
            } else {
              this.setState({
                error: priceResponse.error || priceResponse.data.error,
                loading: false,
                appliedCoupon: false,
              });
            }
          } else {
            if (coupon) {
              axios
                .post("/api/charge/coupon", {
                  coupon: coupon,
                  planId: this.props.planId,
                })
                .then((couponResponse) => {
                  if (!couponResponse.error && !couponResponse.data.error) {
                    let cost =
                      this.state.cost || priceResponse.data.result.price;
                    cost =
                      cost * (1 - couponResponse.data.result.percent_off / 100);
                    this.setState({
                      price: priceResponse.data.result.price,
                      cost,
                      percentOff: couponResponse.data.result.percent_off,
                      coupon: coupon,
                      loading: false,
                      error: false,
                      appliedCoupon: true,
                      bookTitle: priceResponse.data.result.bookTitle,
                      originalPrice: priceResponse.data.result.price,
                      course: priceResponse.data.result.course || {},
                      paypalPlan: couponResponse.data.result.paypalPlan,
                    });
                  } else if (couponResponse.data.error) {
                    this.setState({
                      appliedCoupon: false,
                      coupon: null,
                      error: couponResponse.data.error,
                      loading: false,
                    });
                  }
                })
                .catch((error) => {
                  this.setState({
                    appliedCoupon: false,
                    coupon: null,
                    error: error,
                    loading: false,
                  });
                });
            } else {
              let cost = this.state.cost || priceResponse.data.result.price;
              this.setState({
                price: priceResponse.data.result.price,
                cost,
                originalPrice: priceResponse.data.result.price,
                coupon: null,
                bookTitle: priceResponse.data.result.bookTitle,
                appliedCoupon: false,
                error: false,
                loading: false,
                course: priceResponse.data.result.course || {},
                paypalPlan: priceResponse.data.result.paypalPlan,
              });
            }
          }
        })
        .catch((error) => {
          this.setState({ appliedCoupon: false, error: error, loading: false });
        });
    });
  }

  handleAddOns(add) {
    let cost;
    let addedAddOn = false;
    if (add === 20) {
      cost = this.state.price + INFOGRAPHICS_PRICE_20;
      cost = cost * (1 - this.state.percentOff / 100);
      addedAddOn = "20_infographics";
    } else if (add === 50) {
      cost = this.state.price + INFOGRAPHICS_PRICE_50;
      cost = cost * (1 - this.state.percentOff / 100);
      addedAddOn = "50_infographics";
    } else {
      cost = this.state.price;
      cost = cost * (1 - this.state.percentOff / 100);
      addedAddOn = false;
    }
    this.setState({
      cost,
      addedAddOn
    });
  }

  handleCouponChange(ev) {
    ev.preventDefault();
    this.setState({ coupon: ev.target.value });
  }

  handleRadioChange(e) {
    var value = e.target.value;

    if (value === "credit_card") {
      this.setState({ paymentMethodToShow: "credit_card" });
    } else if (value === "paypal") {
      this.setState({ paymentMethodToShow: "paypal" });
    }
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }

    var planId = this.props.planId;

    var isGift = planId.toLowerCase().indexOf("gift") !== -1;
    var isCorporateSolution =
      planId.toLowerCase().indexOf(CORPORATE_PLAN_ID_1) !== -1 ||
      planId.toLowerCase().indexOf(CORPORATE_PLAN_ID_2) !== -1;
    var isQuiz = planId.toLowerCase().indexOf("quiz") !== -1;
    var isPersonalityQuiz = planId.toLowerCase().indexOf("personality-quiz") !== -1;
    var isCoursePurchase =
      planId.toLowerCase().indexOf("monthly") === -1 &&
      planId.toLowerCase().indexOf("yearly") === -1 &&
      planId.toLowerCase().indexOf("gift") === -1 &&
        planId.toLowerCase().indexOf("lifetime") === -1 &&
        planId.toLowerCase().indexOf("quiz") === -1 &&
      planId.toLowerCase().indexOf("infographics") === -1 &&
      !this.state.bookTitle &&
      !isCorporateSolution;
    var isInfographics = planId.toLowerCase().indexOf("infographics") !== -1;

    if (this.state.complete) {
      if (isInfographics) {
        return (
            <div align="center" className="Main AlertSuccess">
              Thanks for purchasing the infographics! You can download them{" "}
              <a href={this.state.infographicsUrl}>here</a>.
            </div>
        );
      } else if (isPersonalityQuiz) {
        return (
            <div align="center" className="Main AlertSuccess">
              Thanks for purchasing! Click <a href = "/personality-quiz-results">here</a> to see your quiz results.
            </div>
        );
      } else if (isQuiz) {
        return (
            <div align="center" className="Main AlertSuccess">
              Thanks for purchasing! Click <a href = "/quiz-results">here</a> to see your quiz results.
            </div>
        );
      } else if (isGift) {
        return (
          <div align="center" className="Main AlertSuccess">
            Thanks for purchasing a gift. Your gift details have been emailed to you.
          </div>
        );
      } else if (this.state.fileDownloadUrl) {
        return (
          <div align="center" className="Main AlertSuccess">
            <p>
              Thanks for purchasing the book summary! The summary was downloaded
              to your computer. If you have any questions, please contact
              info@sumizeit.com.
            </p>
            {this.state.infographicsUrl && (
              <>
                {" "}
                <br />
                <p>
                  Thanks for purchasing the infographics! You can download them{" "}
                  <a href={this.state.infographicsUrl}>here</a>.
                </p>
              </>
            )}
          </div>
        );
      } else {
        return (
          <div align="center" className="Main AlertSuccess">
            <p>
              Thanks for purchasing! You can now access the{" "}
              <a href="/books">book summaries</a>.
            </p>{" "}
            <br />
            {!this.state.infographicsUrl && (
              <p>
                New Feature! Gain more knowledge in less time with beautiful,
                rich <a href="/infographics">infographics.</a>
              </p>
            )}
            {this.state.infographicsUrl && (
              <div align="center">
                Thanks for purchasing the infographics! You can download them{" "}
                <a href={this.state.infographicsUrl}>here</a>.
              </div>
            )}
            <br />
            <br />
            <p>You can also download our mobile app below.</p>
            <br />
            <p>
              <a
                href="https://apps.apple.com/us/app/sumizeit/id1327646115"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyLoad>
                  <img src={appstore} alt="sumizeit app store page" />
                </LazyLoad>
              </a>
            </p>
          </div>
        );
      }
    }

    var error = "";
    if (this.state.error) {
      error = (
        <p className="alert alert-danger">
          {this.state.error}
          <br />
        </p>
      );
    }

    var isOneTime = false;
    var paypalplanId = this.state.paypalPlan;
    if (
      planId.toLowerCase().indexOf("lifetime") !== -1 ||
      planId.toLowerCase().indexOf("onetime") !== -1 ||
        planId.toLowerCase().indexOf("infographic") !== -1 ||
        planId.toLowerCase().indexOf("quiz") !== -1 ||
      isCoursePurchase ||
      isGift ||
      isInfographics ||
      isCorporateSolution ||
      (this.state.bookTitle && this.state.bookTitle.length)
    ) {
      isOneTime = true;
    }
    const isCorporateSolutionOrGift = isCorporateSolution || isGift;

    var product = "";
    var isSubscription = false;
    var showAddons = false;

    if (planId.toLowerCase().indexOf("yearlypdf") !== -1) {
      product = "Sumizeit Yearly Subscription w/ PDF";
      isSubscription = true;
      showAddons = true;
    } else if (planId.toLowerCase().indexOf("yearly") !== -1) {
      product = "Sumizeit Yearly Subscription";
      isSubscription = true;
      showAddons = true;
    } else if (planId.toLowerCase().indexOf("monthly") !== -1) {
      product = "Sumizeit Monthly Subscription";
      isSubscription = true;
      showAddons = true;
    } else if (planId.toLowerCase().indexOf("quiz") !== -1) {
      product = "Quiz Results";
      isSubscription = false;
      showAddons = true;
    } else if (isCorporateSolution) {
      product = "Sumizeit Yearly Subscription";
    } else if (planId.toLowerCase().indexOf("infographics") !== -1) {
      product = "Infographics";
    } else if (planId.toLowerCase().indexOf("lifetime") !== -1) {
      product = "Sumizeit Lifetime Membership";
      isSubscription = true;
      showAddons = true;
    } else if (planId.toLowerCase().indexOf("infographic") !== -1) {
      product = this.state.bookTitle + " Infographic";
      isSubscription = false;
      showAddons = false;
    } else {
      product = this.state.bookTitle;
      showAddons = true;
    }

    if (isGift) {
      showAddons = false;
    }

    var numbers = [];
    for (var i = 1; i < 201; i += 1) {
      numbers.push(i);
    }

    var quantityOpts = numbers.map((item) => {
      if (item === this.state.quantity) {
        return (
          <option selected value={item}>
            {item}
          </option>
        );
      } else {
        return <option value={item}>{item}</option>;
      }
    });

    return (
      <div className="CheckoutForm">
        {error}
        {this.state.appliedCoupon && (
          <div>
            <div className="Checkout_CouponSuccess" align="center">
              Congrats! We've applied a coupon to your order.
            </div>
            <br />
            <br />
          </div>
        )}
        {isGift && (
            <div>
              <div className="Checkout_CouponSuccess Checkout_Gift" align="center">
                You're purchasing a gift. Once we receive your payment, we will send your gift details by email to send to your friends.
              </div>
              <br />
              <br />
            </div>
        )}
        <div className="CheckoutForm-left">
          <h3 className="CheckoutForm-shoppingcarthdr">Shopping Cart</h3>
          {!isCoursePurchase && (
            <p className="CheckoutForm-producttitle">{product}</p>
          )}
          <div className="CheckoutForm-price">
            ${(this.state.originalPrice / 100).toFixed(2)}
            {isCorporateSolutionOrGift && (
              <div>
                Quantity:&nbsp;&nbsp;
                <select
                  name="quantity"
                  className="CheckoutForm-quantity"
                  onChange={(e) => {
                    this.setState({
                      quantity: e.target.value,
                      price: e.target.value * this.state.originalPrice,
                      cost: e.target.value * this.state.originalPrice,
                    });
                  }}
                >
                  {quantityOpts}
                </select>
              </div>
            )}
          </div>

          {this.state.addInfographics === 20 && (
              <>
                <p className="CheckoutForm-producttitle">Infographics</p>
                <div className="CheckoutForm-price">$10.99</div>
              </>
          )}
          {this.state.addInfographics === 50 && (
              <>
              <p className="CheckoutForm-producttitle">Infographics</p>
                <div className="CheckoutForm-price">$29.99</div>
              </>
          )}
          {!isCoursePurchase && (
            <div className="CheckoutForm-couponbox">
              <div
                className="CheckoutForm-haveavoucher"
                onClick={() => {
                  this.setState({
                    showCouponBox: !this.state.showCouponBox,
                  });
                }}
              >
                Have a Promo Code?
              </div>
              {this.state.showCouponBox && (
                <div className="CheckoutForm-couponcodebox">
                  <input
                    type="text"
                    name="coupon_code"
                    placeholder="Enter Coupon Code"
                    value={this.state.coupon || ""}
                    className="form-control coupon-code"
                    onChange={this.handleCouponChange.bind(this)}
                  />
                  <button
                    onClick={() => {
                      this.getPriceFromCoupon(this.state.coupon);
                    }}
                    className="StandardButton-1"
                    style={{ display: "inline-block" }}
                  >
                    Apply
                  </button>
                  {this.state.appliedCoupon && (
                    <div className="CheckoutForm-couponapplied">
                      Coupon was applied.
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <div className="CheckoutForm-subtotal">
            <div className="CheckoutForm-subtotalhdr">Subtotal</div>
            <div className="CheckoutForm-subtotalamt">
              ${(this.state.cost / 100).toFixed(2)}
            </div>
          </div>
          <div className="CheckoutForm-total">
            <div className="CheckoutForm-totalhdr">Total</div>
            <div className="CheckoutForm-totalamt">
              ${(this.state.cost / 100).toFixed(2)}
            </div>
          </div>
        </div>
        <div className="CheckoutForm-right">
          {(isCorporateSolution && (
              <div>
                <div className="CheckoutForm-paymentgroup">
                  <h6 className="CheckoutForm-paymenttype">
                    Recipient Details
                  </h6>
                  <br />
                  <br />
                  <div>
                    <p>
                      Please keep recipient name and email blank if you want to
                      send the gift details to yourself. If not, we will send an
                      email to the recipient.
                    </p>
                    <br />
                    <br />
                    <input
                      type="text"
                      name="sender-name"
                      placeholder="Sender Name"
                      value={this.state.name}
                      className="form-control"
                      onChange={(e) => {
                        this.setState({
                          name: e.target.value,
                        });
                      }}
                    />
                    <br />
                    <input
                      type="text"
                      name="recipient-name"
                      placeholder="Recipient Name"
                      value={this.state.recipient_name}
                      className="form-control"
                      onChange={(e) => {
                        this.setState({
                          recipient_name: e.target.value,
                        });
                      }}
                    />
                    <br />
                    <input
                      type="text"
                      name="recipient-email"
                      placeholder="Recipient Email"
                      value={this.state.recipient_email}
                      className="form-control"
                      onChange={(e) => {
                        this.setState({
                          recipient_email: e.target.value,
                        });
                      }}
                    />
                    <br />
                    <textarea
                      name="recipient-msg"
                      placeholder="Add a personal message"
                      className="form-control CheckoutForm-recipientpersonalmsg"
                      onChange={(e) => {
                        this.setState({
                          recipient_msg: e.target.value,
                        });
                      }}
                    >
                      {this.state.recipient_msg}
                    </textarea>
                  </div>
                  <br />
                </div>
                <br />
              </div>
            ))}

          {showAddons && (
              <div className="CheckoutForm_addons">
                <h4>Upgrade your order with extras</h4>
                <div>
                  <input
                    type="radio"
                    name="infographics_addone"
                    checked={!this.state.addInfographics}
                    onChange={() => {
                      this.setState(
                          {
                            addInfographics: false
                          },
                          () => {
                            this.handleAddOns(this.state.addInfographics);
                          }
                      );
                    }}
                  />
                  &nbsp;&nbsp;None
                </div>
                <div>
                  <input
                    type="radio"
                    name="infographics_addone"
                    value={20}
                    checked={this.state.addInfographics === 20}
                    onChange={() => {
                      this.setState(
                          {
                            addInfographics: 20
                          },
                          () => {
                            this.handleAddOns(this.state.addInfographics);
                          }
                      );
                    }}
                />
                  &nbsp;&nbsp;20 Infographics $10.99
                </div>
                <div>
                  <input
                    type="radio"
                    name="infographics_addone"
                    checked={this.state.addInfographics === 50}
                    value={50}
                    onChange={() => {
                      this.setState(
                          {
                            addInfographics: 50
                          },
                          () => {
                            this.handleAddOns(this.state.addInfographics);
                          }
                      );
                    }}
                />
                  &nbsp;&nbsp;50 Infographics $29.99
                </div>
              </div>
          )}
          <h2>Please select a payment method:</h2>

          <div
              className="CheckoutForm-paymentgroup radio-group paypal-group"
              onClick={() => {
                this.setState({paymentMethodToShow: "paypal"});
              }}
          >
            <input
                type="radio"
                onChange={this.handleRadioChange.bind(this)}
                name="paymentMethods"
                value="paypal"
            />
            &nbsp;&nbsp;
            <img src={paypal}/>
            {this.state.paymentMethodToShow === "paypal" && (
                <div>
                <br />
                <br />
                {isOneTime && (
                  <PayPalButton
                    amount={(this.state.cost / 100).toFixed(2)}
                    // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                    onSuccess={(details, data) => {
                      this.handlePaypalApprove(
                        data.orderID,
                        data.subscriptionID
                      );
                    }}
                  />
                )}
                {!isOneTime && (
                  <PayPalButton
                    options={{ vault: true }}
                    createSubscription={(data, actions) => {
                      return actions.subscription.create({
                        plan_id: paypalplanId,
                      });
                    }}
                    onApprove={(data, actions) => {
                      // Capture the funds from the transaction
                      return actions.subscription.get().then((details) => {
                        // OPTIONAL: Call your server to save the subscription
                        this.handlePaypalApprove(
                          data.orderID,
                          data.subscriptionID
                        );
                      });
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <br />

          <div
            className="CheckoutForm-paymentgroup radio-group"
            onClick={() => {
              this.setState({ paymentMethodToShow: "credit_card" });
            }}
          >
            <input
              type="radio"
              name="paymentMethods"
              onChange={this.handleRadioChange.bind(this)}
              value="credit_card"
            />
            <div className="CheckoutForm-paymenttype">
              &nbsp;&nbsp;Credit & Debit Cards
            </div>
            <img
              src={creditcard}
              style={{ width: "200px", height: "auto" }}
              alt="pay using credit card"
            />
            <p className="CheckoutForm-safemoneytransfer">
              Safe money transfer using your bank account. Visa, MasterCard,
              Discover, American Express.
            </p>
            <br />
            {this.state.paymentMethodToShow === "credit_card" && (
              <div>
                <div className="row StripeElementWrapper">
                  <h6>Card Number</h6>
                  <div className="stripe-card StripeElement StripeElement--empty">
                    <CardNumberElement />
                  </div>
                </div>
                <div className="row StripeElementWrapper">
                  <h6>Expiration Date</h6>
                  <div className="stripe-card StripeElement StripeElement--empty">
                    <CardExpiryElement />
                  </div>
                </div>
                <div className="row StripeElementWrapper">
                  <h6>Security Code</h6>
                  <div className="stripe-card StripeElement StripeElement--empty">
                    <CardCVCElement />
                  </div>
                </div>
                <br />
                <br />
                <p align="center" className="CheckoutForm-poweredbystripe">
                  Powered by Stripe
                </p>
              </div>
            )}
          </div>
          {this.state.paymentMethodToShow === "credit_card" && (
            <div className="CheckoutForm-buynowwrapper">
              <button
                id="submitBtn"
                className="CheckoutForm-buynowbtn"
                onClick={this.submit.bind(this)}
              >
                Complete Purchase
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
