import React, {Component} from 'react'
import './Sidebar.css'

export default class Sidebar extends Component {

    renderLinks = () => {
        return <div>
            <a href="javascript:void(0)" className="closebtn" onClick={this.closeNav.bind(this)}></a>
            {this.props.children}
        </div>
    };

    closeNav() {
        this.props.handleClose();
    }

    render() {
        var left;
        if (!this.props.isOpen) {
            left = "-480px";
        } else {
            left = "0";
        }
        return (<div className={`sidebar ${this.props.className}`} style={{left: left}}>
                {this.renderLinks()}
            </div>
        );
    }
};