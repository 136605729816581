import React, { Component } from 'react';
import './Quiz.css';
import brain from "../../assets/images/brain.png";

class Quiz extends Component {
    constructor(props) {
        super(props);

        this.state = {
            questions: [
                {
                    title: "I keep a personal journal",
                    types: ["Linguistic Intelligence"]
                },
                {
                    title: "I love solving puzzles and finding patterns.",
                    types: ["Logical-Mathematical Intelligence"]
                },
                {
                    title: "There’s always a logical explanation for everything.",
                    types: ["Logical-Mathematical Intelligence"]
                },
                {
                    title: "I can visualize the layout of a room before I walk into it, and I love creating art or design.",
                    types: ["Spatial Intelligence"]
                },
                {
                    title: "I can pick up on rhythms and melodies instantly, and I often think in terms of sounds.",
                    types: ["Musical Intelligence"]
                },
                {
                    title: "I understand things better when I can move or use my hands, whether it's dancing, building, or playing sports.",
                    types: ["Bodily-Kinesthetic Intelligence"]
                },
                {
                    title: "I spend a lot of time reflecting on my thoughts and emotions. Self-awareness helps me make better decisions.",
                    types: ["Intrapersonal Intelligence"]
                },
                {
                    title: "I often contemplate big questions about life, death, and the universe. I seek meaning in everything I do.",
                    types: ["Existential Intelligence"]
                },
                {
                    title: "I like to draw",
                    types: ["Spatial Intelligence"]
                },
                {
                    title: "I'm able to recognize and create musical pitches, tones, and rhythms",
                    types: ["Musical Intelligence"]
                },
                {
                    title: "I am a dancer",
                    types: ["Bodily-Kinesthetic Intelligence"]
                },
                {
                    title: "I am an effective communicator",
                    types: ["Interpersonal Intelligence"]
                },
                {
                    title: "I am an empathetic person",
                    types: ["Interpersonal Intelligence"]
                },
                {
                    title: "I understand my strengths and weaknesses very clearly.",
                    types: ["Intrapersonal Intelligence"]
                },
                {
                    title: "I care about the environment",
                    types: ["Naturalistic Intelligence"]
                },
                {
                    title: "The natural world is where I feel most at peace.",
                    types: ["Naturalistic Intelligence"]
                },
                {
                    title: "I am a critical thinker",
                    types: ["Existential Intelligence"]
                },
                {
                    title: "I like going to concerts",
                    types: ["Musical Intelligence"]
                }
            ],
            questionNo: 0,
            userIntelligenceTypes: {}
        };
    }

    handleFalse() {
        this.setState({
            questionNo: this.state.questionNo + 1
        })
    }

    handleTrue() {
        let userIntelligenceTypes = this.state.userIntelligenceTypes;

        if ((this.state.questionNo + 1) < this.state.questions.length) {
            const question = this.state.questions[this.state.questionNo];

            question.types.forEach(t => {
                if (!userIntelligenceTypes[t]) {
                    userIntelligenceTypes[t] = 1;
                } else {
                    userIntelligenceTypes[t] += 1;
                }
            });
        } else {
            window.sessionStorage.setItem("userIntelligenceTypes", JSON.stringify(this.state.userIntelligenceTypes));
        }
        this.setState({
            questionNo: this.state.questionNo + 1,
            userIntelligenceTypes
        })
    }

    render () {
        if (this.state.questionNo >= this.state.questions.length) {
            return (
                <div className = "QuizBody">
                    <h1>Congrats! You're Done!</h1>
                    <p>Discover your intelligence type, discover your strengths, and grow faster!</p>
                    <a href = "/payment/quiz"><button className="QuizDoneBtn">View Results</button></a>
                </div>
            );
        }
        const question = this.state.questions[this.state.questionNo];

        return (
            <div className="QuizBody">
                <div className="QuizImg"><img src={brain} alt="sumizeit intelligence type quiz"/></div>
                <h1>Question {this.state.questionNo + 1}/{this.state.questions.length}</h1>
                <p className="QuizAnswerQues">Answer questions to find your intelligence type, discover your strengths, and grow faster!</p>
                <h3 className="QuizBodyTitle">{question.title}</h3>
                <div className="QuizBtns">
                    <button className="QuizFalseBtn" onClick={this.handleFalse.bind(this)}>False</button>
                    <button className="QuizTrueBtn" onClick={this.handleTrue.bind(this)}>True</button>
                </div>
            </div>
        )
    }
}

export default Quiz;