import React, {Component} from "react";
import axios from "../../../axios";
import "./Reader.css"
import Spinner from "../../Spinner/Spinner"
import Audio from "../Audio/Audio";
import CategoryLink from "../BookDetail/CategoryLink";


class Reader extends Component {

    state = {
        book: null,
        complete: false
    };

    componentWillMount() {
        if (this.props.match.params.id) {

            if (window.gtag) {
                window.gtag('event', 'UserRead', {
                    'event_label': this.props.match.params.id,
                    'event_category': 'UserRead',
                    'non_interaction': true
                });
            }
            axios.get('/api/books/reader/' + this.props.match.params.id).then(response => {
                let book = response.data;
                this.setState({book: book, complete: true});
            }).catch(error => {
                this.setState({error: true});
            });
        }
    }

    render() {
        if (!this.state.complete) {
            return <Spinner/>;
        }
        var categories = this.state.book.categories.map((val, index) => {
            return <CategoryLink name={val} />;
        });
        var back = <a href={`/books/${this.state.book.id}`} className="ReaderLink">Back to {`${this.state.book.title}`}</a>
        if (this.state.book) {
            return (<div className="Reader Main">
                <p align="center">
                {back}
                </p>
                <br />
                {this.state.book.lastFree
                && <div className="lastFree">
                    <h2>You read a lot. We like that</h2>
                    <p>You’ve reached the end of your free member preview for this month. Become a member now to read this summary and get unlimited access to all of the summaries on Sumizeit.</p>
                    <br />
                    <a href="/try"><button className="StandardButton-4">Upgrade Now</button>
                </a>
                </div>
                }

                {this.state.book.mediaUrl && this.state.book.showPremiumContent
                &&
                <div align="center"><br/><Audio book={this.state.book}/><br/></div>
                }
                <div className="container">
                    <div className="article" dangerouslySetInnerHTML={{__html: this.state.book.body}}></div>
                </div>

                {!this.state.book.showPremiumContent
                && <div className="paywall">
                    <h1>Get the best of Sumizeit</h1>
                    <br />
                    Please upgrade to see the full content for this summary. <br /><br />You look like someone who appreciates a good story. Try a Sumizeit Premium Subscription and get unlimited access to our large library of text and audio summaries.<br /><br /><a href="/try">
                    <button className="StandardButton-4">Upgrade Now</button>
                </a></div>
                }
                <br />
                <div align="center">{categories}</div>
                <br />
                <p align="center">
                    {back}
                </p>
                <br />
                <br />
            </div>)
        }
        return "";
    }
}


export default Reader;
