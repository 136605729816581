import React, {Component} from 'react';
import axios from '../../axios';
import '../Home/Home.css'
import _ from 'lodash'
import Spinner from '../Spinner/Spinner'
import Color from "color-thief-react";

class Library extends Component {

    state = {
        loading: true,
        books: [],
        user: {},
        limit: 20
    };

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        axios.get('/api/library').then(response => {
            var r = response.data;

            if (r.error) {
                this.setState({
                    error: r.error,
                    loading: false
                });
            } else if (r.status === "ok") {
                this.setState({
                    loading: false,
                    user: r.result.user,
                    books: r.result.books
                });
            }
        }).catch(error => {
            this.setState({
                error: error.message,
                loading: false
            });
        });
    }


    onLoadMore() {
        this.setState({
            limit: this.state.limit + 20
        });
    }

    renderItem (section, item) {
        return <Color src={item.image} crossOrigin="anonymous" format="hex">
            {({data, loading}) => {
                if (loading) return <Spinner/>;
                return (
                    <div key={`${section}-${item.id}`} className="Home-item">
                        <div style={{backgroundColor: data}} className="Home-bookitem-wrapper">
                            <a href={`/books/${item.subid}`}>
                                <button style={{
                                    backgroundImage: "url('" + item.image + "')"
                                }} className="Home-bookitem"></button>
                            </a>
                        </div>
                        <a href={`/books/${item.subid}`} className="Home-itemtitle">{item.title}</a>
                        <div className="Home-itemauthor">{item.author}</div>
                    </div>);
            }}
        </Color>
    }

    showHistory() {
        this.state({
            showOnlyHistory: !this.state.showOnlyHistory
        })
    }

    render() {
        if (this.state.loading) {
            return <Spinner/>
        }

        var mappedBooks = [];
        var books = this.state.books;

        books = books.slice(0, 6);

        if (books && books.length) {
            mappedBooks = books.map(book => {
                return this.renderItem("new", book);
            });
        }


        return (
            <div className="Home Main">
                <h1 className="Home-title">Your Library</h1>

                {mappedBooks && <div className="Home-box Home-boxnew">
                    <div className="Home-boxtop">
                        <h2>Books</h2>
                        <div className="clear"></div>
                        {mappedBooks.length === 0 && <p>No books added to library.</p>}
                    </div>
                    {mappedBooks}
                </div>
                }

            </div>
        );
    }
}

export default Library;
