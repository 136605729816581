import React, { Component } from "react";
import "./Login.css";
import Axios from "../../axios";
import Spinner from "../Spinner/Spinner";
import { CopyToClipboard } from "react-copy-to-clipboard";

class Refer extends Component {
  state = {
    loading: true,
    success: null,
    error: null,
    copied: false,
    numRefers: 0,
    sumRefers: 0,
    rId: null,
  };

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    Axios.get("/api/user/refers")
      .then((response) => {
        if (response.data.status === "ok") {
          const refers = response.data.result.refers;
          let numRefers = 0;
          let sumRefers = 0;
          if (refers && refers.length) {
            numRefers = refers[0].num;
            sumRefers = refers[0].money / 100;
          }
          this.setState({
            error: "",
            loading: false,
            numRefers,
            sumRefers,
            rId: response.data.result.rId,
          });
        } else if (response.data.notLoggedIn) {
          window.location.href = "/login?redirect=/refer-a-friend";
        } else {
          this.setState({
            error: response.data.error,
            loading: false,
            success: "",
          });
        }
      })
      .catch((err) => {
        console.dir(err);
        this.setState({
          error: err,
          loading: false,
          success: "",
        });
      });
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }

    let error = "";
    if (this.state.error && this.state.error.length) {
      error = (
        <div className="alert alert-warning" role="alert">
          {this.state.error}
        </div>
      );
    }

    let success = "";
    if (this.state.success && this.state.success.length) {
      success = (
        <div className="alert alert-success" role="alert">
          {this.state.success}
        </div>
      );
    }

    const url = "https://www.sumizeit.com/r/" + this.state.rId;
    const text = encodeURIComponent(
      "I use sumizeit.com for bestselling nonfiction book summaries. Check it out!"
    );

    const emailText =
      "mailto:?body=Hello, I use sumizeit.com. Check it out. You can access 10-15 minute business and self help book summaries in text, audio, and video format on mobile and desktop. Here's the link: " +
      encodeURIComponent(url) +
      "&amp;subject=Check out sumizeit.com&amp;cc=&amp;bcc=";
    const twitterShareLink = `https://twitter.com/share?text=${text}&url=${url}`;
    const facebookShareLink =
      "https://www.facebook.com/sharer/sharer.php?u=" +
      url +
      "&src=sdkpreparse&quote=" +
      text;

    return (
      <div className="container Refer">
        <div className="row Refer_Header"></div>
        <div className="row Refer_Body">
          {error}
          {success}
          <h1>Refer a Friend</h1>
          <p>
            Invite your friends and family and earn 25% of the proceeds when
            they subscribe. Here's how it works:
          </p>

          <ol>
            <li>
              Share your unique referral link with your friends and family via
              email, social media, or any other means you prefer.
            </li>

            <li>
              When your friends sign up using your link and become a paid
              member, you'll receive 25% of the proceeds.
            </li>

            <li>The more friends you refer, the more money you make.</li>
          </ol>
          <div className="Referrals_Box">
            <h2>Share your link</h2>
            <br />
            <p>
              Copy your personal referral link and share it with your friends
              and followers.
            </p>
            <div className="row Referrals_LinkShareTd">
              <div className="col-md-4 Referrals_LinkTd">Referral Link</div>
              <div className="col-md-8 Referrals_Link">
                <CopyToClipboard
                  text={url}
                  onCopy={() => this.setState({ copied: true })}
                >
                  <span>{url}</span>
                </CopyToClipboard>
                {this.state.copied ? (
                  <span style={{ color: "red" }}>
                    &nbsp;&nbsp;&nbsp;Copied.
                  </span>
                ) : null}
              </div>
            </div>
            <div className="row Referrals_LinkShareTd">
              <div className="col-md-4 Referrals_LinkTd Referrals_ShareLinkLinkTd">
                Share Via
              </div>
              <div className="col-md-8 Referrals_ShareLink">
                <a href={twitterShareLink} target="_blank">
                  Share on Twitter
                </a>
                <br />
                <a href={facebookShareLink} target="_blank">
                  Share on Facebook
                </a>
                <br />
                <a href={emailText} target="_blank">
                  <span>Share via Email</span>
                </a>
              </div>
            </div>
          </div>
          <div className="Referrals_Box">
            <h3>Stats so Far</h3>
            <div className="row Refer_Stats">
              <div className="col-md-9">Number of friends who subscribed</div>
              <div className="col-md-3">{this.state.numRefers}</div>
            </div>
            <div className="row Refer_Stats">
              <div className="col-md-9">Amount of money made</div>
              <div className="col-md-3">${this.state.sumRefers}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Refer;
