import React, { Component } from 'react';

import Aux from '../Aux/Aux';
import './Layout.css';
import Header from '../../components/Navigation/Header/Header';
import Footer from "../../components/Footer/Footer";

class Layout extends Component {
    render () {
        var inBookDetail = (window.location.href.indexOf('book-detail') !== -1 && window.location.href.indexOf('redirect') === -1);

        return (
            <Aux>
                {!inBookDetail
                && <Header/>
                }
                {this.props.children}
                {!inBookDetail
                && <Footer/>
                }
            </Aux>
        )
    }
}

export default Layout;