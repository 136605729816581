import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgressBar = ({ doNext }) => {
    const [percentage, setPercentage] = React.useState(0);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setPercentage((prevPercentage) => {
                if (prevPercentage >= 100) {
                    clearInterval(interval);
                    doNext();
                    return 100;
                } else {
                    return prevPercentage + 1;
                }
            });
        }, 20);

        return () => clearInterval(interval);
    }, []);

    return (
        <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
        />
    );
};

export default CircularProgressBar;