import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from "./hoc/Layout/Layout";
import Pricing from "./components/Pricing/Pricing";
import Lifetime from "./components/Pricing/Lifetime";
import Aux from "./hoc/Aux/Aux";
import BookDetail from "./components/Book/BookDetail/BookDetail";
import Video from "./components/Book/Video/Video";
import BookList from "./components/Book/BookList/BookList";
import BookListPage from "./components/Book/BookListPage/BookListPage";
import BookSearch from "./components/Book/BookSearch/BookSearch";
import Reader from "./components/Book/Reader/Reader";
import SignUp from "./components/Login/SignUp";
import Login from "./components/Login/Login";
import Referred from "./components/Login/Referred";
import Refer from "./components/Login/Refer";
import Library from "./components/Login/Library";
import Account from "./components/Login/Account";
import Logout from "./components/Login/Logout";
import Home from "./components/Home/Home";
import Visuals from "./components/Home/Visuals";
import Visual from "./components/Home/Visual";
import ChooseCategories from "./components/Home/ChooseCategories";
import FAQ from "./components/FAQ/FAQ";
import Payment from "./components/Pricing/Payment";
import InvitationLink from "./components/Promotions/Invitations/InvitationLink";
import AffiliateProgram from "./components/Promotions/Invitations/AffiliateProgram";
import ForgotPassword from "./components/Login/ForgotPassword";
import ResetPassword from "./components/Login/ResetPassword";
import ProtectedRoute from "./components/ProtectedRoute";
import Author from "./components/Book/Author/Author";
import Feedback from "./components/Feedback/Feedback";
import UserList from "./components/Admin/User/UserList";
import UserEdit from "./components/Admin/User/UserEdit";
import AdminBookList from "./components/Admin/Book/AdminBookList";
import BookEdit from "./components/Admin/Book/AdminBookEdit";
import AdminBookPhoto from "./components/Admin/Book/AdminBookPhoto";
import AdminBookVector from "./components/Admin/Book/AdminBookVector";
import AdminBookInfographic from "./components/Admin/Book/AdminBookInfographic";
import AdminBookMedia from "./components/Admin/Book/AdminBookMedia";
import AdminBookAdd from "./components/Admin/Book/AdminBookAdd";
import AdminPodcastMedia from "./components/Admin/Podcasts/AdminPodcastMedia";
import AdminPodcastPhoto from "./components/Admin/Podcasts/AdminPodcastPhoto";
import AdminPodcastList from "./components/Admin/Podcasts/AdminPodcastList";
import AdminPodcastEdit from "./components/Admin/Podcasts/AdminPodcastEdit";
import AdminPodcastAdd from "./components/Admin/Podcasts/AdminPodcastAdd";
import AdminGoalAdd from "./components/Admin/Goals/AdminGoalAdd";
import AdminGoalEdit from "./components/Admin/Goals/AdminGoalEdit";
import AdminGoalList from "./components/Admin/Goals/AdminGoalList";
import AdminGoalPhoto from "./components/Admin/Goals/AdminGoalPhoto";
import AdminGoalFeaturedPhoto from "./components/Admin/Goals/AdminGoalFeaturedPhoto";
import AdminBlogEdit from "./components/Admin/Blog/AdminBlogEdit";
import DeleteAccount from "./components/Login/DeleteAccount";
import AdminBlogPhoto from "./components/Admin/Blog/AdminBlogPhoto";
import AdminBlogAdd from "./components/Admin/Blog/AdminBlogAdd";
import AdminBlogList from "./components/Admin/Blog/AdminBlogList";
import AdminInsightEdit from "./components/Admin/Insight/AdminInsightEdit";
import AdminInsightAdd from "./components/Admin/Insight/AdminInsightAdd";
import AdminInsightList from "./components/Admin/Insight/AdminInsightList";
import TenDayChallenge from "./components/Book/TenDayChallenge/TenDayChallenge";
import ContactUs from "./components/Contact/ContactUs";
import SuggestBook from "./components/Contact/SuggestBook";
import SubscriptionCodeActivate from "./components/Promotions/SubscriptionCodeActivate";
import CancelSubscription from "./components/Login/CancelSubscription";
import SetKindle from "./components/Kindle/SetKindle";
import SendToKindle from "./components/Kindle/SendToKindle";
import AdminCollectionAdd from "./components/Admin/Collections/AdminCollectionAdd";
import AdminCollectionList from "./components/Admin/Collections/AdminCollectionList";
import AdminCollectionEdit from "./components/Admin/Collections/AdminCollectionEdit";
import AdminVisualAdd from "./components/Admin/Visuals/AdminVisualAdd";
import AdminVisualList from "./components/Admin/Visuals/AdminVisualList";
import AdminVisualEdit from "./components/Admin/Visuals/AdminVisualEdit";
import AdminVisualPhoto from "./components/Admin/Visuals/AdminVisualPhoto";
import GiftCard from "./components/GiftCard/GiftCard";
import DownloadPDF from "./components/Book/BookDetail/DownloadPDF";
import Quiz from "./components/Quiz/Quiz";
import PersonalityQuiz from "./components/Quiz/PersonalityQuiz";
import DownloadPDFOrSub from "./components/Book/BookDetail/DownloadPDFOr";
import QuizResults from "./components/Quiz/QuizResults";
import PersonalityQuizResults from "./components/Quiz/PersonalityQuizResults";
import GetStarted from "./components/GetStarted/GetStarted";
import NotFound from "./components/NotFound/NotFound";

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App" id="App">
          <Layout>
            <ProtectedRoute exact path="/library" component={Library} />
            <Route path="/signup" component={SignUp} />
            <Route path="/login" component={Login} />
            <ProtectedRoute path="/home" exact component={Home} />
            <ProtectedRoute path="/visuals/:id" exact component={Visual} />
            <ProtectedRoute path="/visuals" exact component={Visuals} />
            <ProtectedRoute
              path="/choose-categories/:type"
              exact
              component={ChooseCategories}
            />
            <ProtectedRoute path="/account" exact component={Account} />
            <Route path="/contact" exact component={ContactUs} />
            <Route path="/suggest" exact component={SuggestBook} />
            <Route path="/lifetime" exact component={Lifetime} />
            {/*<Route path="/books/:id" exact component={BookDetail}/>*/}
            <Route path="/category/:category" exact component={BookListPage} />
            <Route
              path="/subscriptioncode"
              exact
              component={SubscriptionCodeActivate}
            />
            <Route path="/r/:id" exact component={Referred} />
            <Route path="/refer-a-friend" exact component={Refer} />
            <Route
              path="/ten-day-challenge"
              exact
              component={TenDayChallenge}
            />
            <Route
              path="/subscriptioncode/:code"
              exact
              component={SubscriptionCodeActivate}
            />
            <ProtectedRoute path="/admin/users" exact component={UserList} />
            <ProtectedRoute
              path="/admin/goals"
              exact
              component={AdminGoalList}
            />
            <ProtectedRoute
              path="/admin/goals/photo/:id"
              exact
              component={AdminGoalPhoto}
            />
            <ProtectedRoute
              path="/admin/goals/featured-photo/:id"
              exact
              component={AdminGoalFeaturedPhoto}
            />
            <ProtectedRoute
              path="/admin/goal-add"
              exact
              component={AdminGoalAdd}
            />
            <ProtectedRoute
                path="/admin/goal-edit/:id"
                exact
                component={AdminGoalEdit}
            />
            <ProtectedRoute
                path="/download-pdf/:id/:title"
                exact
                component={DownloadPDF}
            />
            <ProtectedRoute
              path="/admin/books-list"
              exact
              component={AdminBookList}
            />
            <ProtectedRoute
              path="/admin/user-edit/:userId"
              exact
              component={UserEdit}
            />
            <ProtectedRoute
              path="/admin/book-edit/:bookId"
              exact
              component={BookEdit}
            />
            <ProtectedRoute
              path="/admin/book-add"
              exact
              component={AdminBookAdd}
            />
            <ProtectedRoute
                path="/admin/podcast-add"
                exact
                component={AdminPodcastAdd}
            />
            <ProtectedRoute
                path="/admin/podcast-media/:id"
                exact
                component={AdminPodcastMedia}
            />
            <ProtectedRoute
                path="/admin/podcast-photo/:id"
                exact
                component={AdminPodcastPhoto}
            />
            <ProtectedRoute
                path="/admin/podcasts"
                exact
                component={AdminPodcastList}
            />
            <ProtectedRoute
                path="/get-started"
                exact
                component={GetStarted}
            />
            <ProtectedRoute
                path="/admin/podcasts/:id"
                exact
                component={AdminPodcastEdit}
            />
            <ProtectedRoute
              path="/send-to-kindle/:bookId"
              exact
              component={SendToKindle}
            />
            <Route path="/book-detail/:id" exact component={BookDetail} />
            <Route path="/account/delete" exact component={DeleteAccount} />
            <ProtectedRoute
              path="/admin/collections"
              exact
              component={AdminCollectionList}
            />
            <ProtectedRoute
              path="/admin/collection-edit/:id"
              exact
              component={AdminCollectionEdit}
            />
            <ProtectedRoute
              path="/admin/collection-add"
              exact
              component={AdminCollectionAdd}
            />
            <Route path="/giftcard" exact component={GiftCard} />
            <ProtectedRoute
              path="/admin/blog-add"
              exact
              component={AdminBlogAdd}
            />
            <ProtectedRoute
              path="/admin/blog-edit/:postId"
              exact
              component={AdminBlogEdit}
            />
            <ProtectedRoute
              path="/admin/blog-photo/:postId"
              exact
              component={AdminBlogPhoto}
            />
            <ProtectedRoute
              path="/admin/insight-edit/:id"
              exact
              component={AdminInsightEdit}
            />
            <ProtectedRoute
              path="/admin/insight-add"
              exact
              component={AdminInsightAdd}
            />
            <ProtectedRoute
              path="/admin/insights"
              exact
              component={AdminInsightList}
            />

            <ProtectedRoute
              path="/admin/blog-list"
              exact
              component={AdminBlogList}
            />

            <ProtectedRoute
                path="/admin/visuals"
                exact
                component={AdminVisualList}
            />

            <ProtectedRoute
                path="/admin/visuals-add"
                exact
                component={AdminVisualAdd}
            />

            <ProtectedRoute
                path="/admin/visuals-update/:id"
                exact
                component={AdminVisualEdit}
            />

            <ProtectedRoute
                path="/admin/visuals-photo/:id"
                exact
                component={AdminVisualPhoto}
            />


            <ProtectedRoute
              path="/admin/book-photo/:id"
              exact
              component={AdminBookPhoto}
            />
            <ProtectedRoute
              path="/admin/book-vector/:id"
              exact
              component={AdminBookVector}
            />
            <ProtectedRoute
              path="/admin/book-infographic/:id"
              exact
              component={AdminBookInfographic}
            />
            <ProtectedRoute
              path="/admin/book-media/:id"
              exact
              component={AdminBookMedia}
            />
            <ProtectedRoute path="/set-kindle" exact component={SetKindle} />
            <ProtectedRoute path="/video/:id" exact component={Video} />
            <Route path="/logout" exact component={Logout} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route path="/cancel" exact component={CancelSubscription} />
            <Route
              path="/reset-password/:email/:code"
              exact
              component={ResetPassword}
            />
            <ProtectedRoute path="/invite" exact component={InvitationLink} />
            <Route path="/affiliate" exact component={AffiliateProgram} />
            <Route path="/feedback-form" exact component={Feedback} />
            <Route path="/download-pdf-or/:id/:title" exact component={DownloadPDFOrSub} />
            <Route path="/quiz" exact component={Quiz} />
            <Route path="/quiz-results" exact component={QuizResults} />
            <Route path="/personality-quiz" exact component={PersonalityQuiz} />
            <Route path="/personality-quiz-results" exact component={PersonalityQuizResults} />
            <ProtectedRoute path="/payment/:planId" exact component={Payment} />
          </Layout>
        </div>
      </Router>
    );
  }
}

export default App;
