import React, {Component} from "react";
import axios from "../../../axios";
import Spinner from "../../Spinner/Spinner";

class DownloadPDF extends Component {
    state = {
        loading: true,
        fileDownloadUrl: null
    };

    handleBufferSummary(buffero) {
        // Create a buffer (example only, you would replace this with your actual buffer)
        const buffer = new Buffer(buffero);

        // Convert the buffer to a file
        const file = new File([buffer], "summary.pdf", {type: "application/pdf"});

        // Download the file
        const url = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        this.setState({
            fileDownloadUrl: url,
            loading: false,
        });
    }

    fetch() {
        if (this.props.match.params.id) {
            axios.get("/api/books/download-pdf/" + this.props.match.params.id).then(response => {
                if (response.data.status === "ok") {
                    this.handleBufferSummary(response.data.result.buffer);
                } else if (response.data.notPaid) {
                    window.location.href = `/download-pdf-or/${this.props.match.params.id}/${this.props.match.params.title}`;
                } else {
                    this.setState({
                        error: response.data.error
                    });
                }
            }).catch(error => {
                this.setState({
                    error: "There was an error downloading the PDF. Please try again."
                });
            });
        }
    }

    componentDidMount() {
        this.fetch();
    }

    render() {
        if (this.state.loading) {
            return <Spinner/>;
        }

        if (this.state.error) {
            return (
                <p className="alert alert-danger">
                    {this.state.error}
                </p>
            );
        }

        if (this.state.fileDownloadUrl) {
            return (
                <div align="center" className="Main AlertSuccess">
                    <p>
                        Thanks for downloading the book summary! The summary was downloaded
                        to your computer. If you have any questions, please contact
                        info@sumizeit.com.
                    </p>
                </div>
            );
        }
    }
}


export default DownloadPDF;
