import React, {Component} from 'react';
import axios from '../../axios';

class ContactUs extends Component {

    state = {
        name: null,
        email: null,
        body: null
    };

    submit(event) {
        event.preventDefault();

        const {name, email, body} = event.target.elements;

        if (body.value && body.value.length) {
            axios.post('/api/contact', {
                body: body.value,
                email: email.value,
                name: name.value
            }).then(response => {
                var result = response.data;

                if (result.error) {
                    this.setState({error: result.error});
                } else {
                    this.setState({complete: true});
                }
            }).catch(error => {
                this.setState({error: error.message});
            });
        } else {
            this.setState({error: "Please complete the form."});
        }
    }

    render() {
        if (this.state.complete) return (
            <div align="center" className="Main AlertSuccess">Thanks for the message!</div>);

        var error = "";
        if (this.state.error) {
            error = (
                <div className="alert alert-danger" role="alert">
                    {this.state.error}
                </div>
            )
        }
        var result = (
            <div className="container Main">
                <h1 align="center">Contact Us</h1>
                <br/>
                <form onSubmit={this.submit.bind(this)} style={{margin: "auto", maxWidth: "400px"}}>
                    {error}
                    <p>Please use the form below to contact us anytime. You can also email us at info@sumizeit.com.</p>
                    <br/>
                    <br/>

                    <input name="name" className="form-control" type="text" placeholder="Your Name"/>
                    <br/>
                    <br/>
                    <input name="email" className="form-control" type="text" placeholder="Your Email"/>
                    <br/>
                    <br/>
                    <textarea name="body" className="form-control StandardTextarea-1" placeholder="Your Message"/>
                    <br/>
                    <br/>
                    <button type="submit" className="StandardButton-2">Submit</button>
                </form>
            </div>

        );
        return result
    }
}

export default ContactUs;
