import React from "react";
import "./Footer.css";

const Footer = (props) => (
  <section className="_footer_sumizeit_wrapper">
    <div className="_footer_sumizeit_wrap">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
            <div
                className="_footer_sumizeit_logo_cont_wrap overflow wow"
                data-wow-duration="0.8s"
            >
              <div className="_footer_sumizeit_logo">
                <img
                    src="/images/footer-logo.svg"
                    alt="image"
                    className="_footer_sumizeit_logo_tag"
                />
              </div>
              <p className="_footer_sumizeit_logo_cont">
                Satisfy Your Curiosity &amp; Expand Your Knowledge with
                Easy-to-Digest, Non-Fiction Book Summaries.
              </p>

              <br/>
              <div className="_footer_social_icons">
                <a href="https://www.facebook.com/sumizeit" target="_blank">
                  <img src="/images/facebook.svg" className="_footer_social_icon"
                       alt="sumizeit book summary app facebook page"/>
                </a>
                <a href="https://www.x.com/sumizeit" target="_blank">
                  <img src="/images/twitter.svg" className="_footer_social_icon"
                       alt="sumizeit book summary app twitter page"/>
                </a>
                <a href="https://www.linkedin.com/company/sumizeit" target="_blank">
                  <img src="/images/linkedin.svg" className="_footer_social_icon"
                       alt="sumizeit book summary app linkedin page"/>
                </a>
                <a href="https://www.instagram.com/sumizeit" target="_blank">
                  <img src="/images/instagram.svg" className="_footer_social_icon"
                       alt="sumizeit book summary app instagram page"/>
                </a>
              </div>
              <p className="_footer_sumizeit_logo_cont">
                info (at) sumizeit (dot) com
                <br />
                Need help? Call us at (305) 501-1394
              </p>
              <br/>
              <br/>
              <div>
                <a href="https://apps.apple.com/eg/app/sumizeit-nonfiction-summaries/id1327646115">
                  <img
                      src="/images/main-appstore.png"
                      className="Footer_AppStore"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
            <div
                className="_footer_sumizeit_menu overflow wow"
                data-wow-duration="0.89s"
            >
              <p className="_footer_sumizeit_menu_txt">Links</p>

              <ul className="_footer_sumizeit_menu_ul">
                <li className="_footer_sumizeit_menu_li">
                  <a href="/about" className="_footer_sumizeit_menu_itm">
                    About
                  </a>
                </li>
                <li className="_footer_sumizeit_menu_li">
                  <a href="/books" className="_footer_sumizeit_menu_itm">
                    Book Summaries
                  </a>
                </li>
                <li className="_footer_sumizeit_menu_li">
                  <a href="/try" className="_footer_sumizeit_menu_itm">
                    Pricing
                  </a>
                </li>
                <li className="_footer_sumizeit_menu_li">
                  <a href="/payment/gift?coupon=WELCOME" className="_footer_sumizeit_menu_itm">Gift Sumizeit</a>
                </li>
                <li className="_footer_sumizeit_menu_li">
                  <a href="/teams" className="_footer_sumizeit_menu_itm">
                    Teams
                  </a>
                </li>
                <li className="_footer_sumizeit_menu_li">
                  <a href="/quiz" className="_footer_sumizeit_menu_itm">
                    Intelligence Quiz
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
            <div
                className="_footer_sumizeit_menu overflow wow"
                data-wow-duration="0.95s"
            >
              <ul className="_footer_sumizeit_menu_ul">
                <li className="_footer_sumizeit_menu_li">
                  <a href="/refer" className="_footer_sumizeit_menu_itm">
                    Affiliate Program
                  </a>
                </li>
                <li className="_footer_sumizeit_menu_li">
                  <a href="/blog" className="_footer_sumizeit_menu_itm">
                    Blog
                  </a>
                </li>
                <li className="_footer_sumizeit_menu_li">
                  <a href="/book-quotes" className="_footer_sumizeit_menu_itm">
                    Quotes
                  </a>
                </li>
                <li className="_footer_sumizeit_menu_li">
                  <a href="/contact" className="_footer_sumizeit_menu_itm">
                    Contact Us
                  </a>
                </li>
                <li className="_footer_sumizeit_menu_li">
                  <a href="/terms" className="_footer_sumizeit_menu_itm">
                    Terms of Use
                  </a>
                </li>
                <li className="_footer_sumizeit_menu_li">
                  <a href="/privacy" className="_footer_sumizeit_menu_itm">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="_footer_sumizeit_copyr8 overflow wow">
              <p className="_footer_sumizeit_copyr8_txt">
                © Scomy LLC 2022. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Footer;
