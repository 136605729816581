import React, { Component } from "react";
import axios from "../../axios";
import "./Home.css";
import _ from "lodash";
import Spinner from "../Spinner/Spinner";
import Color from "color-thief-react";
import { NavLink } from "react-router-dom";

var removeNonAlphaNumeric = function (name) {
  var catName = name.toLowerCase().replace(/[^0-9a-z_\s]/gi, "");
  catName = catName.replace(/\s+/g, " ");
  return catName.replace(/\s/g, "_");
};

class Home extends Component {
  state = {
    loading: true,
    books: [],
    categories: [],
    user: {},
    isPaid: false,
    limit: 20,
    showOnlyHistory: false,
    bookHistory: [],
    recommendations: [],
  };

  componentDidMount() {
    this.fetchBooks();
  }

  fetchBooks() {
    axios
      .get("/api/books")
      .then((response) => {
        var r = response.data;

        if (r.error) {
          this.setState({
            error: r.error,
            loading: false,
          });
        } else if (r.status === "ok") {
          if (r.result.recommendations.length < 1) {
            window.location.href = "/choose-categories/new";
          } else {
            this.setState(
                {
                  loading: false,
                  books: r.result.books,
                  categories: r.result.categories,
                  recommendations: r.result.recommendations
                }
            );
          }
        }
      })
      .catch((error) => {
        this.setState({
          error: error.message,
          loading: false,
        });
      });
  }

  onLoadMore() {
    this.setState({
      limit: this.state.limit + 20,
    });
  }

  renderItem(section, item) {
    return (
      <Color src={item.image} crossOrigin="anonymous" format="hex">
        {({ data, loading }) => {
          if (loading) return <Spinner />;
          return (
            <div key={`${section}-${item.id}`} className="Home-item">
              <div
                style={{ backgroundColor: data }}
                className="Home-bookitem-wrapper"
              >
                <a href={`/books/${item.subid}`}>
                  <button
                    style={{
                      backgroundImage: "url('" + item.image + "')",
                    }}
                    className="Home-bookitem"
                  ></button>
                </a>
              </div>
              <a href={`/books/${item.subid}`} className="Home-itemtitle">
                {item.title}
              </a>
              <div className="Home-itemauthor">{item.author}</div>
            </div>
          );
        }}
      </Color>
    );
  }

  showHistory() {
    this.state({
      showOnlyHistory: !this.state.showOnlyHistory,
    });
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }

    var mappedBooks = [];
    var mappedCategories = [];
    var mappedMy = [];
    var mappedRecommendations = [];
    var books = this.state.books;
    var recommendations = this.state.recommendations;
    var categories = this.state.categories;
    var myItems = this.state.bookHistory;
    var userName = this.state.user.firstName;

    books = books.slice(0, 6);
    recommendations = recommendations.slice(0, 10);

    if (myItems && myItems.length) {
      mappedMy = myItems.map((myItem) => {
        return this.renderItem("my", myItem);
      });
    }

    mappedMy = mappedMy.slice(0, 10);

    if (books && books.length) {
      mappedBooks = books.map((book) => {
        return this.renderItem("new", book);
      });
    }

    if (recommendations && recommendations.length) {
      mappedRecommendations = recommendations.map((book) => {
        return this.renderItem("recommendations", book);
      });
    }

    if (categories) {
      mappedCategories = categories.map((cat) => {
        return (
          <div
            className="Home-categoryname CategoryName"
            key={`category-${cat.subid}`}
          >
            <a href={`/books/?category=${cat.subid}`}>{cat.name}</a>
          </div>
        );
      });
    }

    return (
      <div className="Home Main">
        {userName && (
          <h1 className="Home-title">
            Welcome Back, {this.state.user.firstName}!
          </h1>
        )}
        {!userName && <h1 className="Home-title">Welcome Back!</h1>}

        {mappedMy && mappedMy.length > 0 && (
          <div className="Home-box Home-boxhistory">
            <div className="Home-boxtop">
              <h2>Pick up where you left off</h2>
              {!this.state.showOnlyHistory && (
                <div className="Home-boxtopright">
                  <a href="#" onClick={this.showHistory.bind(this, true)}>
                    View all
                  </a>
                </div>
              )}
              <div className="clear"></div>
            </div>
            {mappedMy}
            {this.state.showOnlyHistory &&
              myItems &&
              myItems.length > this.state.limit && (
                <div className="Home-loadmore-wrapper">
                  <button
                    onClick={this.onLoadMore.bind(this)}
                    className="Home-loadmore"
                  >
                    Load more
                  </button>
                </div>
              )}
          </div>
        )}

        {mappedRecommendations && mappedRecommendations.length > 0 && (
          <div className="Home-box Home-boxnew">
            <div className="Home-boxtop">
              <h2>Books Recommended For You</h2>
              <div className="Home-boxtopright">
                <a href="/books/?sort=newest">View all</a>
              </div>
              <div className="clear"></div>
              <p>Access each book summary by clicking on a link below.</p>
              <p>New members get access to 2 FREE book summaries.</p>
            </div>
            {mappedRecommendations}
          </div>
        )}

        {mappedBooks && (
          <div className="Home-box Home-boxnew">
            <div className="Home-boxtop">
              <h2>New Releases</h2>
              <div className="Home-boxtopright">
                <a href="/books/?sort=newest">View all</a>
              </div>
              <div className="clear"></div>
              <p>Access each book summary by clicking on a link below.</p>
              <p>New members get access to 2 FREE book summaries.</p>
            </div>
            {mappedBooks}
          </div>
        )}

        <div className="Home-box Home-boxgetstarted">
          <div>
            <div className="Home-toplink Home-infographics">
              <p>
                <b>Download our infographics</b>
              </p>
              <a href="/infographics">
                <button>Go</button>
              </a>
            </div>

            <div className="Home-toplink Home-tendaychallenge">
              <p>
                <b>Take our 10-day challenge</b>
              </p>
              <NavLink to="/ten-day-challenge">
                <button>Go</button>
              </NavLink>
            </div>
            <div className="Home-toplink Home-randombook">
              <p>
                <b>Try a random book</b>
              </p>
              <a href="/random">
                <button>Go</button>
              </a>
            </div>
          </div>
        </div>

        {mappedCategories && !this.state.showOnlyHistory && (
          <div className="Home-box Home-boxcategories">
            <div className="Home-boxtop">
              <h2>Explore our categories</h2>
              <div className="clear"></div>
            </div>
            <div className="Home-categories Categories">{mappedCategories}</div>
          </div>
        )}
      </div>
    );
  }
}

export default Home;
