import React, { Component } from "react";
import axios from "../../../axios";
import "../Admin.css";
import Spinner from "../../Spinner/Spinner";
import Form from "../../Form/Form";

class UserEdit extends Component {
  state = {
    user: null,
    loading: true,
    error: "",
    success: "",
  };

  componentWillMount() {
    this.fetch();
  }

  fetch() {
    if (this.props.match && this.props.match.params.userId) {
      const userId = this.props.match.params.userId;
      axios
        .get("/api/admin/user/edit/" + userId)
        .then((response) => {
          if (response.data.result.user && response.data.result.user.length) {
            this.setState({
              user: response.data.result.user[0],
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({ error: true, loading: false });
        });
    }
  }

  handleSubmit(values) {
    if (this.props.match && this.props.match.params.userId) {
      this.setState(
        {
          loading: true,
        },
        () => {
          axios
            .put("/api/admin/user", {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              planId: values.planId,
              paidDate: values.paidDate,
              paymentMethod: values.paymentMethod,
              userId: this.state.user.id,
            })
            .then((response) => {
              if (response.data.status === "ok") {
                this.fetch();
              } else {
                this.setState(
                  {
                    error: response.data.error,
                    loading: false,
                    success: "",
                  },
                  () => {
                    window.scrollTo(0, 0);
                  }
                );
              }
            })
            .catch((err) => {
              console.dir(err);
              this.setState(
                {
                  error: err,
                  loading: false,
                  success: "",
                },
                () => {
                  window.scrollTo(0, 0);
                }
              );
            });
        }
      );
    }
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }
    const user = this.state.user;
    var error = "";
    if (this.state.error && this.state.error.length) {
      error = (
        <>
          <div className="alert alert-warning" role="alert">
            {this.state.error}
          </div>
          <br />
          <br />
        </>
      );
    }

    var success = "";
    if (this.state.success && this.state.success.length) {
      success = (
        <>
          <div className="alert alert-success" role="alert">
            {this.state.success}
          </div>
          <br />
          <br />
        </>
      );
    }

    return (
      <div className="container StandardForm">
        {error}
        {success}
        <div className="row">
          <h1>User #{user.id}</h1>
          <Form
            submitHandler={this.handleSubmit.bind(this)}
            submitButtonLabel="Submit"
            fields={[
              {
                id: "firstName",
                label: "First Name",
                type: "text",
                inputType: "text",
                required: true,
                value: user.firstName,
              },
              {
                id: "lastName",
                label: "Last Name",
                type: "text",
                inputType: "text",
                required: true,
                value: user.lastName,
              },
              {
                id: "email",
                label: "email",
                type: "text",
                inputType: "text",
                required: true,
                value: user.email,
              },
              {
                id: "planId",
                label: "Plan Id",
                type: "text",
                inputType: "text",
                required: false,
                value: user.planId,
              },
              {
                id: "paymentMethod",
                label: "Payment Method",
                type: "text",
                inputType: "text",
                required: false,
                value: user.paymentMethod,
              },
              {
                id: "paidDate",
                label: "Paid Date",
                type: "text",
                inputType: "text",
                required: false,
                value: user.paidDate,
              },
              {
                id: "endPaidDate",
                label: "End Paid Date",
                type: "text",
                inputType: "text",
                required: false,
                value: user.endPaidDate,
              },
            ]}
            showLabels={true}
            errorHandler={(error) => {
              window.scrollTo(0, 0);
              this.setState({
                error,
              });
            }}
          />
        </div>
      </div>
    );
  }
}

export default UserEdit;
