import React, {Component} from 'react';
import axios from '../../../axios';
import "./BookList.css";
import Book from "../Book";
import Spinner from "../../Spinner/Spinner";
import {NavLink} from "react-router-dom";
import Kindle from "../../Kindle/Kindle";
import Modal from "../../Modal/Modal";

function getNum(val) {
    val = +val || 0;
    return val;
}

class BookList extends Component {

    state = {
        books: null, loading: true,
        bookToSendToKindle: null,
        showModal: false
    };

    constructor (props) {
        super(props);

        this.state = {
            books: null, loading: true
        };

        if (props.books) {
            this.state.books = props.books;
            this.state.loading = false;
        }
    }

    componentDidMount() {
        if (!this.state.books) {
            axios.get('/api/books/list').then(response => {
                this.setState({books: response.data.books, loading: false});
            }).catch(error => {
                this.setState({error: true});
            });
        }
    }

    handleRemoveFromLibrary (bookId) {
        var books = Object.assign({}, this.state.books);
        delete books[bookId];
        this.setState({
            books,
            loading: false
        }, () => {
            axios.delete('/library/delete/' + bookId).then(response => {
            }).catch(error => {
                this.setState({error: true});
            });
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.books) {
            return {books: props.books };
        }
    }

    showSendToKindleModal () {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    render() {
        if (this.state.loading) {
            return <Spinner />;
        }
        if (this.state.books) {
            var books = this.state.books;

            var matchSort = this.props.match && this.props.match.params.sort ? this.props.match.params.sort : null;
            var sort = this.props.sort ? this.props.sort : matchSort;

            var bookKeys = Object.keys(books);
            var vals = bookKeys.map(function(key) {
                return books[key];
            });

            if ((this.props && this.props.sort === "trending") || (this.props.match && this.props.match.params.sort === "trending")) {
                books = vals.sort((a, b) => - ( getNum(a.readCount) - getNum(b.readCount)));
            } else if ((this.props && this.props.sort === "ab") || (this.props.match && this.props.match.params.sort === "ab")) {
                books = vals.sort((a, b) => a.title - b.title);
            } else if ((this.props && this.props.sort === "new") || (this.props.match && this.props.match.params.sort === "new")) {
                books = vals.sort((a, b) => - (getNum(a.uploadDate) - getNum(b.uploadDate)));
            }

            var keys = Object.keys(books);
            var category = (this.props && this.props.match) ? this.props.match.params.category : this.props.category;
            var author = (this.props && this.props.match) ? this.props.match.params.author : this.props.author;
            var audio = this.props.audio;
            var video = this.props.video;
            var list = this.props.list;
            var listOwner = this.props.listOwner;
            var tag = this.props.tag;
            var collection = this.props.collection;
            var mapped = [];

            keys.forEach(key => {
                var book = books[key];
                var add = false;
                if (category) {
                    if (book.categories.indexOf(category) !== -1) {
                        add = true;
                    }
                } else if (author) {
                    if (book.author.indexOf(author) !== -1) {
                        add = true;
                    }
                } else if (list) {
                    if (book.lists && book.lists.hasOwnProperty(listOwner) && (book.lists[listOwner].indexOf(list) !== -1)) {
                        add = true;
                    }
                } else if (tag) {
                    if (book.tags && (book.tags.indexOf(tag) !== -1)) {
                        add = true;
                    }
                } else if (collection) {
                    if (book.courses && (book.courses.indexOf(collection) !== -1)) {
                        add = true;
                    }
                } else {
                   add = true;
                }

                if (add) {
                    if (audio) {
                        if (book.hasMedia || book.mediaUrl) {
                            add = true;
                        } else {
                            add = false;
                        }
                    }

                    if (video) {
                        if (book.hasVideo || book.video) {
                            add = true;
                        } else {
                            add = false
                        }
                    }
                }

                if (add) {
                    var reactKey = sort ? sort + "" + key : key;

                    var libraryMetadata = "";
                    if (this.props.isInLibrary) {
                        libraryMetadata = (

                            <div>
                                <br />
                                <button className="StandardButton-2" onClick={() => {
                                    this.setState({bookToSendToKindle: key, showModal: true})
                                }} key={`${key}-send-to-kindle`} style={{margin: "5px auto", textAlign: "center"}}>Send to Kindle</button>

                                {this.state.bookToSendToKindle === key &&
                                    <Modal onClose={this.showSendToKindleModal.bind(this)} show={this.state.showModal}>
                                        <Kindle bookId={key} />
                                    </Modal>
                                }
                                <button onClick={this.handleRemoveFromLibrary.bind(this, key)} className="StandardButton-1" key={`${key}-remove`} style={{margin: "5px auto", textAlign: "center"}}>Remove</button>

                            </div>


                        )
                    }
                    mapped.push(<Book book={books[key]} hideBookName={this.props.hideBookNames} key={reactKey} hideBookTitle={this.props.hideBookTitle} libraryMetadata={libraryMetadata} />);
                }
            });

            if (this.props.limit) {
                mapped = mapped.splice(0, this.props.limit);
            }

            return (
                <div className="BookList Main">
                    {mapped}
                </div>
            )
        } else {
            return ""
        }
    }
}

export default BookList;
