import React, {Component} from 'react';
import './Modal.css';

export default class Modal extends React.Component {
    onClose = e => {
        this.props.onClose && this.props.onClose(e);
    };
    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <div className="modal" id="modal">
                <div className="modal-content">
                    <span className="close" onClick={this.onClose}>&times;</span>
                    {this.props.children}
                </div>
            </div>
        );
    }
}