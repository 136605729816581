import React, {Component} from 'react';
import Utils from '../../utils';
import "./Payment.css"
import MetaTags from 'react-meta-tags';
import {NavLink } from 'react-router-dom';
import fathersday from "../../assets/images/fathersday.png";

class Lifetime extends Component {

    componentDidMount() {
    }

    render() {
        return (
            <div className="Pricing lifetime-container Main">
                <MetaTags>
                    <title>Upgrade to Lifetime Subscription | Sumizeit</title>
                    <meta name="description" content="Upgrade to a lifetime subscription and get the best of Sumizeit"/>
                </MetaTags>

                <h1 align="center">Happy Father's Day</h1>
                <p>For Father's day, gift your father the gift of knowledge. With a Sumizeit Lifetime Premium Membership, he gets the following for a lifetime:</p><br />
                <div className="pricing-content_left">
                    <ul className="pricingFeatureList">
                        <li>Unlimited access to nonfiction book summaries on mobile and desktop</li>
                        <li>Available in text, audio, and video</li>
                        <li>Access to a library of hundreds of book titles</li>
                        <li>New book titles added weekly</li>
                        <li>Learn how to be rich!</li>
                    </ul>
                </div>
                <div className="pricing-content_right">
                    <div className="pricing-image">
                        <img src={fathersday} style={{maxWidth: 500, width: "100%", height: "auto"}} />
                    </div>
                </div>
                <div className="clear"></div>
                <br />
                <div className="pricing-cards">
                    <div className="pricing-card" style={{display: "block", width: "100%", float: "none"}}>
                        <p className="bold">*Most Popular*</p>
                        <h2>Unlimited Access for a Lifetime</h2>
                        <p>$69.99</p>
                        <br />
                        <p style={{fontSize: "12px"}}>*Available for a limited time only</p>
                        <br />
                        <br />
                        <a href="/payment/lifetime" className="StandardButton-4" style={{margin: "0 auto", textAlign: "center"}}>Buy now</a>
                    </div>
                </div>
                <br />

                <div className="pricing-lifetime-card" style={{display: "block"}}>
                    <h2>Other options?</h2>
                    <br />
                    <p>We also provide yearly and monthly subscriptions.</p>
                    <br />
                    <br />
                    <NavLink to="/try" className="StandardButton-1">Click for other options</NavLink>
                </div>

                <br />
                <br />
                <br />
                <div>
                    <h3>Frequently Asked Questions</h3>

                    <br />
                    <h4>How can I try the product before I pay for premium?</h4>
                    <p>Sign up for an account by clicking on "Sign up" in the top right of the page. You can read or listen to three book summaries for free. After the third book summary, we ask you to upgrade to Premium.</p>

                    <h4>Does the lifetime membership auto-renew?</h4>
                    <p>No. You pay a flat fee of $69 and you get unlimited access to Sumizeit for a lifetime.</p>
                </div>
            </div>
        );
    }
}

export default Lifetime;
