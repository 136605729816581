import React, { Component } from 'react';
import './Quiz.css';
import personality from "../../assets/images/review.svg";

const getKeyWithHighestValue = (obj) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        return value > acc[1] ? [key, value] : acc;
    }, ['', -Infinity])[0];
};

class PersonalityQuizResults extends Component {
    constructor(props) {
        super(props);

        this.state = {
            personalityTypes: {},
            personalityType: ""
        };
    }

    componentWillMount() {
        let personalityTypes = {};
        try {
            personalityTypes = JSON.parse(window.sessionStorage.getItem("personalityTypes"));
        } catch {
            personalityTypes = {};
        }

        const personalityType = getKeyWithHighestValue(personalityTypes);

        this.setState({
            personalityTypes,
            personalityType
        });
    }


    render () {
        return (
            <div className="QuizBody">
                <div className="QuizImg"><img src={personality} alt="sumizeit personality type quiz"/></div>
                <h1>Quiz Result</h1>
                <p>In Surrounded by Idiots by Thomas Erikson, the author explains four main personality types using the DISC model. These types are represented by colors: Red, Yellow, Green, and Blue. Each color corresponds to a particular set of behaviors, communication styles, and preferences.</p>
                <h2>Your Personality Type: {this.state.personalityType}</h2>
                <p>Read the <a href = "/books/surrounded-by-idiots">book summary</a> for more information.</p>
            </div>
        )
    }
}

export default PersonalityQuizResults;