import React, {Component} from "react";
import Spinner from '../Spinner/Spinner';
import Form from '../../Form';
import Axios from "../../axios";
import "./Login.css";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            loading: false,
            success: ''
        };
    }

    handleSubmit(values) {
        if (this.props.match && this.props.match.params && this.props.match.params.email && this.props.match.params.code) {
            Axios.put("/api/user/reset-password", {
                password1: values.password1,
                password2: values.password2,
                code: this.props.match.params.code,
                email: this.props.match.params.email
            }).then((response) => {
                if (response.data.status === "ok") {
                    this.setState({
                        error: '',
                        success: "Your password was changed. You can now <a href='/login'>login</a>."
                    }, () => {
                        window.scrollTo(0, 0)
                    })
                } else {
                    this.setState({
                        error: response.data.error,
                        loading: false,
                        success: ''
                    }, () => {
                        window.scrollTo(0, 0)
                    });
                }
            }).catch(err => {
                console.dir(err);
                this.setState({
                    error: err,
                    loading: false,
                    success: ''
                }, () => {
                    window.scrollTo(0, 0)
                })
            });
        }
    }

    componentDidMount() {
    }

    render() {
        if (this.state.loading) {
            return (<Spinner/>);
        }

        var error = '';
        if (this.state.error && this.state.error.length) {
            error = (<div className="alert alert-warning" role="alert">{this.state.error}</div>);
        }

        if (this.state.success && this.state.success.length) {
            return (<div className="container">
                <div className="row">
                    <div className="alert alert-success" role="alert">
                        <div dangerouslySetInnerHTML={{__html: this.state.success}}/>
                    </div>
                </div>
            </div>);
        }

        return <div className="container ResetPassword profile-form">
            <div className="row">
                {error}
                <h1>Reset Password</h1>
                <p>Please provide the details below to reset your password.</p>
                <Form fields={[
                    {
                        id: "password1",
                        label: "New Password",
                        type: "text",
                        required: true,
                        inputType: "password"
                    },
                    {
                        id: "password2",
                        label: "Confirm New Password",
                        type: "text",
                        required: true,
                        inputType: "password"
                    }
                ]} submitHandler={this.handleSubmit.bind(this)} submitButtonLabel="Reset Password"
                      errorHandler={(error) => {
                          this.setState({
                              error
                          })
                      }}/>
            </div>
        </div>
    }
}

export default ResetPassword;