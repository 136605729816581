import React, { Component } from "react";
import axios from "../../../axios";
import "../Admin.css";
import Spinner from "../../Spinner/Spinner";
import AdminNavigation from "../Navigation/AdminNavigation";
import Form from "../../Form/Form";
import _ from "lodash";

class AdminBookEdit extends Component {
  state = {
    book: null,
    loading: true,
    categories: null,
    categoriesMap: {},
    goalsMap: {},
  };

  componentWillMount() {
    if (this.props.match && this.props.match.params.bookId) {
      this.fetch();
    }
  }

  fetch() {
    const bookId = this.props.match.params.bookId;
    axios
      .get("/api/admin/books/" + bookId)
      .then((response) => {
        if (response.data.status === "ok") {
          this.setState(
            {
              book: response.data.result.book,
              loading: true,
            },
            () => {
              this.fetchCategories();
            }
          );
        } else {
          this.setState({
            error: response.data.error,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: "There was an error processing your request.",
          loading: false,
        });
      });
  }

  fetchGoals() {
    axios
      .get("/api/goals")
      .then((response) => {
        if (response.data.status === "ok") {
          const gmap = {};
          response.data.result.goals.forEach((c) => {
            gmap[c.id] = c.title;
          });

          const book = Object.assign({}, this.state.book);
          if (this.state.book.goals) {
            let goals = [];
            this.state.book.goals.forEach((c) => {
              goals.push({
                label: gmap[c.id],
                value: c.id,
              });
            });
            book.goals = goals;
          }

          this.setState({
            goals: this._convertToMultiSelect(response.data.result.goals),
            loading: false,
            book,
            goalsMap: gmap,
          });
        } else {
          this.setState({
            error: response.data.error,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: "There was an error processing your request.",
          loading: false,
        });
      });
  }

  _convertToMultiSelect(values) {
    const output = [];
    values.forEach((v) => {
      output.push({
        label: v.name || v.title,
        value: v.id,
      });
    });
    return output;
  }

  fetchCategories() {
    axios
      .get("/api/categories")
      .then((response) => {
        if (response.data.status === "ok") {
          const cmap = {};
          response.data.result.categories.forEach((c) => {
            cmap[c.id] = c.name;
          });

          const book = Object.assign({}, this.state.book);

          if (this.state.book.categories) {
            const cats = [];
            this.state.book.categories.forEach((c) => {
              cats.push({
                label: cmap[c.id],
                value: c.id,
              });
            });
            book.categories = cats;
          }

          this.setState(
            {
              categories: this._convertToMultiSelect(
                response.data.result.categories
              ),
              loading: true,
              book,
              categoriesMap: cmap,
            },
            () => {
              this.fetchGoals();
            }
          );
        } else {
          this.setState({
            error: response.data.error,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: "There was an error processing your request.",
          loading: false,
        });
      });
  }

  handleSubmit(values) {
    const output = _.cloneDeep(values);

    if (this.props.match && this.props.match.params.bookId) {
      const bookId = this.props.match.params.bookId;
      this.setState(
        {
          loading: true,
        },
        () => {
          var selectedCategories = [];
          if (output.categories && output.categories.length) {
            output.categories.forEach((item) => {
              selectedCategories.push(item.value);
            });
          }

          var selectedGoals = [];
          if (output.goals && output.goals.length) {
            output.goals.forEach((item) => {
              selectedGoals.push(item.value);
            });
          }

          output.categories = selectedCategories;
          output.goals = selectedGoals;

          axios
            .put("/api/admin/books/" + bookId, output)
            .then((response) => {
              if (response.data.status === "ok") {
                const cmap = this.state.categoriesMap;
                const gmap = this.state.goalsMap;

                const cats = [];
                output.categories.forEach((c) => {
                  cats.push({
                    label: cmap[c],
                    value: c,
                  });
                });

                const gs = [];
                output.goals.forEach((c) => {
                  gs.push({
                    label: gmap[c],
                    value: c,
                  });
                });

                output.categories = cats;
                output.goals = gs;

                this.setState(
                  {
                    error: "",
                    loading: false,
                    success: "Book was updated.",
                    book: output,
                  },
                  () => {
                    window.scrollTo(0, 0);
                  }
                );
              } else {
                this.setState(
                  {
                    error: response.data.error,
                    loading: false,
                    success: "",
                  },
                  () => {
                    window.scrollTo(0, 0);
                  }
                );
              }
            })
            .catch((err) => {
              console.dir(err);
              this.setState(
                {
                  error: err,
                  loading: false,
                  success: "",
                },
                () => {
                  window.scrollTo(0, 0);
                }
              );
            });
        }
      );
    }
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }

    var error = "";
    if (this.state.error && this.state.error.length) {
      error = (
        <>
          <div className="alert alert-warning" role="alert">
            {this.state.error}
          </div>
          <br />
          <br />
        </>
      );
    }

    var success = "";
    if (this.state.success && this.state.success.length) {
      success = (
        <>
          <div className="alert alert-success" role="alert">
            {this.state.success}
          </div>
          <br />
          <br />
        </>
      );
    }

    if (this.state.book) {
      const book = this.state.book;

      return (
        <div className="Main Form">
          <h1>Edit Book {this.props.match.params.bookId}</h1>
          {success}
          {error}
          <Form
            submitHandler={this.handleSubmit.bind(this)}
            submitButtonLabel="Submit"
            fields={[
              {
                id: "title",
                label: "Title",
                type: "text",
                inputType: "text",
                required: true,
                value: book.title,
              },
              {
                id: "image",
                label: "Image",
                type: "text",
                inputType: "text",
                required: true,
                value: book.image,
              },
              {
                id: "vector",
                label: "Vector",
                type: "text",
                inputType: "text",
                required: false,
                value: book.vector,
              },
              {
                id: "infographicUrl",
                label: "Infographic",
                type: "text",
                inputType: "text",
                required: false,
                value: book.infographicUrl,
              },
              {
                id: "author",
                label: "Author",
                type: "text",
                inputType: "text",
                required: true,
                value: book.author,
              },
              {
                id: "authorDesc",
                label: "Author Description",
                type: "textarea",
                inputType: "textarea",
                required: false,
                value: book.authorDesc,
              },
              {
                id: "description",
                label: "Description",
                type: "textarea",
                inputType: "textarea",
                required: false,
                value: book.description,
              },
              {
                id: "body",
                label: "Body",
                type: "textarea",
                inputType: "textarea",
                required: false,
                value: book.body,
              },

              {
                id: "categories",
                label: "Categories*",
                type: "multi-select",
                required: true,
                inputType: "multi-select",
                options: this.state.categories,
                helpText: "Please select all that apply.",
                value: book.categories,
              },
              {
                id: "goals",
                label: "Goals*",
                type: "multi-select",
                required: false,
                inputType: "multi-select",
                options: this.state.goals,
                helpText: "Please select all that apply.",
                value: book.goals,
              },
              {
                id: "video",
                label: "Video",
                type: "text",
                inputType: "text",
                value: book.video,
              },
              {
                id: "mediaUrl",
                label: "Media Url",
                type: "text",
                inputType: "text",
                value: book.mediaUrl,
              },
              {
                id: "tags",
                label: "Tags",
                type: "text",
                inputType: "text",
                value: book.tags,
              },
              {
                id: "free",
                label: "Is Free?",
                type: "text",
                inputType: "text",
                value: book.free,
              },
              {
                id: "isPopular",
                label: "Is Popular?",
                type: "text",
                inputType: "text",
                value: book.isPopular,
              },
              {
                id: "comingSoon",
                label: "Coming soon?",
                type: "text",
                inputType: "text",
                required: false,
                value: book.comingSoon,
              },
            ]}
            showLabels={true}
            errorHandler={(error) => {
              window.scrollTo(0, 0);
              this.setState({
                error,
              });
            }}
          />
        </div>
      );
    }
    return "";
  }
}

export default AdminBookEdit;
