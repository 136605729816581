import React, {Component} from 'react';
import axios from '../../../axios';
import { Twitter, Facebook, Linkedin, Reddit, Mail } from 'react-social-sharing';
import "./Invitations.css"
import Spinner from "../../Spinner/Spinner"

class InvitationLink extends Component {

    state = {
        complete: false,
        error: null,
        loading: true,
        earnings: 0,
        numReferrals: 0
    };

    componentDidMount () {
        axios.get('/invitation/generate').then(response => {
            var result = response.data;

            if (result.error) {
                this.setState({error: result.error});
            } else {
                this.setState({inviteCode: result.code});

                axios.get('/invitation/getReferrals').then(response => {
                    var result = response.data;

                    if (result.error) {
                        this.setState({error: result.error, loading: false});
                    } else {
                        this.setState({earnings: result.referralEarning, numReferrals: result.numReferrals, loading: false});
                    }
                });
            }
        });

    }

    copyStringToClipboard (str) {
        // Create new element
        var el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = str;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
    }

    render() {
        if (this.state.loading) {
            return (
                <div>
                    <Spinner />
                    <br />
                    <br />
                    <p align = "center">Generating your referral link. Please wait at least 2 minutes.</p>
                </div>
            );
        }
        var error = "";
        if (this.state.error) {
            error = (
                <div className="alert alert-danger" role="alert">
                    {this.state.error}
                </div>
            );
            return ({error});
        }
        var header = "";
        var earnings = this.state.earnings;
        var numReferrals = this.state.numReferrals;

        header = (<div className="InvitationHeader">You referred {numReferrals} people. Total earnings: <span className="big">${earnings}</span></div>);


        if (this.state.inviteCode) {
            var link = this.state.inviteCode;
            return (
                <div className="container Main">
                    {header}
                    <div className="InvitationHero">
                        <div className="text">
                            <div className="hero-text">
                                <h2>Be a good friend, and get rewarded!</h2>
                                <p>Let people know about Sumizeit. Share your invitation link with friends and when they order, you get 10% of the revenue.</p>
                            </div>
                        </div>
                    </div>
                    <div className="InvitationCode">
                        <input type="text" value={link} id="invitationCode" disabled />&nbsp;&nbsp;<button className="StandardButton-2" onClick={this.copyStringToClipboard.bind(this, link)}>Copy</button></div>
                    <div className="SocialButtons">
                        <Facebook solidcircle small link={link}/>
                        <Linkedin solidcircle small link={link}/>
                        <Reddit solidcircle small link={link}/>
                        <Twitter solidcircle small link={link}/>
                        <Mail solidcircle small link={link}/>
                    </div>
                </div>

            );
        } else {
            return "";
        }
    }
}

export default InvitationLink;
