import React, { Component } from 'react';

import {NavLink } from 'react-router-dom';
import './Book.css';
import LazyLoad from 'react-lazyload';

class Book extends Component {
    render () {
        var titleH = "";
        if (!this.props.hideBookTitle) {
            titleH = <div className="BookTitle">{this.props.book.title}</div>;
        }
        return (
            <div className="Book">
                <a href={`/books/${this.props.book.id}`}>
                    <LazyLoad><img src = {this.props.book.image} className="BookImage" alt={this.props.book.title} /></LazyLoad>
                </a>
                {!this.props.hideBookName
                && <a href={`/books/${this.props.book.id}`}>{titleH}</a>
                }
                {this.props.libraryMetadata}
            </div>
        )
    }
}

export default Book;
