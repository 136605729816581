import React, {Component} from "react";
import axios from "../../../axios";
import "./BookDetail.css";
import Audio from "../Audio/Audio";
import Sidebar from "../../Sidebar/Sidebar";
import Spinner from "../../Spinner/Spinner";
import Header from "../../Navigation/Header/Header";
import Footer from "../../Footer/Footer";
import Utils from "../../../utils";

class BookDetail extends Component {
    state = {
        book: null,
        user: null,
        loading: true,
        sideBarOpen: false,
        optionsSideBarOpen: false,
        startedAudioPlaying: false,
        index: 0,
        success: false,
        fontIncrease: 1,
        bodyParts: null,
        enableDarkMode: false
    };

    constructor() {
        super();
        this.containerRef = React.createRef();
    }

    startedAudioPlaying() {
        if (!this.state.startedAudioPlaying) {
            this.setState({
                startedAudioPlaying: true
            });
            this.updatePosition();
        }
    }

    detectKeyPress(e) {
        if (e.keyCode === 37) {
            this.previousIndex();
        } else if (e.keyCode === 39) {
            this.incrementIndex();
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.detectKeyPress.bind(this), false);


        if (this.props.match.params.id) {
            axios
                .get("/api/books/" + this.props.match.params.id)
                .then((response) => {
                    if (response && response.data && response.data.status === "ok") {
                        if (window.gtag) {
                            window.gtag("event", "user_read", {
                                event_label: response.data.result.book.subid,
                                event_category: "user_action",
                                non_interaction: true
                            });
                        }

                        var index = response.data.result.book.position;
                        var bookBody =
                            response.data.result.book.body ||
                            response.data.result.book.truncatedBody;

                        var bodyParts = null;
                        if (bookBody && bookBody.length) {
                            bodyParts = this.getBodyParts(bookBody);
                        }

                        if (!bodyParts || !bodyParts.hasOwnProperty(index)) {
                            index = 0;
                        }

                        this.setState(
                            {
                                bodyParts,
                                book: response.data.result.book,
                                user: response.data.result.user,
                                loading: false,
                                index
                            },
                            () => {
                                this.updatePosition();
                                this.createQuiz()
                            }
                        );
                    } else {
                        this.setState({
                            error: true,
                            loading: false
                        });
                    }
                }).catch((error) => {
                this.setState({
                    error: true,
                    loading: false
                });
            });
        }
    }

    createQuiz() {
        if (!this.containerRef.current) {
            return
        }


        let bodyParts = this.state.bodyParts;
        let quizHtml = "<h3>Quiz</h3>";
        let hasQuiz = false;

        const flipCardQuizz = this.containerRef.current.querySelectorAll(".flip-quiz");

        if (flipCardQuizz && flipCardQuizz.length) {
            hasQuiz = true;
            flipCardQuizz.forEach(flipCardQuiz => {
                const flipAnswer = flipCardQuiz.dataset.answer;
                const flipText = flipCardQuiz.innerHTML;

                quizHtml += `<div class="quiz-container"><h4>Question</h4>
                  <div class="flash-card" style="perspective: 1000px;">
                  <div class="flip-card">
                    <div class="flip-front side">
                      ${flipText}
                      <div class="flip-bottom">
                          Touch to Flip
                      </div>
                    </div>
                    <div class="flip-back side">
                      ${flipAnswer}
                    </div>
                  </div>
                </div>
                </div>`;

                flipCardQuiz.remove();
            });
        }

        const mQuizs = this.containerRef.current.querySelectorAll(".multiple-quiz");

        if (mQuizs && mQuizs.length) {
            hasQuiz = true;
            mQuizs.forEach(mQuiz => {
                const mAnswer = mQuiz.dataset.answer || "";
                let mChoices = mQuiz.dataset.choices || [];
                const mQuestion = mQuiz.dataset.question || "";
                const mExplanation = mQuiz.dataset.explanation || "";
                quizHtml += `<div class="quiz-container"><h4>Question</h4><p>${sanitizeHTML(mQuestion)}</p>`;

                mChoices = JSON.parse(mChoices);
                mChoices.forEach(c => {
                    const answerClass = (c === mAnswer) ? "answer" : "";
                    quizHtml += `<div class = "choice ${answerClass}">${sanitizeHTML(c)}</div>`;
                });
                if (mExplanation) {
                    quizHtml += `<div class="explanation hide">${sanitizeHTML(mExplanation)}</div>`;
                }
                quizHtml += `</div>`;

                mQuiz.remove();
            });
        }

        const fQuizz = this.containerRef.current.querySelectorAll(".fill-quiz");

        if (fQuizz && fQuizz.length) {
            hasQuiz = true;
            fQuizz.forEach(fQuiz => {
                const fAnswer = fQuiz.dataset.answer || "";
                let fChoices = fQuiz.dataset.choices || "[]";
                const fQuestion = fQuiz.dataset.question || "No question provided.";
                const fExplanation = fQuiz.dataset.explanation || "";

                try {
                    fChoices = JSON.parse(fChoices);
                } catch (error) {
                    console.error("Invalid JSON for choices:", fChoices);
                    fChoices = [];
                }

                quizHtml += `
                   <div class="quiz-container">
                       <h4>Question</h4>
                       <div>${fQuestion.replace("_", '<div class="fill-in-blank"></div>')}</div><br />
               `;

                fChoices.forEach(c => {
                    const answerClass = c === fAnswer ? "answer" : "";
                    quizHtml += `<div class="fill-choice ${answerClass}">${sanitizeHTML(c)}</div>`;
                });

                if (fExplanation) {
                    quizHtml += `<div class="explanation hide">${sanitizeHTML(fExplanation)}</div>`;
                }

                quizHtml += `</div>`;
                fQuiz.remove();
            });
        }

        function sanitizeHTML(str) {
            return str;
        }


        if (hasQuiz) {
            bodyParts[this.state.bodyParts.length - 1] = quizHtml;

            this.setState(bodyParts);
        }
    }

    addEventListeners() {
        const flipCards = this.containerRef.current.querySelectorAll(".flip-card");

        let flipped = false;

        if (flipCards) {
            flipCards.forEach(flipCard => {
                const flipFront = flipCard.querySelector(".flip-front");

                flipFront.addEventListener("click", () => {
                    flipped = !flipped;
                    flipCard.style.transform = flipped ? "rotateY(180deg)" : "rotateY(0deg)";
                });
            });
        }


        const quizDivs = this.containerRef.current.querySelectorAll(".quiz-container");

        quizDivs.forEach(q => {
            const choiceDivs = q.querySelectorAll(".choice");
            const explanation = q.querySelector(".explanation");
            const correctAnswer = q.querySelector(".answer");

            choiceDivs.forEach(function (div) {
                div.addEventListener("click", function () {
                    if (div.classList.contains("answer")) {
                        div.classList.add("right-choice");

                        if (explanation) {
                            explanation.classList.remove("hide");
                        }
                    } else {
                        div.classList.add("wrong-choice");

                        if (explanation) {
                            explanation.classList.remove("hide");
                        }
                        if (correctAnswer) {
                            correctAnswer.classList.add("right-choice");
                        }
                    }
                });
            });


            const fillInBlankDivs = q.querySelectorAll(".fill-choice");
            const fillInBlank = q.querySelector(".fill-in-blank");

            fillInBlankDivs.forEach(function (div) {
                div.addEventListener("click", function () {
                    if (div.classList.contains("answer")) {
                        div.classList.add("right-choice");

                        if (explanation) {
                            explanation.classList.remove("hide");
                        }
                        if (fillInBlank) {
                            fillInBlank.textContent = correctAnswer.textContent;
                            fillInBlank.classList.add("correct");
                        }
                    } else {
                        div.classList.add("wrong-choice");
                        if (correctAnswer) {
                            correctAnswer.classList.add("right-choice");
                        }
                        if (explanation) {
                            explanation.classList.remove("hide");
                        }
                        if (fillInBlank && correctAnswer) {
                            fillInBlank.textContent = correctAnswer.textContent;
                            fillInBlank.classList.add("correct");
                        }
                    }
                });
            });
        });
    }

    addToLibrary() {
        if (!Utils.isAuthenticated()) {
            alert("Please login to access this feature");
            return;
        }
        axios
            .post("/api/library", {
                bookId: this.state.book.id
            })
            .then((response) => {
                if (response.data.status === "ok") {
                    var book = this.state.book;
                    book.isInLibrary = true;
                    this.setState({
                        book
                    });
                } else if (response.data.error) {
                    this.setState({error: response.data.error});
                }
            })
            .catch((error) => {
                this.setState({
                    error: true
                });
            });
    }

    getBodyParts(body) {
        if (body && body.length) {
            body = body.replace(/\srole="presentation"/g, "");
            body = body.replace(/\sdir="ltr"/g, "");

            var bodyParts = body.split("<h3>");
            if (bodyParts && bodyParts.length) {
                var count = bodyParts.length;
                for (var i = 1; i < count; i += 1) {
                    bodyParts[i] = "<h3>" + bodyParts[i];
                }

                var lastPart = [];
                if (bodyParts.length > 1) {
                    lastPart = bodyParts[bodyParts.length - 1].split("<h2>");

                    if (lastPart.length <= 1) {
                        lastPart = [];
                    } else if (lastPart && lastPart.length > 1) {
                        bodyParts.pop();

                        var count = lastPart.length;
                        for (var i = 1; i < count; i += 1) {
                            lastPart[i] = lastPart[i].replace(/<(\/?)h2>/g, "<$1h3>");
                            lastPart[i] = "<h3>" + lastPart[i];
                        }
                    }
                }

                if (bodyParts.length && bodyParts[0].length === 0) {
                    bodyParts = bodyParts.shift();
                }

                bodyParts = bodyParts.concat(lastPart);
                return bodyParts;
            }
        }
        return null;
    }

    removeFromLibrary() {
        axios
            .delete("/api/library/" + this.state.book.id)
            .then((response) => {
                if (response.data.status === "ok") {
                    var book = this.state.book;
                    book.isInLibrary = false;
                    this.setState({
                        book
                    });
                } else if (response.data.error) {
                    this.setState({
                        error: response.data.error
                    });
                }
            })
            .catch((error) => {
                this.setState({error: true});
            });
    }

    updatePosition() {
        axios.put("/api/user-book-history/", {
            bookId: this.state.book.id,
            position: this.state.index,
            completed: this.state.index + 1 === this.state.bodyParts.length - 1
        });
    }

    previousIndex() {
        if (this.state.index - 1 >= 0) {
            this.setState({index: this.state.index - 1}, () => {
                this.updatePosition();
            });
        }
    }

    toggleDarkmode() {
        this.setState(
            {
                enableDarkMode: !this.state.enableDarkMode
            },
            () => {
                if (this.state.enableDarkMode) {
                    document.body.classList.add("dark");
                } else {
                    document.body.classList.remove("dark");
                }
            }
        );
    }

    incrementIndex() {
        if (
            this.state.bodyParts &&
            this.state.index + 1 < this.state.bodyParts.length
        ) {
            this.setState({index: this.state.index + 1}, () => {
                this.updatePosition();
            });
        }
    }

    componentDidUpdate() {
        this.addEventListeners();
    }

    toggleSidebar() {
        this.setState({
            sideBarOpen: !this.state.sideBarOpen
        });
    }

    toggleOptionsSidebar() {
        this.setState({
            optionsSideBarOpen: !this.state.optionsSideBarOpen
        });
    }

    incDecFont() {
        var newFontSize = this.state.fontIncrease + 1;
        if (newFontSize > 3) {
            newFontSize = 1;
        }
        this.setState({
            fontIncrease: newFontSize
        });
    }

    submit(event) {
        event.preventDefault();

        const {name, email, body} = event.target.elements;

        if (body.value && body.value.length) {
            axios.post('/api/contact', {
                body: body.value,
                email: "info@sumizeit.com",
                name: "Feedback Form"
            }).then(response => {
                var result = response.data;

                if (result.error) {
                    this.setState({error: result.error});
                } else {
                    this.setState({success: true});
                }
            }).catch(error => {
                this.setState({error: error.message});
            });
        }
    }

    render() {
        if (this.state.loading) {
            return <Spinner/>;
        }

        if (this.state.error) {
            return (
                <div className="Alert-error">
                    There was an error. Please try again later.
                </div>
            );
        }

        if (this.state.bodyParts && this.state.bodyParts.length) {
            var bodyParts = this.state.bodyParts;
            var progress = ((this.state.index + 1) * 100) / bodyParts.length;

            var chapterMapped1 = [];
            var chapterCompleteClassName =
                this.state.index > 0 ? " chapter-complete" : " chapter-notcomplete";
            chapterMapped1.push(
                <div
                    className={`sidebar-link${chapterCompleteClassName}`}
                    onClick={() => {
                        this.setState(
                            {
                                index: 0
                            },
                            this.updatePosition
                        );
                    }}
                    key="key-insights"
                >
                    Key Insights
                </div>
            );

            var chapterMapped2 = bodyParts.map((p, index) => {
                var part = p.substring(0, p.lastIndexOf("</h3>"));
                part = part.replace("<h3>", "");
                if (part.length) {
                    var chapterCompleteClassName =
                        this.state.index > index
                            ? " chapter-complete"
                            : " chapter-notcomplete";
                    return (
                        <div
                            className={`sidebar-link${chapterCompleteClassName}`}
                            onClick={() => {
                                this.setState(
                                    {
                                        index: index
                                    },
                                    this.updatePosition
                                );
                            }}
                            key={part}
                        >
                            {part}
                        </div>
                    );
                }
            });

            var mapped = chapterMapped1.concat(chapterMapped2);

            var classFont = "";
            if (this.state.fontIncrease === 1) {
                classFont = "text-1";
            } else if (this.state.fontIncrease === 2) {
                classFont = "text-2";
            } else {
                classFont = "text-3";
            }

            var notLoggedInBodyClass = "";

            if (this.state.book.notLoggedIn) {
                notLoggedInBodyClass = "bodyNotLoggedIn";
            }
            var overlayBlock = "none";
            if (this.state.sideBarOpen || this.state.optionsSideBarOpen) {
                overlayBlock = "block";
            }

            let className = "";
            if (this.state.enableDarkMode) {
                className = "dark";
            } else {
                className = "";
            }

            return (
                <div className={`BookDetailInapp container ${className}`} ref={this.containerRef}>
                    <Sidebar
                        isOpen={this.state.sideBarOpen}
                        handleClose={() => {
                            this.setState({
                                sideBarOpen: false
                            });
                        }}
                        className="table-of-contents"
                    >
                        <div className="sidebar-wrapper">
                            <h2>{this.state.book.title}</h2>
                            <div className="timeline">{mapped}</div>
                        </div>
                    </Sidebar>
                    <Sidebar
                        isOpen={this.state.optionsSideBarOpen}
                        handleClose={() => {
                            this.setState({
                                optionsSideBarOpen: false
                            });
                        }}
                        className="options-sidebar"
                    >
                        <div className="sidebar-wrapper">
                            <a href={`/books/${this.state.book.id}`} className="close-book">
                                Close book summary
                            </a>
                            <a
                                className="send-to-kindle"
                                href={`/send-to-kindle/${this.state.book.id}`}
                            >
                                Send to kindle
                            </a>
                            <a
                                className="download"
                                href={`/download-pdf/${this.state.book.subid}/${this.state.book.title}`}
                            >
                                Download PDF
                            </a>
                            {!this.state.book.isInLibrary && (
                                <div
                                    className="add-remove-library"
                                    onClick={this.addToLibrary.bind(this)}
                                >
                                    Add to Library
                                </div>
                            )}
                            {this.state.book.isInLibrary && (
                                <div
                                    className="add-remove-library"
                                    onClick={this.removeFromLibrary.bind(this)}
                                >
                                    Remove from Library
                                </div>
                            )}
                        </div>
                    </Sidebar>
                    <div
                        className="overlay"
                        style={{display: overlayBlock}}
                        onClick={() => {
                            this.setState({
                                optionsSideBarOpen: false,
                                sideBarOpen: false
                            });
                        }}
                    ></div>
                    <div className="detail-menu">
                        <button
                            className="list"
                            onClick={this.toggleSidebar.bind(this)}
                        ></button>
                        <button
                            className="font"
                            onClick={this.incDecFont.bind(this)}
                        ></button>
                        <button
                            className="options"
                            onClick={this.toggleOptionsSidebar.bind(this)}
                        ></button>
                        <button
                            className="darkmode"
                            onClick={this.toggleDarkmode.bind(this)}
                        ></button>
                    </div>

                    <br/>

                    {!this.state.book.isPaidUser &&
                        this.state.book.body &&
                        this.state.book.body.length && (
                            <header className="BookDetail_UpgradeNow">
                                <div className="container">
                                    <div className="row _book_trial_alert">
                                        <div className="col-md-1">
                                            <img src="/images/star1.svg" alt="Star"/>
                                        </div>
                                        <div className="col-md-7">
                                            <p>
                                                Do you like this summary? Upgrade for unlimited access.
                                            </p>
                                        </div>
                                        <div className="col-md-4">
                                            <a href="/try" className="_alert_btn">
                                                Upgrade Now
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </header>
                        )}

                    <div className="row">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: bodyParts[this.state.index]
                            }}
                            className={`body ${classFont} ${notLoggedInBodyClass}`}
                        ></div>
                        {this.state.book.notLoggedIn && (
                            <div className="BookDetailInapp">
                                <div className="paywall">
                                    <h2>Please login to access the full text and audio summary for FREE</h2>
                                    <p>
                                        Users get access to two free summaries. Upgrade for unlimited access.
                                    </p>
                                    <a
                                        href={`/login?redirect=/book-detail/${this.state.book.subid}`}
                                        className="StandardButton-4"
                                    >
                                        Login
                                    </a>

                                    <div className="BookDetail_Paywall_Discount">
                                        <img src="/images/giftbox.svg"/>
                                        Congrats! You've unlocked a 50% discount. Use coupon code <b>WELCOME</b> for
                                        50% off Sumizeit Premium.
                                    </div>

                                    <p>
                                        Save time with unlimited access to text, audio, and video
                                        summaries of the world's best selling books.
                                        <br/>
                                        <a
                                            href={`/pricing`}
                                            className="StandardButton-4"
                                        >
                                            Upgrade Now
                                        </a>
                                    </p>
                                </div>
                            </div>
                        )}
                        {!this.state.book.isPaidUser && this.state.book.limitReached && (
                            <div className="BookDetailInapp">
                                <div className="paywall">
                                    <h2>Upgrade now to access the full summary.</h2>

                                    <div className="BookDetail_Paywall_Discount">
                                        <img src="/images/giftbox.svg"/>
                                        Congrats! As a new user, you've unlocked a 50% discount. Use coupon code <b>WELCOME</b> for
                                        50% off Sumizeit Premium.
                                    </div>

                                    <p>
                                        Save time with unlimited access to text, audio, and video
                                        summaries of the world's best selling books.
                                    </p>
                                        <a
                                            href={`/pricing?limit_reached=yes`}
                                            className="StandardButton-4"
                                        >
                                            Upgrade Now
                                        </a>
                                </div>
                            </div>
                        )}
                        <p>
                            {this.state.index > 0 && (
                                <a
                                    href="#"
                                    className="incrdec prev"
                                    onClick={this.previousIndex.bind(this)}
                                >
                                    Previous
                                </a>
                            )}
                            {this.state.index < bodyParts.length - 1 && (
                                <a
                                    href="#"
                                    className="incrdec next"
                                    onClick={this.incrementIndex.bind(this)}
                                >
                                    Next
                                </a>
                            )}

                            {this.state.success && (<h2 className="BookDetail_ContactUsBoxH2">Thanks for submitting the feedback form!</h2>)}
                            {(this.state.index === bodyParts.length - 1) && !this.state.success && (
                                <form onSubmit={this.submit.bind(this)}>
                                    <h2 className="BookDetail_ContactUsBoxH2">We would love to know what you think to improve the product!</h2>
                                    <textarea name="body"  className="BookDetail_ContactUsBox" placeholder="Would your recommend Sumizeit to a friend? If not, why not?"></textarea>
                                    <button type="submit" className="StandardButton-2">Submit</button>
                                </form>
                            )}
                            <div className="clear"></div>
                        </p>
                    </div>
                    <div className="row progress-bar-wrapper">
                        <div
                            className="progress-bar"
                            style={{width: progress + "%"}}
                        ></div>
                    </div>
                    <div className="row bottom">
                        <div className="title">
                            {this.state.book.title}{" "}
                            {this.state.index + 1 + "/" + bodyParts.length}
                        </div>
                        {this.state.book.mediaUrl && (
                            <div className="audio">
                                <Audio
                                    book={this.state.book}
                                    startedPlaying={this.startedAudioPlaying.bind(this)}
                                />
                            </div>
                        )}
                        {this.state.book.video && (
                            <div className="video">
                                <a href={`/video/${this.state.book.id}`}>Go to video</a>
                            </div>
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <Header/>
                    <div className="BookDetailInapp">
                        <div className="paywall">
                            <h2>Get the best of Sumizeit</h2>
                            <p>Start your free trial</p>
                            <p>
                               Upgrade now to see the full content of this book summary.
                            </p>
                            <p>
                                Upgrade to a Sumizeit Premium Subscription for unlimited access
                                to our large library of text, audio, and video summaries.
                            </p>

                            <p>
                                All plans except for the lifetime plan include a{" "}
                                <b>3 day free trial</b>.
                            </p>
                            <br/>
                            <a href="/try" className="StandardButton-4">
                                Upgrade now
                            </a>
                        </div>
                    </div>
                    <Footer/>
                </div>
            );
        }
    }
}

export default BookDetail;
