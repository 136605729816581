import React, {Component} from 'react';
import "./NotFound.css";
class NotFound extends Component {
    render() {
        return (
            <div className="App Index">
                <div className="CutRow main">
                    <div className="h1404">404</div>
                    <p>We could not find the page that you are looking for.</p>
                    <p><a href="/">Back to Home Page</a></p>
                </div>
            </div>
        )
    }
}

export default NotFound;
