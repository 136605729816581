import React, { Component } from 'react';
import './Quiz.css';
import personality from "../../assets/images/review.svg";

class PersonalityQuiz extends Component {
    constructor(props) {
        super(props);

        this.state = {
            questions: [
                {
                    title: "I prefer to take charge and lead in group situations.",
                    types: ["Red"]
                },
                {
                    title: "I often act on impulse, preferring to start new projects without much planning.",
                    types: ["Yellow"]
                },
                {
                    title: "I am highly empathetic and always consider how my actions impact others.",
                    types: ["Green"]
                },
                {
                    title: "I like to follow rules and ensure that everything is done in a detailed and accurate manner.",
                    types: ["Blue"]
                },
                {
                    title: "I enjoy socializing and connecting with new people, even in large groups.",
                    types: ["Yellow"]
                },
                {
                    title: "I feel uncomfortable with sudden changes and prefer to have consistency in my routine.",
                    types: ["Green"]
                },
                {
                    title: "When it comes to making decisions, I prioritize facts and logic over emotions.",
                    types: ["Blue"]
                },
                {
                    title: "I get bored easily and like to jump from one exciting task to another.",
                    types: ["Yellow"]
                },
                {
                    title: "I tend to avoid conflict and prefer to keep the peace whenever possible.",
                    types: ["Green"]
                },
                {
                    title: "I enjoy setting ambitious goals for myself and work hard to achieve them.",
                    types: ["Red"]
                },
                {
                    title: "I dislike being rushed and prefer to take my time with tasks to ensure they’re done right.",
                    types: ["Blue"]
                },
                {
                    title: "I love brainstorming creative ideas, even if they are not always realistic.",
                    types: ["Yellow"]
                },
                {
                    title: "I often help others with their problems, even if it means putting my own needs aside.",
                    types: ["Green"]
                },
                {
                    title: "I am very direct in communication and prefer to speak bluntly rather than sugarcoating things.",
                    types: ["Red"]
                },
                {
                    title: "I enjoy working alone and value having time to focus on my tasks without distractions.",
                    types: ["Blue"]
                }
            ],
            questionNo: 0,
            personalityTypes: {}
        };
    }

    handleFalse() {
        this.setState({
            questionNo: this.state.questionNo + 1
        })
    }

    handleTrue() {
        let personalityTypes = this.state.personalityTypes;

        if ((this.state.questionNo + 1) < this.state.questions.length) {
            const question = this.state.questions[this.state.questionNo];

            question.types.forEach(t => {
                if (!personalityTypes[t]) {
                    personalityTypes[t] = 1;
                } else {
                    personalityTypes[t] += 1;
                }
            });
        } else {
            window.sessionStorage.setItem("personalityTypes", JSON.stringify(this.state.personalityTypes));
        }
        this.setState({
            questionNo: this.state.questionNo + 1,
            personalityTypes
        })
    }

    render () {
        if (this.state.questionNo >= this.state.questions.length) {
            return (
                <div className = "QuizBody">
                    <h1>Congrats! You're Done!</h1>
                    <p>Discover your personality type, discover your strengths, and grow faster!</p>
                    <a href = "/payment/personality-quiz"><button className="QuizDoneBtn">View Results</button></a>
                </div>
            );
        }
        const question = this.state.questions[this.state.questionNo];

        return (
            <div className="QuizBody">
                <div className="QuizImg"><img src={personality} alt="sumizeit personality type quiz"/></div>
                <h1>Question {this.state.questionNo + 1}/{this.state.questions.length}</h1>
                <p className="QuizAnswerQues">Answer questions to find your personality type, discover your strengths, and grow faster!</p>
                <h3 className="QuizBodyTitle">{question.title}</h3>
                <div className="QuizBtns">
                    <button className="QuizFalseBtn" onClick={this.handleFalse.bind(this)}>False</button>
                    <button className="QuizTrueBtn" onClick={this.handleTrue.bind(this)}>True</button>
                </div>
            </div>
        )
    }
}

export default PersonalityQuiz;