import React, { Component } from "react";

import "./TenDayChallenge.css";
import axios from "../../../axios";
import Spinner from "../../Spinner/Spinner";
import Utils from "../../../utils";

class TenDayChallenge extends Component {
  state = {
    step: 1,
    goals: null,
    selected: {},
    loading: true,
    userBookRecommendations: [],
    userGoalTitles: [],
    error: null,
    bookIds: [],
    email: null,
    resultsNotSaved: true,
  };

  componentDidMount() {
    try {
      if (window.sessionStorage) {
        let goals = JSON.parse(window.sessionStorage.getItem("goals"));
        let bookIds = JSON.parse(window.sessionStorage.getItem("bookIds"));

        if (goals && goals.length && bookIds && bookIds.length) {
          this.handleSave();
        } else {
          this.fetchGoals();
        }
      }
    } catch (err) {
      this.fetchGoals();
    }
  }

  fetchGoals() {
    axios
      .get("/api/goals")
      .then((response) => {
        if (response.data.status === "ok") {
          let step = 1;
          let resultsNotSaved = true;
          if (
            response.data.result &&
            response.data.result.userBookRecommendations &&
            response.data.result.userBookRecommendations.length
          ) {
            step = 3;
            resultsNotSaved = false;
          }
          this.setState({
            goals: response.data.result.goals,
            loading: false,
            step,
            resultsNotSaved,
            userGoalTitles: response.data.result.userGoals,
            books: response.data.result.userBookRecommendations,
          });
        } else {
          this.setState({
            error: response.data.error,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: "There was an error processing your request.",
          loading: false,
        });
      });
  }

  handleStart() {
    var step = 2;

    if (window.gtag) {
      window.gtag("event", "ten_day_challenge_start", {
        event_category: "engagement",
      });
    }

    this.setState({
      step,
    });
  }

  handleSave() {
    if (window.gtag) {
      window.gtag("event", "ten_day_challenge_save", {
        event_category: "engagement",
      });
    }

    if (!Utils.isAuthenticated()) {
      let goals = [];
      let selected = this.state.selected;

      for (var s in selected) {
        if (selected.hasOwnProperty(s)) {
          goals.push(parseInt(s, 10));
        }
      }

      if (window.sessionStorage) {
        window.sessionStorage.setItem("goals", JSON.stringify(goals));
        window.sessionStorage.setItem(
          "bookIds",
          JSON.stringify(this.state.bookIds)
        );
      }
      setTimeout(() => {
        window.location.href = "/login?redirect=/ten-day-challenge";
      }, 1000);
    } else {
      let goals = [];
      try {
        if (window.sessionStorage) {
          goals = JSON.parse(window.sessionStorage.getItem("goals"));
          if (goals && goals.length) {
            window.sessionStorage.removeItem("goals");
          }
        }
      } catch (err) {
        goals = [];
      }

      let bookIds = [];
      try {
        if (window.sessionStorage) {
          bookIds = JSON.parse(window.sessionStorage.getItem("bookIds"));
          if (bookIds && bookIds.length) {
            window.sessionStorage.removeItem("bookIds");
          }
        }
      } catch (err) {
        bookIds = [];
      }

      if (!goals || !goals.length) {
        goals = [];
        let selected = this.state.selected;

        for (var s in selected) {
          if (selected.hasOwnProperty(s)) {
            goals.push(parseInt(s, 10));
          }
        }
      }

      if (!bookIds || !bookIds.length) {
        bookIds = this.state.bookIds;
      }

      if (goals && goals.length && bookIds && bookIds.length) {
        this.setState(
          {
            loading: true,
          },
          () => {
            axios
              .post("/api/user/ten-day-challenge-submit", {
                goals,
                bookIds,
              })
              .then((response) => {
                if (response.data.status === "ok") {
                  this.setState(
                    {
                      step: 3,
                    },
                    () => {
                      this.fetchGoals();
                    }
                  );
                } else {
                  this.setState({
                    error: response.data.error,
                    loading: false,
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  error: "There was an error processing your request.",
                  loading: false,
                });
              });
          }
        );
      }
    }
  }

  handleSubmit() {
    if (window.gtag) {
      window.gtag("event", "ten_day_challenge_submit", {
        event_category: "engagement",
      });
    }
    let goals = [];

    if (!goals || !goals.length) {
      let selected = this.state.selected;

      for (var s in selected) {
        if (selected.hasOwnProperty(s)) {
          goals.push(parseInt(s, 10));
        }
      }
    }

    if (goals && goals.length && this.state.email && this.state.email.length) {
      this.setState(
        {
          loading: true,
        },
        () => {
          axios
            .post("/api/user/ten-day-challenge", {
              goals,
              email: this.state.email,
            })
            .then((response) => {
              if (response.data.status === "ok") {
                this.setState({
                  books: response.data.result.books,
                  bookIds: response.data.result.bookIds,
                  loading: false,
                  userGoalTitles: response.data.result.userGoalTitles,
                  step: 3,
                });
              } else {
                this.setState({
                  error: response.data.error,
                  loading: false,
                });
              }
            })
            .catch((error) => {
              this.setState({
                error: "There was an error processing your request.",
                loading: false,
              });
            });
        }
      );
    } else {
      alert("Please pick at least one goal and specify an email address.");
    }
  }

  handleSelected(e, id, checked) {
    let selected = Object.assign({}, this.state.selected);
    selected[id] = checked;

    var numSelected = 0;
    for (var s in selected) {
      if (selected.hasOwnProperty(s)) {
        if (selected[s]) {
          numSelected += 1;
        }
      }
    }
    if (numSelected > 2) {
      e.preventDefault();
      alert("You can only pick two goals.");
    } else {
      this.setState({
        selected,
      });
    }
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }

    if (this.state.books && this.state.step === 3) {
      const html = this.state.books.map((b) => {
        return (
          <div className="TendayChallenge_Book col-md">
            <div className="TendayChallenge_DayNo">
              <b>Day #{b.dayNo}</b>
            </div>
            <div className="TendayChallenge_BookImgWrapper">
              <a href={`/books/${b.subid}`}>
                <img src={b.image} className="TendayChallenge_BookImg" />
              </a>
            </div>
            <div>
              <a href={`/books/${b.subid}`}>
                <button className="button-23">Start</button>
              </a>
            </div>
          </div>
        );
      });

      let userGoals = this.state.userGoalTitles.map((i) => {
        return <li>{i.title}</li>;
      });

      const tweet =
        "Check out sumizeit's ten-day reading challenge to be a better you.";

      return (
        <div className="TendayChallenge container">
          <div className="TendayChallenge_Header"></div>
          <h1>Your Ten Day Reading challenge</h1>
          <div className="TendayChallenge_Header_Share row">
            <a
              href="https://www.facebook.com/sharer/sharer.php?u=https://www.sumizeit.com/ten-day-challenge"
              target="_blank"
              className="button-23"
            >
              Share on Facebook
            </a>
            <a
              href={`http://twitter.com/share?text=${tweet}&url=https://www.sumizeit.com/ten-day-challenge`}
              target="_blank"
              className="button-23"
            >
              Tweet
            </a>
            <a
              href="mailto:?subject=Check out sumizeit.com's 10 day reading challenge&amp;body=Hi%0D%0A%0D%0AI took sumizeit.com's ten day reading challenge to be a better you. I think you should to. It will help you achieve your goals. You can check it out at https://www.sumizeit.com/ten-day-challenge"
              title="Share by Email"
              className="button-23"
              target="_blank"
            >
              Share by Email
            </a>
          </div>
          <p>
            Be a better you. Spend 15-minutes a day going over the books below.
            You can read, listen to, or watch the book summaries.
          </p>
          {this.state.resultsNotSaved && (
            <div className="TenDayChallenge_SaveResults">
              <button
                className="StandardButton-1"
                onClick={() => {
                  this.handleSave();
                }}
              >
                Save the results
              </button>
            </div>
          )}
          <div className="TendayChallenge_Body row">{html}</div>
          <div className="TendayChallenge_Goals">
            <h3>Your goals</h3>
            <ul>{userGoals}</ul>
          </div>
          <div className="TendayChallenge_Button">
            <button
              className="StandardButton-1"
              onClick={() => {
                this.setState({
                  step: 1,
                  selected: {},
                  userBookRecommendations: [],
                  userGoalTitles: [],
                  loading: false,
                });
              }}
            >
              Regenerate
            </button>
          </div>
        </div>
      );
    } else if (this.state.goals && this.state.step === 2) {
      var html = this.state.goals.map((g) => {
        return (
          <div>
            <label className="TendayChallenge_CheckboxContainer">
              {g.title}
              <input
                type="checkbox"
                checked={this.state.selected[g.id]}
                onChange={(e) => this.handleSelected(e, g.id, e.target.checked)}
              />
              <span className="TendayChallenge_Checkmark"></span>
            </label>
          </div>
        );
      });
      return (
        <div className="TendayChallenge">
          <div className="TendayChallenge_Header"></div>

          <div className="TendayChallenge_Body">
            <h1>Spend 15 minutes a day to be a better you</h1>

            <p>
              Tell us your goals, and we will recommend a list of books that
              will help you achieve them.
            </p>

            <p>Please enter your email address to receive the results:</p>

            <div className="TendayChallenge_Input">
              <input
                name="email"
                type="text"
                placeholder="Your Email Address"
                onChange={(e) => {
                  this.setState({
                    email: e.target.value,
                  });
                }}
              />
            </div>

            <p>What are your goals? Please pick two to focus on:</p>

            <div>
              {html}
              <div className="TendayChallenge_Button">
                <button
                  className="StandardButton-1"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="TendayChallenge">
          <div className="TendayChallenge_Header"></div>
          <div className="TendayChallenge_Body">
            <h1>Be a better you - take our FREE 10 day challenge</h1>
            <p>
              Need help achieving your goals? Books can help you achieve your
              goals! They give you the wisdom that you need to reach your
              potential.
            </p>
            <p>
              Fill out a simple, short questionnaire to start your 10 day book
              challenge. Tell us what goals you want to achieve and we will
              provide you with a list of books with their 15-minute summaries
              that you can use to achieve your goals.
            </p>

            <p>
              Don't let life pass you by. Be happier today. Click on the button
              below to start your 10 day challenge and become a better you.
            </p>
            <div className="TendayChallenge_Button">
              <button
                className="StandardButton-1"
                onClick={(e) => {
                  this.handleStart();
                }}
              >
                Start Challenge
              </button>
              <br />
              <br />
              <p>
                Already completed the challenge?{" "}
                <a href="/login?redirect=/ten-day-challenge">Login</a> to view
                your results
              </p>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default TenDayChallenge;
