import React, {Component} from "react";
import Axios from "../../axios";
import Spinner from "../Spinner/Spinner";
import queryString from "query-string";
import "./Login.css";
import {NavLink} from "react-router-dom";
import MetaTags from "react-meta-tags";
import axios from "axios";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            loading: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const search = this.props.location.search;
        const error = new URLSearchParams(search).get("error");
        const code = new URLSearchParams(search).get("code");
        const type = new URLSearchParams(search).get("type");
        const redirect = new URLSearchParams(search).get("redirect");
        const token = new URLSearchParams(search).get("token");

        if (error && error.length) {
            this.setState({
                error: error
            });
        }
        if (code) {
            this.handleCallback(code, type);
        }

        if (token) {
            this.handleToken(token);
        }
    }

    async handleToken(token) {
        this.setState(
            {
                loading: true
            },
            async () => {
                if (window.localStorage) {
                    window.localStorage.setItem("token", token);
                    window.localStorage.setItem("tokenDate", Date.now());
                }

                setTimeout(async () => {
                    try {
                        const newAxios = axios.create({
                            withCredentials: true,
                            baseURL:
                                window.location.href.indexOf("localhost") !== -1
                                    ? "https://localhost:4000"
                                    : "",
                            headers: {
                                Authorization: "Bearer " + token,
                                "Content-Type": "application/json"
                            },
                            credentials: "same-origin"
                        });
                        const response = await newAxios.get("/api/user/session");

                        if (response.data.status === "ok") {
                            let sessionRedirect = null;
                            if (
                                window.sessionStorage &&
                                window.sessionStorage.getItem("redirect")
                            ) {
                                sessionRedirect = window.sessionStorage.getItem("redirect");
                            }

                            const values = queryString.parse(this.props.location.search);
                            if (values.redirect && values.redirect !== "null") {
                                window.location.href = values.redirect;
                            } else if (
                                sessionRedirect &&
                                sessionRedirect.length &&
                                sessionRedirect !== "null"
                            ) {
                                window.sessionStorage.removeItem("redirect");
                                window.location.href = sessionRedirect;
                            } else {
                                window.location.href = "/home?onsite=1&token=" + token;
                            }
                        } else {
                            this.setState({
                                success: "",
                                loading: false,
                                error: response.data.error
                            });
                        }
                    } catch (err) {
                        this.setState({
                            success: "",
                            loading: false,
                            error: err
                        });
                    }
                }, 1000);
            }
        );
    }

    async handleCallback(code, type) {
        this.setState(
            {
                loading: true
            },
            async () => {
                let url;
                if (type === "linkedin") {
                    url = "/api/linkedin/oauth/callback?code=" + code;
                } else if (type === "facebook") {
                    url = "/api/facebook/oauth/callback?code=" + code;
                } else if (type === "google") {
                    url = "/api/google/login/oauth/callback?code=" + code;
                }
                try {
                    const response = await Axios.get(url);
                    if (response.data.status === "ok") {
                        if (window.gtag) {
                            window.gtag("event", "login", {
                                event_category: "user",
                                event_label: type
                            });
                        }

                        window.localStorage.setItem("token", response.data.result.token);
                        window.localStorage.setItem("tokenDate", Date.now());

                        let sessionRedirect = null;
                        if (
                            window.sessionStorage &&
                            window.sessionStorage.getItem("redirect")
                        ) {
                            sessionRedirect = window.sessionStorage.getItem("redirect");
                        }

                        const values = queryString.parse(this.props.location.search);
                        if (values.redirect && values.redirect !== "null") {
                            window.location.href = values.redirect;
                        } else if (
                            sessionRedirect &&
                            sessionRedirect.length &&
                            sessionRedirect !== "null"
                        ) {
                            window.sessionStorage.removeItem("redirect");
                            window.location.href = sessionRedirect;
                        } else {
                            window.location.href =
                                "/home?onsite=1&token=" + response.data.result.token;
                        }
                    } else {
                        this.setState({
                            success: "",
                            loading: false,
                            error: response.data.error
                        });
                    }
                } catch (err) {
                    this.setState({
                        success: "",
                        loading: false,
                        error: err
                    });
                }
            }
        );
    }

    async handleSocialLogin(ev, type) {
        ev.preventDefault();

        let redirect = null;
        const values = queryString.parse(this.props.location.search);
        if (values.redirect) {
            redirect = values.redirect;
        }

        if (window.sessionStorage && redirect) {
            window.sessionStorage.setItem("redirect", redirect);
        }

        setTimeout(async () => {
            let url;
            if (type === "linkedin") {
                url = "/api/linkedin/oauth?origin=login&redirect=" + redirect;
            } else if (type === "facebook") {
                url = "/api/facebook/oauth?origin=login&redirect=" + redirect;
            } else if (type === "google") {
                url = "/api/google/login/oauth?origin=login&redirect=" + redirect;
            }

            try {
                const response = await Axios.get(url);
                setTimeout(() => {
                    if (response.data.status === "ok") {
                        window.location.href =
                            "/goback?goback=" +
                            encodeURIComponent(response.data.result.url) +
                            (redirect ? "&redirect=" + encodeURIComponent(redirect) : "");
                    } else {
                        this.setState({
                            error: response.data.error
                        });
                    }
                }, 500);
            } catch (err) {
                this.setState({
                    error: err
                });
            }
        }, 500);
    }

    handleSubmit(ev) {
        ev.preventDefault();
        const {email, password} = ev.target.elements;
        if (!email.value.length || !password.value.length) {
            this.setState(
                {
                    error: "Please specify an email or password.",
                    loading: false
                },
                () => {
                    window.scrollTo(0, 0);
                }
            );
        } else {
            Axios.post("/api/user/login", {
                username: email.value,
                password: password.value,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            })
                .then((response) => {
                    if (response.data.status === "ok") {
                        if (window.gtag) {
                            window.gtag("event", "login", {
                                event_category: "user",
                                event_label: "auth"
                            });
                        }

                        window.localStorage.setItem("token", response.data.result.token);
                        window.localStorage.setItem("tokenDate", Date.now());
                        const values = queryString.parse(this.props.location.search);
                        if (values.redirect) {
                            window.location.href = values.redirect;
                        } else {
                            window.location.href = "/home?onsite=1";
                        }
                    } else {
                        this.setState(
                            {
                                error: response.data.error,
                                loading: false
                            },
                            () => {
                                window.scrollTo(0, 0);
                            }
                        );
                    }
                })
                .catch((err) => {
                    console.dir(err);
                    this.setState(
                        {
                            error: err,
                            loading: false
                        },
                        () => {
                            window.scrollTo(0, 0);
                        }
                    );
                });
        }
    }

    handleAppleLogin(ev) {
        ev.preventDefault();
        let redirect = null;
        const values = queryString.parse(this.props.location.search);
        if (values.redirect) {
            redirect = values.redirect;
        }

        if (window.sessionStorage && redirect) {
            window.sessionStorage.setItem("redirect", redirect);
        }

        let appleRedirect =
            "/goback?goback=" +
            encodeURIComponent("/api/user/apple-login") +
            (redirect ? "&redirect=" + encodeURIComponent(redirect) : "");

        window.location.href = appleRedirect;
    }

    render() {
        const inBookDetail = window.location.href.indexOf("/book-detail/") !== -1;

        if (this.state.loading) {
            return <Spinner/>;
        }

        var error = "";
        if (this.state.error && this.state.error.length) {
            error = (
                <>
                    <div className="alert alert-warning" role="alert">
                        {this.state.error}
                    </div>
                    <br/>
                    <br/>
                </>
            );
        }

        var redirect = null;
        var redirectUrl = null;
        const values = queryString.parse(this.props.location.search);
        redirectUrl = values.redirect;

        if (values.redirect) {
            redirect = "?redirect=" + values.redirect;
        }

        return (
            <div className="Login">
                <div className="registration-form">
                    <form
                        onSubmit={(ev) => this.handleSubmit(ev)}
                        name="login"
                        method="POST"
                    >
                        <div className="form-icon">
                            <span><i className="icon icon-user"></i></span>
                        </div>
                        {error}
                        {redirect && !inBookDetail && (
                            <div className="alert alert-warning Login_Error">
                                Please login to access this page.
                            </div>
                        )}
                        {(inBookDetail || !redirect) && (
                            <div className="Login_BookDetail">
                                <p>
                                    Please login or register to access 2 FREE book summaries in text and audio format. Then upgrade for unlimited access.
                                </p>
                            </div>
                        )}
                        <div className="already-a-user">
                            New to sumizeit?{" "}
                            <NavLink
                                to={{
                                    pathname: "/signup",
                                    search: redirect
                                }}
                            >
                                Sign up
                            </NavLink>
                        </div>
                        <hr/>
                        <div className="social-media">
                            <h5>Log In with Social Media</h5>
                            <div className="social-login">
                                <a
                                    href="#"
                                    className="google niceBtn d-flex justify-content-center align-items-center"
                                    onClick={(ev) => {
                                        this.handleSocialLogin(ev, "google");
                                    }}
                                >
                                    <span className="s-icon-google mr-3"></span> Sign In with Google
                                </a>
                            </div>

                            <div className="social-login">
                                <a
                                    className="apple niceBtn d-flex justify-content-center align-items-center"
                                    onClick={(ev) => {
                                        this.handleAppleLogin(ev);
                                    }}
                                >
                                    <span className="s-icon-apple mr-3"></span> Sign In with Apple
                                </a>
                            </div>
                        </div>
                        <p>- OR - </p>
                        <div className="login-with-email">
                            <h5>Log In with Email</h5>
                            <div className="form-group">
                                <input type="text" className="form-control item" id="email" placeholder="Email"/>
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control item" id="password"
                                       placeholder="Password"/>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-block create-account">Login</button>
                            </div>
                            <div className="forgot-password">
                                <a href="/forgot-password" className="forgot-pass">
                                    Forgot Password
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
    );
    }
}

export default Login;
