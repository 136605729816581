import React, {Component} from 'react';
import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';
import "./Payment.css"
import Spinner from "../Spinner/Spinner";

const key = (window.location.href.indexOf('localhost') !== -1) ? 'pk_test_2e1BmJXyob96MfCGRc3skSHX00LjDjbfzQ' : 'pk_live_WGtZBktuN13RtjoHdOGM24zN00TOtZBaPd';
// (window.location.href.indexOf('localhost') !== -1) ? "AaJp4q2o1ePXWQgAPk6m1McmYbpT5St3_UfVXLbob7fz77suLouPY59AqwXBn1qNa2CG6hqtls3dKtk4" :
const paypalKey =  "AVU5vmNDJ7N5V1zUr5UbUPfJT0N8yLSZshRW7YhIvZ95BRiAf9kwIvGER5zLM2K2mgELrSMu-JceY8zF";

class Payment extends Component {

    state = {
        loading: true,
        loadingStripe: true,
        loadingPaypal: true
    };

    componentDidMount() {
        var stripeScript = document.createElement('script');
        stripeScript.type = 'text/javascript';
        stripeScript.src = " https://js.stripe.com/v3/";

        document.head.appendChild(stripeScript);
        stripeScript.onload = () => {
            var loading = this.state.loading;
            if (!this.state.loadingPaypal) {
                loading = false;
            }
            this.setState({'loadingStripe': false, loading: loading})
        };

        var paypalScript = document.createElement('script');
        paypalScript.type = 'text/javascript';
        paypalScript.src = "https://www.paypal.com/sdk/js?client-id=" + paypalKey + "&vault=true";

        document.head.appendChild(paypalScript);
        paypalScript.onload = () => {
            var loading = this.state.loading;
            if (!this.state.loadingStripe) {
                loading = false;
            }
            this.setState({'loadingPaypal': false, loading: loading})
        };
    }

    render() {
        if (this.state.loading) { return <Spinner /> }

        let planId = (this.props.match && this.props.match.params.planId) ? this.props.match.params.planId : null;
        return (
            <div className="Payment Pricing_Main">
                <StripeProvider apiKey={key}>
                    <Elements>
                        <CheckoutForm planId={planId}/>
                    </Elements>
                </StripeProvider>
            </div>
        );
    }
}

export default Payment;
