import React, {Component} from 'react';
import Kindle from './Kindle'

class SendToKindle extends Component {

    componentDidMount() {

    }

    render() {
        if (this.props.match && this.props.match.params.bookId) {
            return (<Kindle bookId={this.props.match.params.bookId} />);
        } else {
            return (<div>Please select a book to send to kindle.</div>);
        }
    }
}

export default SendToKindle;
