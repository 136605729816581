import React, {Component} from "react";
import axios from "../../../axios";
import "../Admin.css";
import Spinner from "../../Spinner/Spinner";
import Form from "../../Form/Form";
import _ from "lodash";

class AdminPodcastEdit extends Component {
    state = {
        podcast: null,
        loading: true
    };

    componentWillMount() {
        if (this.props.match && this.props.match.params.id) {
            this.fetch();
        }
    }

    fetch() {
        const id = this.props.match.params.id;
        axios
            .get("/api/admin/podcasts/" + id)
            .then((response) => {
                if (response.data.status === "ok") {
                    this.setState(
                        {
                            podcast: response.data.result.podcast,
                            loading: false
                        }
                    );
                } else {
                    this.setState({
                        error: response.data.error,
                        loading: false
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    error: "There was an error processing your request.",
                    loading: false
                });
            });
    }

    handleSubmit(values) {
        const output = _.cloneDeep(values);

        if (this.props.match && this.props.match.params.id) {
            const id = this.props.match.params.id;
            this.setState(
                {
                    loading: true
                },
                () => {
                    axios
                        .put("/api/admin/podcasts/" + id, output)
                        .then((response) => {
                            if (response.data.status === "ok") {
                                this.setState(
                                    {
                                        error: "",
                                        loading: false,
                                        success: "Podcast was updated.",
                                        podcast: output
                                    },
                                    () => {
                                        window.scrollTo(0, 0);
                                    }
                                );
                            } else {
                                this.setState(
                                    {
                                        error: response.data.error,
                                        loading: false,
                                        success: ""
                                    },
                                    () => {
                                        window.scrollTo(0, 0);
                                    }
                                );
                            }
                        })
                        .catch((err) => {
                            console.dir(err);
                            this.setState(
                                {
                                    error: err,
                                    loading: false,
                                    success: ""
                                },
                                () => {
                                    window.scrollTo(0, 0);
                                }
                            );
                        });
                }
            );
        }
    }

    render() {
        if (this.state.loading) {
            return <Spinner/>;
        }

        var error = "";
        if (this.state.error && this.state.error.length) {
            error = (
                <>
                    <div className="alert alert-warning" role="alert">
                        {this.state.error}
                    </div>
                    <br/>
                    <br/>
                </>
            );
        }

        var success = "";
        if (this.state.success && this.state.success.length) {
            success = (
                <>
                    <div className="alert alert-success" role="alert">
                        {this.state.success}
                    </div>
                    <br/>
                    <br/>
                </>
            );
        }

        if (this.state.podcast) {
            const podcast = this.state.podcast;

            return (
                <div className="Main Form">
                    <h1>Edit Podcast {this.props.match.params.id}</h1>
                    {success}
                    {error}
                    <Form
                        submitHandler={this.handleSubmit.bind(this)}
                        submitButtonLabel="Submit"
                        fields={[
                            {
                                id: "title",
                                label: "Title",
                                type: "text",
                                inputType: "text",
                                required: true,
                                value: podcast.title
                            },
                            {
                                id: "image",
                                label: "Image",
                                type: "text",
                                inputType: "text",
                                required: false,
                                value: podcast.image
                            },
                            {
                                id: "author",
                                label: "Author",
                                type: "text",
                                inputType: "text",
                                required: true,
                                value: podcast.author
                            },
                            {
                                id: "authorDesc",
                                label: "Author Description",
                                type: "textarea",
                                inputType: "textarea",
                                required: false,
                                value: podcast.authorDesc
                            },
                            {
                                id: "description",
                                label: "Description",
                                type: "textarea",
                                inputType: "textarea",
                                required: false,
                                value: podcast.description
                            },
                            {
                                id: "mins",
                                label: "Mins",
                                type: "text",
                                inputType: "text",
                                required: false,
                                value: podcast.mins
                            },
                            {
                                id: "mediaUrl",
                                label: "Media Url",
                                type: "text",
                                inputType: "text",
                                required: false,
                                value: podcast.mediaUrl
                            }
                        ]}
                        showLabels={true}
                        errorHandler={(error) => {
                            window.scrollTo(0, 0);
                            this.setState({
                                error
                            });
                        }}
                    />
                </div>
            );
        }
        return "";
    }
}

export default AdminPodcastEdit;
