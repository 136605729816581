import React, {Component} from 'react';
import axios from '../../axios';
import Spinner from '../Spinner/Spinner'
import {NavLink} from 'react-router-dom';

class Kindle extends Component {

    state = {
        hasKindle: false,
        loading: true,
        complete: false,
        error: false,
        mustUpgrade: false
    };

    componentDidMount() {
        axios.get('/api/kindle/has-kindle').then(response => {
            if (response.data.result.kindleEmail && response.data.result.kindleEmail.length > 0) {
                this.setState({hasKindle: true, loading: true});

                axios.post('/api/kindle/', {bookId: this.props.bookId}).then(response => {
                    if (response.data.status === "ok") {
                        this.setState({
                            complete: true,
                            hasKindle: true,
                            loading: false,
                            mustUpgrade: response.data.result.upgrade
                        });
                    } else {
                        this.setState({
                            complete: false,
                            hasKindle: true,
                            loading: false,
                            error: response.data.error,
                            mustUpgrade: response.data.result.upgrade
                        });
                    }
                }).catch(error => {
                    this.setState({error: error.message, hasKindle: true, loading: false});
                });
            } else {
                this.setState({hasKindle: false, loading: false});
            }
        }).catch(error => {
            this.setState({error: error.message, loading: false});
        });
    }

    submit(event) {
        event.preventDefault();
        const {kindle_email, kindle_email_end} = event.target.elements;

        if (kindle_email.value && kindle_email.value.length) {
            axios.post('/api/kindle/set-kindle', {
                kindle_email: kindle_email.value,
                kindle_email_end: kindle_email_end.value,
                bookId: this.props.bookId
            }).then(response => {
                var result = response.data;

                if (result.error) {
                    this.setState({
                        error: result.error,
                        loading: false,
                        hasKindle: false,
                        mustUpgrade: response.data.result.upgrade
                    });
                } else {
                    this.setState({
                        complete: true,
                        loading: false,
                        hasKindle: true,
                        mustUpgrade: response.data.result.upgrade
                    });
                }
            }).catch(error => {
                this.setState({error: error.message, loading: false});
            });
        } else {
            this.setState({error: "Please complete the form."});
        }
    }

    render() {
        if (this.state.loading) return (<Spinner/>);
        if (this.state.complete) return (<div align="center" className="Main">
            <h2>Successfully sent to Kindle!</h2>
            <br/>
            <br/>
            <p>Next time you sync your Kindle the Sumizeit Originals will be waiting for you. Please be patient – they
                can be slow travelers!</p>
        </div>);

        var error = "";
        if (this.state.mustUpgrade) {
            error = (
                <div className="alert alert-danger" role="alert">Please <NavLink to="/try">click here</NavLink> to
                    upgrade to use this feature.</div>
            );
        } else if (this.state.error) {
            error = (
                <div className="alert alert-danger" role="alert">
                    {this.state.error}
                </div>
            )
        }
        if (!this.state.hasKindle) {
            return (
                <div className="container Main">
                    {error}
                    <h1 align="center">Set up your Kindle</h1>
                    <form onSubmit={this.submit.bind(this)} style={{margin: "auto", maxWidth: "400px"}}>
                        <p>Send sumizeit originals right to your Kindle from within the app.</p>
                        <br/>
                        <p>1. Add sumizeit to your approved document senders to receive documents from sumizeit.</p>
                        <p>Go <a
                            href="https://www.amazon.com/mn/dcw/myx.html/ref=kinw_myk_redirect#/home/settings/payment"
                            target="_blank">here</a>. Click on "Personal Document Settings". Your kindle's email address
                            will be listed. Please input it below. Go to the section <strong>Approved Personal Document
                                E-mail List</strong> and add kindle@sumizeitmail.com as an approved email address.</p>
                        <br/>
                        <p>2. Add your Kindle email</p>
                        <br/>

                        <input name="kindle_email" className="form-control" type="text" placeholder="email"/>
                        <select name="kindle_email_end">
                            <option value="@kindle.com">kindle.com</option>
                            <option value="@kindle.cn">kindle.cn</option>
                            <option value="@kindle.ca">kindle.ca</option>
                            <option value="@kindle.it">kindle.it</option>
                            <option value="@free.kindle.com">free.kindle.com</option>
                        </select>
                        <br/>
                        <br/>
                        <button type="submit" className="StandardButton-2">Submit</button>
                    </form>
                </div>
            );
        } else {
            return (
                <div className="container Main">
                    {error}
                </div>
            )
        }
    }
}

export default Kindle;
