import React, {Component} from "react";
import axios from "../../axios";
import "./Login.css";
import Spinner from "../Spinner/Spinner";
import Form from "../Form/Form";
import queryString from "query-string";

class DeleteAccount extends Component {
    state = {
        error: null,
        loading: false
    };

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);

        if (window.localStorage && values.token) {
            window.localStorage.setItem("token", values.token);
        }
    }

    handleSubmit(values) {
        if (values.confirm === "confirm") {
            axios.post('/api/user/delete', values).then(response => {
                if (response.data.status === "ok") {
                    window.location.href = "/logout"
                } else {
                    this.setState({
                        error: "Unable to delete account. Please email us at info@sumizeit.com.",
                        loading: false
                    })
                }
            }).catch(error => {
                console.dir(error);
                this.setState({error: error});
            });
        } else {
            this.setState({
                error: "Please write the word 'confirm' in the textbox to delete your account.",
                loading: false
            })
        }

    }

    componentDidMount() {
    }

    render() {

        if (this.state.loading) {
            return <Spinner />
        }

        var error = "";
        if (this.state.error && this.state.error.length) {
            error = (
                <>
                    <div className="alert alert-warning" role="alert">
                        {this.state.error}
                    </div>
                    <br/>
                    <br/>
                </>
            );
        }

        return <div className="container Account Form profile-form">
            <div className="row">
                {error}
                <h1>Delete Account</h1>
                <Form
                    submitHandler={this.handleSubmit.bind(this)}
                    submitButtonLabel="Submit"
                    fields={[
                        {
                            id: "why",
                            label: "Why are you deleting your account?",
                            type: "textarea",
                            inputType: "textarea",
                            required: true
                        },
                        {
                            id: "how",
                            label: "How did you find out about us?",
                            type: "text",
                            inputType: "text",
                            required: true
                        },
                        {
                            id: "confirm",
                            label: "Please write the word \"confirm\" in the text box below to confirm your account deletion.",
                            type: "text",
                            inputType: "text",
                            required: true
                        }
                    ]}
                    showLabels={true}
                    errorHandler={(error) => {
                        window.scrollTo(0, 0);
                        this.setState({
                            error,
                        });
                    }}
                />
            </div>
        </div>
    }
}

export default DeleteAccount;
