import axios from 'axios';

const token = window.localStorage.getItem('token');
const instance = axios.create({
    withCredentials: true,
    baseURL: (window.location.href.indexOf('localhost') !== -1) ? "http://localhost:4000" : "",
    headers: {
        Authorization: "Bearer " + token,
        'Content-Type': 'application/json',
    },
    credentials: "same-origin"
});

export default instance;